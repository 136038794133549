import React from 'react'
import '../css/CalenderPopup.css'
import image5 from '../images/01.png';
const Popup = ({ onClose }) => {
  return (
    <div className="popup">
      <div className="popup-content">
        <img
          src={image5}
          width={150}
          height={100}
          className='img-fluid'
          alt='laptop'

        />
        <p className='overlay-heading1'>Meeting Created</p>
        <p className='overlay-text2'>Meeting details have been added successfully!</p>
        <button onClick={onClose} className='btn-overlay-1'>Close</button>
      </div>
    </div>
  );
}

export default Popup;
