import React from "react";
import { useEffect, useState } from "react";
import "../css/Popups.css";
import image2 from "../images/claincoaster-integration-img-2.svg";
import axios from "axios";

const Popups = ({ onClose }) => {
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState(null);

  const fetchSearchedData = async (term) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/search-all?searchWord=${term}`,
      {
        headers: {
          // Set your authorization header here if needed
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      }
    );
    setSearchData(response.data.message.data);
  };

  useEffect(() => {
    if (searchText !== "") {
      fetchSearchedData(searchText);
    } else setSearchData(null);
  }, [searchText]);

  const handleSearch = () => {
    // Implement your search logic here
    console.log(`Searching for: ${searchText}`);
  };

  const handleSearchItemClick = (item) => {
    if (item.type === "client") {
      window.location.href = `/ClientNotes/${item.id}`;
    } else if (item.type === "task") {
      window.location.href = `/taskpage/${item.id}`;
    }
  };

  return (
    <div className="popup-container search-container">
      <span className="close-popup z-1" onClick={onClose}>
        X
      </span>
      <button className="input-search-btn">
        <img
          src={image2}
          width={20}
          height={20}
          className="img-fluid1"
          alt="vector"
        />
      </button>
      <input
        type="text"
        placeholder="Search........"
        className="input-search sidebar-search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <div
        className="overflow-scroll search-result rounded shadow"
        style={{ height: 400 }}
      >
        {searchData &&
          searchData.map((item, index) => {
            return (
              <div
                onClick={() => handleSearchItemClick(item)}
                key={item}
                className="bg-white p-2 searched-item"
              >
                <span className="mx-4 text-success">{item.type}</span>{" "}
                <span>{item.subject}</span>{" "}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Popups;
