import { useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import "../css/TagInput.css";
function TagsInput() {
  const [tags, setTags] = useState([]);
  function handleKeyDown(e) {
    // If user did not press enter key, return
    if (e.key !== "Enter") return;
    // Get the value of the input
    const value = e.target.value;
    // If the value is empty, return
    if (!value.trim()) return;
    // Add the value to the tags array
    setTags([...tags, value]);
    // Clear the input
    e.target.value = "";
  }
  function removeTag(index) {
    setTags(tags.filter((el, i) => i !== index));
  }

  return (
    <div className="tags-input-container">
      {tags.map((tag, index) => (
        <div className="tag-item" key={index}>
          <span className="text">{tag}</span>
          <span className="close" onClick={() => removeTag(index)}>
            &times;
          </span>
        </div>
      ))}
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Col xxl={12}>
          <Form.Control
            type="text"
            onKeyDown={handleKeyDown}
            className="claincoaster-general-input1-1 "
            placeholder="Company name"
          />
        </Col>
      </Form.Group>
    </div>
  );
}

export default TagsInput;
