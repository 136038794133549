import { React, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Task.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Dropdown } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Vector1 from "../images/Vector-chart.png";
import Vector2 from "../images/Vector-dot.png";
import { BsArrowRightCircle } from "react-icons/bs";
import { useState } from "react";
import Button1 from "react-bootstrap/Button";
import Button2 from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Popup from "../components/Popup";
import { toast } from "react-hot-toast";
import Select from "react-select";

import { useDispatch, useSelector } from "react-redux";
import { createNewTask } from "../ReduxSetup/UserActions";
import { fetchUserProfilePersonalBasicData } from "../ReduxSetup/UserActions";
import { fetchAllUsers } from "../ReduxSetup/UserActions";
import { fetchAllTaskList } from "../ReduxSetup/UserActions";
import { fetchUpdateTaskDetails } from "../ReduxSetup/UserActions";
import { fetchClientDashboardData } from "../ReduxSetup/UserActions";
import { fetchTaskWithId } from "../ReduxSetup/UserActions";
import { fetchclients } from "../ReduxSetup/UserActions";
import { useNavigate, useParams } from "react-router";
import { te } from "date-fns/locale";

export default function Task({ name, ...props }) {
  const { taskIdInUrl } = useParams();
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const navigate = useNavigate();

  const handleCheckboxChange = (option) => {
    if (option.some((item) => item.value === "all")) {
      const reloadOptions = fetchAllUserTask.map((key) => {
        return { value: key.user_id, label: key.name };
      });
      setSelectedOptions(reloadOptions);
    } else {
      setSelectedOptions(option);
    }
  };
  const [addViewValue, setAddViewValue] = useState([]);

  const addViewOptions = [
    { value: "high", label: "High Priority" },
    { value: "medium", label: "Medium Priority" },
    { value: "low", label: "Low Priority" },
    { value: "urgent", label: "Urgent" },
  ];

  const handleAddView = (option) => {
    setAddViewValue(option);
    const { value } = option;
    let temp = [...fetchAllTaskReducer];
    temp = temp.filter((item) => {
      return item.priority === value;
    });
    setFilteredTasks(temp);
  };

  const [ides, setIdes] = useState("");
  // const createdBY = useSelector((state) => state.fetchUserProfilePersonalBasicDataReducer);
  // console.log('createdBY',createdBY)
  const createdBY = useSelector((state) => {
    if (
      state.fetchUserProfilePersonalBasicDataReducer &&
      state.fetchUserProfilePersonalBasicDataReducer.userData &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data[0]
    ) {
      return state.fetchUserProfilePersonalBasicDataReducer.userData.data[0];
    } else {
      return null;
    }
  });
  // console.log("createdBY", createdBY);

  const fetchAllUserTask = useSelector((state) => {
    if (
      state.fetchUserReducer &&
      state.fetchUserReducer.userData &&
      state.fetchUserReducer.userData.data
    ) {
      return state.fetchUserReducer.userData.data;
    }
    return [];
  });

  const taskUserOptions = fetchAllUserTask.map((key) => {
    return { value: key.user_id, label: key.name };
  });

  const fetchAllClients = useSelector((state) => {
    if (state.fetchAllClientsReducer && state.fetchAllClientsReducer.userData) {
      return state.fetchAllClientsReducer.userData;
    }
    return [];
  });

  // console.log("fetchAllUser9898", fetchAllUserTask);

  const userIdForTaskFetch = useSelector((state) => {
    if (
      state.fetchUserProfilePersonalBasicDataReducer &&
      state.fetchUserProfilePersonalBasicDataReducer.userData &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data[0] &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data[0].user_id
    ) {
      return state.fetchUserProfilePersonalBasicDataReducer.userData?.data[0]
        ?.user_id;
    } else {
      return null;
    }
  });
  // console.log("userIdForTaskFetch", userIdForTaskFetch);

  // fetch client data
  const fetchClientData = useSelector(
    (state) => state?.fetchClientData?.activeClientData?.message?.data
  );
  // console.log("fetchClientData..........", fetchClientData);

  const fetchAllTaskReducer = useSelector((state) => {
    if (
      state.fetchAllTaskReducer &&
      state.fetchAllTaskReducer.userData &&
      state.fetchAllTaskReducer.userData.data
    ) {
      return state.fetchAllTaskReducer.userData.data;
    } else {
      return [];
    }
  });
  // console.log("fetchAllTaskReducer", fetchAllTaskReducer);

  // useEffect(()=>{
  //   if(userIdForTaskFetch){
  //     dispatch(fetchAllTaskList(userIdForTaskFetch));
  //   }
  // },[dispatch,userIdForTaskFetch])

  useEffect(() => {
    dispatch(fetchUserProfilePersonalBasicData());
    dispatch(fetchClientDashboardData("client"));
    dispatch(fetchAllUsers());
    dispatch(fetchclients());
    if (fetchAllTaskReducer) {
      setFilteredTasks(fetchAllTaskReducer);
    }
  }, [dispatch]);

  useEffect(() => {
    if (selectedOptions.length > 0) {
      const temp = selectedOptions.map((item) => item.value);
      dispatch(fetchAllTaskList(temp));
    }
  }, [selectedOptions]);

  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const handleCloseLeft = () => setShow(false);
  const handleShowLeft = () => setShow(true);
  const handleCloseRight = () => {
    navigate("/taskpage");
    setShows(false);
  };
  const handleShowRight = (id) => {
    setShows(true);
    navigate(`/taskpage/${id}`);
  };

  useEffect(() => {
    if (taskIdInUrl) {
      dispatch(fetchTaskWithId(taskIdInUrl));
      setShows(true);
    } else {
      navigate("/taskpage");
    }
  }, [taskIdInUrl]);

  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
    setTimeout(() => {
      setPopupOpen(false);
    }, 2000);
  };

  const fetchTaskIdDetails = useSelector((state) => {
    if (
      state.fetchTaskWithIdReducer &&
      state.fetchTaskWithIdReducer.userData &&
      state.fetchTaskWithIdReducer.userData.data
    ) {
      return state.fetchTaskWithIdReducer.userData.data[0];
    } else {
      return null;
    }
  });
  // console.log("fetchTaskIdDetails..33...", fetchTaskIdDetails);
  // console.log("ID..", fetchTaskIdDetails?.task_id);
  // console.log("Notes", fetchTaskIdDetails?.notes);

  // console.log("Notes", fetchTaskIdDetails?.due_date);
  const timestamp = fetchTaskIdDetails?.due_date;
  const datePart = timestamp?.substring(0, 10);
  // console.log("datePart", datePart);

  // state manage for updated task list parameters starts
  const [updateAsignUserId, setUpdateAsignUserId] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");
  const [updateDueDate, setUpdateDueDate] = useState("");
  const [updateClientUserId, setUpdateClientUserId] = useState("");
  const [updateReminderTime, setUpdateReminderTime] = useState("");
  const [updatePriority, setUpdatePriority] = useState("");
  const [updateType, setUpdateType] = useState("");
  const [updateNotes, setUpdateNotes] = useState("");
  const [updateTaskName, setUpdateTaskName] = useState("");
  const [updateClientName, setUpdateClientName] = useState("");
  // state manage for updated task list parameters end

  const [taskName, setTaskName] = useState("");
  const [assignedUserId, setAssignedUserId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [time, setTime] = useState("");
  // const [createdBy,setCreatedBy] = useState('');
  const [sendReminder, setSendReminder] = useState("");
  const [priority, setPriority] = useState("");
  const [type, setType] = useState("");
  const [status, setStatus] = useState("");
  const [clientId, setClientId] = useState("");
  const [notes, setNotes] = useState("");
  const [filteredTasks, setFilteredTasks] = useState([]);

  useEffect(() => {
    if (fetchTaskIdDetails) {
      setUpdateNotes(fetchTaskIdDetails.notes);
      setUpdateTaskName(fetchTaskIdDetails.task_name);
      setUpdateDueDate(datePart);
      setUpdateReminderTime(fetchTaskIdDetails.reminder_time);
      setUpdatePriority(fetchTaskIdDetails.priority);
      setUpdateType(fetchTaskIdDetails.type);
      setUpdateStatus(fetchTaskIdDetails.status);
      setUpdateClientUserId(fetchTaskIdDetails.assigned_user_id);
      setUpdateAsignUserId(fetchTaskIdDetails.client_id);
      setUpdateClientName(fetchTaskIdDetails.client_name)
    }
  }, [fetchTaskIdDetails]);

  useEffect(() => {
    if (fetchAllTaskReducer) {
      setFilteredTasks(fetchAllTaskReducer);
    }
  }, [fetchAllTaskReducer]);

  useEffect(() => {
    if (fetchAllUserTask) {
      const reloadOptions = fetchAllUserTask.map((key) => {
        return { value: key.user_id, label: key.name };
      });
      setSelectedOptions(reloadOptions);
    }
  }, [fetchAllUserTask]);

  const MapTaskId = (id) => {
    setIdes(id);
    dispatch(fetchTaskWithId(id));
  };

  const handleTaskData = (e) => {
    e.preventDefault();
  
    const fullTime = time ? (dueDate + ' ' + time) : dueDate;

    if (
      taskName &&
      assignedUserId &&
      dueDate &&
      // time &&
      sendReminder &&
      priority &&
      type &&
      status &&
      clientId &&
      notes
    ) {
      console.log(
        taskName,
        assignedUserId,
        dueDate,
        time,
        sendReminder,
        priority,
        type,
        status,
        clientId,
        notes
      );
      const taskData = {
        task_name: taskName,
        assigned_user_id: assignedUserId,
        due_date: fullTime,
        reminder_time: sendReminder,
        priority: priority,
        type: type,
        status: status,
        client_id: clientId,
        notes: notes,
        created_by: createdBY?.user_id,
      };
      // dispatch(
      //   createNewTask(
      //     clientId,
      //     status,
      //     fullTime,
      //     assignedUserId,
      //     sendReminder,
      //     priority,
      //     type,
      //     notes,
      //     taskName,
      //     createdBY?.user_id
      //   )
      // );
      dispatch(createNewTask(taskData));
      setTaskName("");
      setAssignedUserId("");
      setDueDate("");
      setTime("");
      setNotes("");
      setSendReminder("");
      setPriority("");
      setType("");
      setStatus("");
      setClientId("");
      setShow(false);
    } else {
      // console.log("plese input valid fields")
      toast.error("Please input all the fields", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
    }

    // console.log("clientId", clientId);
    // console.log("status", status);
    // console.log("dueDate", dueDate);
    // console.log("assignedUserId", assignedUserId);
    // console.log("sendReminder", sendReminder);
    // console.log("priority", priority);
    // console.log("type", type);
    // console.log("notes", notes);
    // console.log("taskName", taskName);
    // console.log("createdBY", createdBY?.user_id);
  };

  const handleTaskFetchData = (e) => {
    e.preventDefault();
    const taskData = {
      assignedUserId: updateAsignUserId,
      status: updateStatus,
      dueDate: updateDueDate,
      clientId: updateClientUserId,
      reminderTime: updateReminderTime,
      priority: updatePriority,
      type: updateType,
      notes: updateNotes,
      taskName: updateTaskName,
      client_name: updateClientName
    };
    dispatch(
      fetchUpdateTaskDetails(fetchTaskIdDetails?.task_id, taskData)
      // fetchUpdateTaskDetails(
      //   fetchTaskIdDetails?.task_id,
      //   updateAsignUserId,
      //   updateStatus,
      //   updateDueDate,
      //   updateClientUserId,
      //   updateReminderTime,
      //   updatePriority,
      //   updateType,
      //   updateNotes,
      //   updateTaskName
      // )
    );
    setShows(false);
    console.log("updateClientUserId", updateClientUserId);
    console.log("updateStatus", updateStatus);
    console.log("updateDueDate", updateDueDate);
    console.log("updateAsignUserId", updateAsignUserId);
    console.log("updateReminderTime", updateReminderTime);
    console.log("updatePriority", updatePriority);
    console.log("updateType", updateType);
    console.log("updateNotes", updateNotes);
    console.log("updateTaskName", updateTaskName);
  };

  function isSameDate(date1, date2) {
    // Check if the year, month, and day of two dates are the same
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const [taskFilterActive, setTaskFilterActive] = useState("all");

  const handleTaskFilter = (field) => {
    // e.preventDefault();
    setTaskFilterActive(field);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let temp = [...fetchAllTaskReducer];

    if (field === "overdue") {
      temp = temp?.filter((task) => {
        return task.status === "pending" && new Date(task.due_date) < today;
      });
      setFilteredTasks(temp);
    } else if (field === "dueToday") {
      temp = temp?.filter((task) => {
        // Check if the status is pending and the due date is today
        return (
          task.status === "pending" &&
          isSameDate(new Date(task.due_date), today)
        );
      });
      setFilteredTasks(temp);
    } else if (field === "upcoming") {
      temp = temp?.filter((task) => {
        return task.status === "pending" && new Date(task.due_date) > today;
      });
      setFilteredTasks(temp);
    } else {
      setFilteredTasks(temp);
    }
  };

  return (
    <Container className="task-container-1">
      <div
        className="custom-width task-container-2 pb-1"
        style={{ margin: "0 auto" }}
      >
        <div className="div-header ">
          <p className="mt-4">Tasks</p>{" "}
          <Button1
            type="button"
            onClick={handleShowLeft}
            className="but-header mt-3 p-2 px-4"
            variant="secondary"
          >
            {" "}
            +&nbsp; Add New Task
          </Button1>
        </div>

        <div className="div-header-1">
          <p className="mt-2">Assigned to</p>

          <Select
            isMulti
            name="cs-manager"
            options={[
              { label: "Select All", value: "all" },
              ...taskUserOptions,
            ]}
            className="basic-multi-select"
            classNamePrefix="select"
            value={selectedOptions}
            onChange={handleCheckboxChange}
          />

          {/* <Dropdown>
            <Dropdown.Toggle
              variant="primary"
              id="dropdown-basic"
              className="text-dark"
            >
              Select Options
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {fetchAllUserTask.map((option) => (
                <Form.Check
                  type="checkbox"
                  key={option.user_id}
                  label={option.name}
                  checked={selectedOptions.includes(option.user_id)}
                  onChange={() => handleCheckboxChange(option.user_id)}
                />
              ))}
            </Dropdown.Menu>
          </Dropdown> */}

          {/* <Form.Select
            className="w-25 mx-1 h-25 mx-md-5 px-1"
            aria-label="Default select example"
            value={assignedUserId}
            onChange={(e) => setAssignedUserId(e.target.value)}
          >
            <option>Select User</option>
            { {fetchAllUserTask.map((task) => (
            <option onClick={setSelectedUser(prevState => [...prevState, task.user_id])} value={task.user_id}>{task.name}</option>
            ))}
          </Form.Select> */}
        </div>
        <Row className="d-flex justify-content-between">
          <Col xxl={6} xl={7} lg={8} md={10} sm={12} xs={12} xxs={12}>
            <div className="div-section mt-3">
              <button
                className={`${
                  taskFilterActive === "all" ? "bg-success text-white" : ""
                } task-btn-2 p-1 pe-4 ps-4 me-2 `}
                onClick={() => handleTaskFilter("all")}
              >
                All Tasks
              </button>
              <button
                className={`${
                  taskFilterActive === "overdue" ? "bg-success text-white" : ""
                } task-btn-2 p-1 pe-4 ps-4 me-2 `}
                onClick={() => handleTaskFilter("overdue")}
              >
                Overdue
              </button>

              <button
                className={`${
                  taskFilterActive === "dueToday" ? "bg-success text-white" : ""
                } task-btn-3 p-1 pe-4 ps-4 me-2 `}
                onClick={() => handleTaskFilter("dueToday")}
              >
                Due Today
              </button>

              <button
                className={`${
                  taskFilterActive === "upcoming" ? "bg-success text-white" : ""
                } task-btn-4 p-1 pe-4 ps-4 me-2 `}
                onClick={() => handleTaskFilter("upcoming")}
              >
                Upcoming
              </button>
            </div>
          </Col>

          <Col
            xxl={2}
            xl={2}
            lg={2}
            md={2}
            sm={2}
            xs={4}
            xxs={6}
            className="col-div ms-5 w-25"
          >
            <Select
              name="addView"
              options={addViewOptions}
              className="basic-multi-select mt-2"
              classNamePrefix="select"
              placeholder={"+ Add View"}
              value={addViewValue}
              onChange={handleAddView}
            />
          </Col>
        </Row>

        <Col>
          <div className="div-row2  mt-3 mb-5">
            <p className="row-text p-1 ">
              Active Task
              <span className="span-no border ">
                {selectedOptions.length > 0 ? filteredTasks?.length : 0}
              </span>
            </p>

            {/* one card to show task information ----------------------------------------------------------------------------------------- */}
            {selectedOptions?.length > 0 &&
              filteredTasks?.map((data) => (
                <p
                  className="mini-div"
                  onClick={() => MapTaskId(data?.task_id)}
                >
                  <p className="div-row3">
                    <span className="span-no border ">id:{data?.task_id}</span>{" "}
                    <span className="text-line">
                      <Button2
                        onClick={() => handleShowRight(data?.task_id)}
                        className="btn-popup btn "
                      >
                        {}
                        {data?.task_name}
                      </Button2>
                    </span>
                    <span className="span-date1">
                      {" "}
                      <img
                        src={Vector1}
                        width={14}
                        height={14}
                        className="img-fluid1 me-1 mb-1"
                        alt="vector"
                      />
                      Due:{data?.due_date.split("T")[0]}
                    </span>
                  </p>
                  <p className="div-row4">
                    Priority :{" "}
                    <span className="span-high">{data?.priority}</span>{" "}
                    <span className="dot1">
                      {/* <img
                    src={Vector2}
                    width={19}
                    height={5}
                    className="img-fluid"
                    alt="vector"
                  /> */}
                      <p className="div-row4">
                        Status :{" "}
                        <span className="span-high">{data.status}</span>{" "}
                      </p>
                    </span>
                  </p>
                </p>
              ))}

            {/* <p className="mini-div">
              <p className="div-row3">
                <span className="span-no border ">2</span>{" "}
                <span className="text-line">
                  <Button2 onClick={handleShowRight} className="btn-popup btn">
                    {" "}
                    {name}Follow up call with Vaisakh
                  </Button2>
                </span>
                <span className="span-date1">
                  {" "}
                  <img
                    src={Vector1}
                    width={14}
                    height={14}
                    className="img-fluid1 me-1 mb-1"
                    alt="vector"
                  />
                  Due: 09/01/2023
                </span>
              </p> */}
            {/* <p className="div-row4">
                Priority : <span className="span-high">High</span>{" "}
                <span className="dot1">
                  <img
                    src={Vector2}
                    width={19}
                    height={5}
                    className="img-fluid"
                    alt="vector"
                  />
                </span>
              </p>
            </p> */}

            {/* <p className="row-text p-1 ">
              Completed Tasks<span className="span-no border ">1</span>
            </p>

            <p className="mini-div1 mb-4">
              <p className="div-row-5">
                <span className="span-no1 border ">✓</span>{" "}
                <span className="text-line1">Follow up call with Vaisakh</span>
                <span className="span-date1">
                  {" "}
                  <img
                    src={Vector1}
                    width={14}
                    height={14}
                    className="img-fluid1 me-1 mb-1"
                    alt="vector"
                  />
                  Due: 09/01/2023
                </span>
              </p>
              <p className="div-row4">
                Priority : <span className="span-high">High</span>{" "}
                <span className="dot1">
                  <img
                    src={Vector2}
                    width={19}
                    height={5}
                    className="img-fluid"
                    alt="vector"
                  />
                </span>
              </p>
            </p> */}
          </div>
        </Col>
      </div>

      {/* add new task slider starts ---------------------------------------------------------------------- */}

      <Container className="fluid">
        <Offcanvas
          style={{ width: "800px" }}
          show={show}
          onHide={handleCloseLeft}
          placement="end"
          {...props}
        >
          <Offcanvas.Header closeButton className="close-btn-offcanvas">
            <Offcanvas.Title>Add New Task</Offcanvas.Title>
            <BsArrowRightCircle className="svg-offcanvas" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* add new task real form starts --------------------------------------------------------------------------------------------- */}
            <Form onSubmit={handleTaskData}>
              <Row className="offcanvas-row-1">
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={7}
                  sm={12}
                  className="offcanvas-col-1"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className="mb-1"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="12" className="lable-text">
                        Task Name<span className="text-span">*</span>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          className="p-3"
                          type="text"
                          placeholder="Enter task name"
                          value={taskName}
                          onChange={(e) => setTaskName(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                  className="offcanvas-col-1 mt-3"
                >
                  <div>
                    <span className="offcanvas-span mb-2">Assigned User</span>
                    <Form.Select
                      className="task-dropdown-20 mt-2 p-3 "
                      aria-label="Default select example"
                      value={assignedUserId}
                      onChange={(e) => setAssignedUserId(e.target.value)}
                    >
                      <option>Select User</option>
                      {fetchAllUserTask.map((task, i1) => (
                        <option key={i1} value={task.user_id}>
                          {task.name}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control
                          className="task-dropdown-20 mt-2 p-3"
                          type="number"
                          placeholder="Assigned UserId"
                          value={assignedUserId}
                          onChange={(e) => setAssignedUserId(e.target.value)}
                        /> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={4}
                  xl={7}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-2"
                >
                  <div>
                    <Form>
                      <Form.Group
                        as={Row}
                        className=" offcanvas-lable mb-1"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="4" className="lable-text">
                          Due Date<span className="text-span">*</span>
                        </Form.Label>
                        <br></br>
                        <Col sm="12">
                          <Form.Control
                            className="p-3"
                            type="Date"
                            placeholder="09/01/2023"
                            value={dueDate}
                            onChange={(e) => setDueDate(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                <Col
                  xxl={3}
                  xl={7}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-2"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable mb-1"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        Created By<span className="text-span">*</span>
                      </Form.Label>
                      <br></br>
                      <Col sm="12">
                        <Form.Control
                          className="task-dropdown-20 mt-0 p-3"
                          type="text"
                          placeholder="Assigned UserId"
                          value={createdBY?.name}
                          // onChange={(e)=> setCreatedBy(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col
                  xxl={5}
                  xl={5}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-3"
                >
                  <div>
                    <span className="offcanvas-span mb-2  ">Send Reminder</span>
                    <Form.Select
                      className="task-dropdown-20  p-3 mt-3 "
                      aria-label="Default select example"
                      value={sendReminder}
                      onChange={(e) => setSendReminder(e.target.value)}
                    >
                      <option>Select</option>
                      <option value="30">30 minutes before</option>
                      {/* <option value="40">40 minutes before</option> */}
                      {/* <option value="50">50 minutes before</option> */}
                      <option value="60">1 hour before</option>
                      <option value="240">4 hours before</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  className="offcanvas-col mt-2"
                >
                  <div>
                    <span className="offcanvas-span mb-2">Priority *</span>

                    <Form.Select
                      className="task-dropdown-3 mt-1 py-3 px-5"
                      aria-label="Default select example"
                      ariaValueText
                      value={priority}
                      onChange={(e) => setPriority(e.target.value)}
                    >
                      <option>Select Priority</option>
                      <option value="urgent">urgent</option>
                      <option value="high">high</option>
                      <option value="medium">medium</option>
                      <option value="low">low</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col
                  xxl={3}
                  xl={3}
                  lg={3}
                  md={3}
                  sm={6}
                  className="offcanvas-col mt-2"
                >
                  <div>
                    <span className="offcanvas-span mb-2">Type *</span>
                    <Form.Select
                      className="task-dropdown mt-1 py-3 "
                      aria-label="Default select example"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                    >
                      <option>select</option>
                      <option value="call">call</option>
                      <option value="email">E-Mail</option>
                      <option value="emeet">E-Meet</option>
                      <option value="todo">To-Do</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col
                  xxl={3}
                  xl={3}
                  lg={3}
                  md={3}
                  sm={6}
                  className="offcanvas-col"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        Time
                      </Form.Label>
                      <br></br>
                      <Col sm="12">
                        <Form.Control
                          className="p-3"
                          type="Time"
                          placeholder="12:00"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={12}
                  xl={10}
                  lg={8}
                  md={6}
                  sm={4}
                  className="offcanvas-col mt-2"
                >
                  <p className="text-heading-offcanvas">Associate task with</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={4}
                  sm={6}
                  className="offcanvas-col mt-2"
                >
                  <div>
                    <span className="offcanvas-span mb-2 ">Status *</span>
                    <Form.Select
                      className="input-offcanvas mt-2 py-3 px-5 "
                      aria-label="Default select example"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option>Select</option>
                      <option value="pending">Pending</option>
                      <option value="Hold">Hold</option>
                      <option value="Done">Done</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={4}
                  sm={6}
                  className="offcanvas-col mt-2"
                >
                  <div>
                    <span className="offcanvas-span mb-2 mx-2 ">
                      Client Name *
                    </span>
                    <Form.Select
                      className="task-dropdown-3 mt-2 mx-2 py-3 px-5"
                      aria-label="Default select example"
                      value={clientId}
                      onChange={(e) => setClientId(e.target.value)}
                    >
                      <option>Select</option>
                      {fetchAllClients?.map((item, index) => {
                        return (
                          <option key={index} value={item.client_id}>
                            {item.client_name}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {/* <Form.Control
                      className="task-dropdown-20 mt-2 p-3"
                      type="number"
                      placeholder="Assigned Clinet Id"
                      value={clientId}
                      onChange={(e) => setClientId(e.target.value)}
                    /> */}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xxl={12} xl={12} lg={8} md={12} sm={12} className="mt-2">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="12" className="lable-text">
                      Note<span className="text-span">*</span>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        className="p-3"
                        as="textarea"
                        rows={2}
                        placeholder="note"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className="col-offcanvas mt-3 mb-2"
              >
                <div>
                  {/* <Button type="button" className="btn-offcanvas-1 py-2 px-5">
                    Cancel
                  </Button> */}
                  <Button
                    type="submit"
                    className="btn-offcanvas-2 py-2 px-5"
                    // onClick={togglePopup}
                    onClick={() => window.location.reload()}
                  >
                    Add
                  </Button>
                </div>
              </Col>
            </Form>
            {/* add new task real form ends --------------------------------------------------------------------------------------------- */}
          </Offcanvas.Body>
        </Offcanvas>
      </Container>

      {isPopupOpen && (
        <div className="overlay">
          <Popup onClose={togglePopup} />
        </div>
      )}

      <Row>
        <Offcanvas
          style={{ width: "800px" }}
          show={shows}
          onHide={handleCloseRight}
          placement="end"
          {...props}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{updateTaskName}</Offcanvas.Title>
            <BsArrowRightCircle className="svg-offcanvas" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* task detail form starts --------------------------------------------------------------------------------------*/}
            <Form onSubmit={handleTaskFetchData}>
              <Row>
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={7}
                  sm={8}
                  className="offcanvas-col-1"
                >
                  {/* client id for ------------------------------------------------------------------- */}
                  <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable mb-1"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        <span className="offcanvas-span mt-3 ">assignUser</span>
                        <span className="text-span">*</span>
                      </Form.Label>

                      <Form.Select
                        className="task-dropdown-2"
                        aria-label="Default select example"
                        value={updateAsignUserId}
                        onChange={(e) => setUpdateAsignUserId(e.target.value)}
                      >
                        {/* {fetchClientData?.map((data) =>( */}
                        {fetchAllUserTask.map((assign) => (
                          <option value={assign.user_id}>{assign.name}</option>
                        ))}
                        {/* // ))} */}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {/* client id for ------------------------------------------------------------------- */}

                  <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable mb-1"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        <span className="offcanvas-span mt-3 ">Client</span>
                        <span className="text-span">*</span>
                      </Form.Label>

                      <Form.Select
                        className="task-dropdown-2"
                        aria-label="Default select example"
                        value={updateClientUserId}
                        onChange={(e) => setUpdateClientUserId(e.target.value)}
                      >
                        {fetchClientData?.map((data) => (
                          <option value={data.client_id}>
                            {data.client_name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} className="mt-1">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="12" className="lable-text">
                      Note<span className="text-span">*</span>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        className="p-3"
                        as="textarea"
                        rows={3}
                        placeholder="notes"
                        value={updateNotes}
                        onChange={(e) => setUpdateNotes(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xxl={12} xl={12} lg={12} md={12} sm={12} className="mt-1">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="12" className="lable-text">
                      TaskName<span className="text-span">*</span>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        style={{ height: "52px" }}
                        className="p-3"
                        as="textarea"
                        rows={3}
                        placeholder="notes"
                        value={updateTaskName}
                        onChange={(e) => setUpdateTaskName(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col
                  xxl={4}
                  xl={7}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-2"
                >
                  <div>
                    <Form>
                      <Form.Group
                        as={Row}
                        className=" offcanvas-lable mb-1"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="4" className="lable-text">
                          DueDate<span className="text-span">*</span>
                        </Form.Label>
                        <br></br>
                        <Col sm="12">
                          <Form.Control
                            className="p-3"
                            type="input"
                            placeholder="DD-MM-YYYY"
                            value={updateDueDate}
                            onChange={(e) => setUpdateDueDate(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                {/* <Col
                  xxl={4}
                  xl={7}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-2"
                > */}
                {/* <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable mb-1"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        Time<span className="text-span">*</span>
                      </Form.Label>
                      <br></br>
                      <Col sm="12">
                        <Form.Control
                          className="p-3"
                          type="Time"
                          placeholder="12:00"
                        />
                      </Col>
                    </Form.Group>
                  </div> */}
                {/* </Col> */}
                <Col
                  xxl={4}
                  xl={5}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-3"
                >
                  <div>
                    <span className="offcanvas-span mb-2  ">Send Reminder</span>
                    <Form.Select
                      className="task-dropdown-20  p-3 mt-3 "
                      aria-label="Default select example"
                      value={updateReminderTime}
                      onChange={(e) => setUpdateReminderTime(e.target.value)}
                    >
                      <option value="10">10 minutes</option>
                      <option value="20">20 minutes</option>
                      <option value="30">30 minutes</option>
                      <option value="40">40 minutes</option>
                      <option value="50">50 minutes</option>
                      <option value="60">60 minutes</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  className="offcanvas-col mt-2"
                >
                  <div>
                    <span className="offcanvas-span mb-5 ms-2">Priority *</span>
                    <Form.Select
                      className="task-dropdown-3 mt-1 "
                      aria-label="Default select example"
                      value={updatePriority}
                      onChange={(e) => setUpdatePriority(e.target.value)}
                    >
                      <option value="urgent">urgent</option>
                      <option value="high">high</option>
                      <option value="medium">medium</option>
                      <option value="low">low</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  className="offcanvas-col mt-2"
                >
                  <div>
                    <span className="offcanvas-span mb-5 ms-3">Type *</span>
                    <Form.Select
                      className="task-dropdown-3 mt-1 "
                      aria-label="Default select example"
                      value={updateType}
                      onChange={(e) => setUpdateType(e.target.value)}
                    >
                      <option value="select">select</option>
                      <option value="call">call</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={12}
                  xl={10}
                  lg={8}
                  md={6}
                  sm={4}
                  className="offcanvas-col mt-2"
                >
                  <p className="text-heading-offcanvas">Associate task with</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  className="offcanvas-col mt-2"
                >
                  <div>
                    <span className="offcanvas-span mb-5 ms-2">Status *</span>
                    <Form.Select
                      className="task-dropdown-3 mt-1 "
                      aria-label="Default select example"
                      value={updateStatus}
                      onChange={(e) => setUpdateStatus(e.target.value)}
                    >
                      <option value="Pending">Pending</option>
                      <option value="Hold">Hold</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  className="offcanvas-col mt-2"
                ></Col>
              </Row>
              <Row>
                <Col
                  xxl={12}
                  xl={10}
                  lg={8}
                  md={6}
                  sm={4}
                  className="offcanvas-col mt-2"
                >
                  <p className="text-heading-offcanvas"></p>
                </Col>
              </Row>

              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className="col-offcanvas mt-3 mb-3"
              >
                <div>
                  {/* <Button type="button" className="btn-offcanvas-4 py-2 px-5">
                    Cancel
                  </Button> */}
                  <Button type="submit" className="btn-offcanvas-2 py-2 px-4">
                    Update Changes
                  </Button>
                </div>
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </Row>
    </Container>
  );
}
