import React from "react";
import Sidebar from "./components/Sidebar";
import "./css/App.css";
import "./css/media.css";
import "./css/CustomMedia.css";
import Header from "./components/Header";
import Homepage from "./Pages/Homepage";
import ViewAllNotes from "./Pages/ViewAllNotes";
import "./Fonts/stylesheet.css";
import { Routes, Route, useLocation } from "react-router-dom";
import TaskPage from "./Pages/TaskPage";
import LoginPage from "./Pages/LoginPage";
import RegisterPage from "./Pages/RegisterPage";
import ClientsPage from "./Pages/ClientsPage";
import Claincoaster from "./Pages/OrganizationSetting";
import UserProfilePage from "./Pages/UserProfilePage";
import PrivateRoutes from "./PrivateRoutes/PrivateRoutes";
import CalenderPage from "./Pages/CalenderPage";
import ClientNotes from "./Pages/ClientNotes";
import Loader from "./components/Loader";
import { useSelector } from "react-redux";
import ContactPage from "./Pages/ContactPage";

function App() {
  const location = useLocation();
  const routesWithoutHeaderAndSidebar = ["/", "/registration"];
  const shouldRendeHeaderAndSidebar = !routesWithoutHeaderAndSidebar.includes(
    location.pathname
  );

  const isLoading = useSelector((state) => state.loaderReducer.isLoading);
  return (
    <>
      {isLoading && <Loader />}

      <div className="App" style={{ backgroundColor: "#FAFAFA" }}>
        {shouldRendeHeaderAndSidebar && <Header />}
        {shouldRendeHeaderAndSidebar && <Sidebar />}

        <Routes>
          <Route exact path="/" element={<LoginPage />} />
          <Route exact path="/registration" element={<RegisterPage />} />
          <Route
            exact
            path="/homepage"
            element={<PrivateRoutes component={Homepage} />}
          />
          {/* <Route
            exact
            path="/viewallnotes"
            element={<PrivateRoutes component={ViewAllNotes} />}
          /> */}
          <Route
            exact
            path="/ClientNotes/:clientId"
            element={<PrivateRoutes component={ClientNotes} />}
          />
          <Route
            exact
            path="/taskpage"
            element={<PrivateRoutes component={TaskPage} />}
          />
          <Route
            exact
            path="/taskpage/:taskIdInUrl"
            element={<PrivateRoutes component={TaskPage} />}
          />
          <Route
            exact
            path="/client-dashboard"
            element={<PrivateRoutes component={ClientsPage} />}
          />
          <Route
            exact
            path="/archived-clients"
            element={<PrivateRoutes component={ClientsPage} />}
          />
          <Route
            exact
            path="/secondary-contact/:clientId"
            element={<PrivateRoutes component={ContactPage} />}
          />
          <Route
            exact
            path="/organization-setting"
            element={<PrivateRoutes component={Claincoaster} />}
          />
          <Route
            exact
            path="/user-profile"
            element={<PrivateRoutes component={UserProfilePage} />}
          />

          <Route
            exact
            path="/calender"
            element={<PrivateRoutes component={CalenderPage} />}
          />
        </Routes>
      </div>
    </>
  );
}

export default App;
