import React from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const ActivePieChart = ({ good_health_percentage, average_health_percentage, poor_health_percentage, total_clients }) => {

  const data = [
    { name: 'Group A', value: good_health_percentage },
    { name: 'Group B', value: average_health_percentage },
    { name: 'Group C', value: poor_health_percentage },

  ];


  const COLORS = ['#27A376', '#FFD023', '#E03137'];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {/* {`${(percent * 100).toFixed(0)}%`} */}
      </text>
    );
  };

  return (
    <>
      <ResponsiveContainer width="100%" height="60%" className="graph-chart-2">
        <PieChart width={300} height={300}>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>

      </ResponsiveContainer>
      <div className=' test-chart mt-lg-0 mt-3'>
        <div className='test-chart test-chart2'>
          <div style={{ textAlign: 'center' }}>
            <h4>{total_clients}</h4>
            <span>Total Clients</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivePieChart;
