import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Edit from "../images/edit.svg";
import Good from "../images/good-icn.svg";
import Average from "../images/average-icn.svg";
import Poor from "../images/poor-icn.svg";
import Email from "../images/email.svg";
import Phone from "../images/phone.svg";
import Share from "../images/share.svg";
import chevron from "../images/chevron-right.svg";
import calendar from "../images/calendar.svg";
import Reply from "../images/material-symbols_reply.svg";
import ReplyAll from "../images/material-symbols_reply-all.svg";
import Forward from "../images/material-symbols_forward.svg";
import timer from "../images/timer.svg";
import { Card } from "react-bootstrap";
import { Container, Row, Col, Button } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import toast from "react-hot-toast";
import ClipLoader from "react-spinners/ClipLoader";
import { IoIosArrowForward, IoIosCloseCircle } from "react-icons/io";
// import { WithContext as ReactTags } from "react-tag-input";
import Vector1 from "../images/Vector-chart.png";
import Parser from "html-react-parser";
import { RxCrossCircled } from "react-icons/rx";

import {
  fetchUserProfilePersonalBasicData,
  deleteTag,
  fetchClientWiseEmailRecipients,
  fetchClientDataClientWise,
  fetchEmailClientWise,
  fetchAllUsers,
  sendEmailAction,
  updateClientData,
  updatePocDetail,
  fetchClientTasks,
  updateRenewalDate,
} from "../ReduxSetup/UserActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Select from "react-select";
import TaskPopup from "../components/TaskPopup";
import { HiOutlineHome } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";

const ClientNotes = () => {
  const params = useParams();
  const navigate  = useNavigate()
  // console.log(params.clientId);
  const dispatch = useDispatch();

  const fromEmail = useSelector((state) => {
    if (
      state.fetchUserProfilePersonalBasicDataReducer &&
      state.fetchUserProfilePersonalBasicDataReducer.userData &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data
    ) {
      return state.fetchUserProfilePersonalBasicDataReducer.userData.data[0]
        .email;
    } else {
      return null;
    }
  });
  // console.log("fromEmail", fromEmail);

  // USE SELECTOR RO FETCH CLIENT WISE DATA --------------------------------
  const clientWiseData = useSelector((state) => {
    if (
      state.fetchClientDataClientWiseReducer &&
      state.fetchClientDataClientWiseReducer.userData &&
      state.fetchClientDataClientWiseReducer.userData.data
    ) {
      return state.fetchClientDataClientWiseReducer.userData.data[0];
    } else {
      return null;
    }
  });
  // console.log("PPclientWiseData", clientWiseData);

  const fetchClientWiseEmail = useSelector(
    (state) => state?.fetchEmailClientWiseReducer?.userData?.data
  );

  const fetchClientWiseTasks = useSelector(
    (state) => state?.fetchClientTasksReducer?.userData?.data
  );

  // if (fetchClientWiseEmail) {
  //   console.log(".............fetchClientWiseEmail", fetchClientWiseEmail);
  // }

  // fetch all user
  const fetchAllUser = useSelector((state) => {
    if (
      state.fetchUserReducer &&
      state.fetchUserReducer.userData &&
      state.fetchUserReducer.userData.data
    ) {
      return state.fetchUserReducer.userData.data;
    }
    return [];
  });
  // console.log('fetchAllUser',fetchAllUser);
  const [managerValues, setManagerValues] = useState([]);

  const managerOptions = fetchAllUser.map((key) => {
    return { value: key.user_id, label: key.name };
  });

  const handleManagerChange = (option) => {
    if (option.length > 2) {
      toast.error("Cannot Assign more than 2 CS Managers");
      return;
    }
    setManagerValues(option);
    const data = {
      clientId: params.clientId,
      updateType: "csManager",
      csmanager: option.map((item) => item.value),
    };
    dispatch(updateClientData(data));
  };

  useEffect(() => {
    dispatch(fetchUserProfilePersonalBasicData());
    dispatch(fetchClientDataClientWise(params.clientId));
    dispatch(fetchEmailClientWise(params.clientId));
    dispatch(fetchClientWiseEmailRecipients(params.clientId));
    dispatch(fetchAllUsers());
    dispatch(fetchClientTasks(params.clientId));

    if (clientWiseData) {
      setCompanyName(clientWiseData?.company_name);
      setPriority(clientWiseData?.priority);
      setMrr(clientWiseData?.mrr);
      setArr(clientWiseData?.arr);
      const currentCSM =
        clientWiseData?.csm_array[0] === null
          ? []
          : clientWiseData.csm_array.map((item) => ({
              value: item.csm_id,
              label: item.csm_name,
            }));
      setManagerValues(currentCSM);
      const currentTags =
        clientWiseData?.tag_array[0] === null
          ? []
          : clientWiseData.tag_array.map((item) => ({
              value: item.tag_id,
              label: item.tag_name,
            }));
      setTags(currentTags);
      setContactSection({
        pocName: clientWiseData?.contact_name,
        founder: clientWiseData?.designation,
        contact: clientWiseData?.phone_number,
        email: clientWiseData?.email,
      });
    }
  }, [dispatch]);

  useEffect(() => {
    if (clientWiseData) {
      setCompanyName(clientWiseData?.company_name);
      setPriority(clientWiseData?.priority);
      setMrr(clientWiseData?.mrr);
      setArr(clientWiseData?.arr);
      const currentCSM =
        clientWiseData?.csm_array[0] === null
          ? []
          : clientWiseData.csm_array.map((item) => ({
              value: item.csm_id,
              label: item.csm_name,
            }));
      setManagerValues(currentCSM);
      const currentTags =
        clientWiseData?.tag_array[0] === null
          ? []
          : clientWiseData.tag_array.map((item) => ({
              value: item.tag_id,
              label: item.tag_name,
            }));
      setTags(currentTags);
      setContactSection({
        pocName: clientWiseData?.contact_name,
        founder: clientWiseData?.designation,
        contact: clientWiseData?.phone_number,
        email: clientWiseData?.email,
      });
    }
  }, [clientWiseData]);

  const modules = {
    toolbar: [
      [{ header: "1" }, { font: [] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["link"],
      [{ color: [] }, { background: [] }],
      ["emoji"],
    ],
  };

  const formats = [
    "header",
    "font",
    "list",
    "bold",
    "italic",
    "underline",
    "align",
    "link",
    "color",
    "background",
    "emoji",
  ];

  //for updating client data ==================
  const [mrr, setMrr] = useState(clientWiseData?.mrr);
  const [arr, setArr] = useState(clientWiseData?.arr);
  const [companyName, setCompanyName] = useState(clientWiseData?.company_name);

  const [tags, setTags] = useState([]);
  const [newTag, setnewTag] = useState("");

  const [priority, setPriority] = useState(clientWiseData?.priority);
  const [editCompanyName, seteditCompanyName] = useState(false);
  const [editMrr, seteditMrr] = useState(false);
  const [isRenewDateEditable, setIsRenewDateEditable] = useState(false);
  const [renewalDate, setRenewalDate] = useState(
    clientWiseData?.renewal_date.split(" ")[0] || "----/---/---"
  );
  const handleInfoChange = (e, field) => {
    let data = {
      clientId: params.clientId,
      updateType: field,
    };
    if (field === "companyName") {
      data.companyName = companyName;
      seteditCompanyName(false);
    } else if (field === "mrr") {
      data.mrr = mrr;
      seteditMrr(false);
    } else if (field === "priority") {
      data.priority = e.target.value;
    } else if (field === "tags") {
      if (newTag === "") {
        toast.error("Tag cannot be empty");
      } else if (tags.includes(newTag)) {
        toast.error("tag already exist");
      } else {
        setTags((prev) => [...prev, newTag]);
        data.tags = [newTag];
        dispatch(updateClientData(data));
        dispatch(fetchClientDataClientWise(params.clientId));
        // window.location.reload();

        // setTags((prev) => {
        //   const updatedTags = [...prev, newTag];
        //   data.tags = updatedTags;
        //   return updatedTags;
        // });
      }
      setnewTag("");
    }

    if (field !== "tags") {
      dispatch(updateClientData(data));
      dispatch(fetchClientDataClientWise(params.clientId));
    }
  };

  const handleDeleteTag = (tag) => {
    const cid = parseInt(params.clientId);
    dispatch(deleteTag(tag.value, cid));
    dispatch(fetchClientDataClientWise(params.clientId));
  };

  // =========================

  // senbd mail useState format starts  --------------------------------
  const [to, setTo] = useState("");
  const [subject, setSubject] = useState("");
  const [recipientClientId, setRecipientClientId] = useState(null);

  const [editorContent, setEditorContent] = useState("");

  // send mail useState format ends  --------------------------------

  const [showSidebar2, setShowSidebar2] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addclass, setaddclass] = useState(false);
  const toggleclass = () => {
    setaddclass(!addclass);
  };
  useEffect(() => {
    if (addclass) {
      document.body.classList.add("body-blur");
    } else {
      document.body.classList.remove("body-blur");
    }
    return () => {
      document.body.classList.remove("body-blur");
    };
  }, [addclass]);
  // const [file, setFile] = useState(null);
  // const [tags1, setTags1] = useState([]);
  // const [tags2, setTags2] = useState([]);

  // const handleDelete1 = (i) => {
  //   setTags1(tags1.filter((tag, index) => index !== i));
  // };

  // const handleAddition1 = (tag) => {
  //   setTags1([...tags1, tag]);
  // };

  // const handleDelete2 = (i) => {
  //   setTags2(tags2.filter((tag, index) => index !== i));
  // };

  // const handleAddition2 = (tag) => {
  //   setTags2([...tags2, tag]);
  // };

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  const recipientEmails = useSelector((state) => {
    if (
      state.fetchedClientEmailsReducer &&
      state.fetchedClientEmailsReducer.userData &&
      state.fetchedClientEmailsReducer.userData.data
    ) {
      return state.fetchedClientEmailsReducer.userData.data;
    }
  });
  
  const recipientOptions = (recipientEmails || [])
    ?.map((item) => {
      if (item?.email && params?.clientId) {
        return { label: item?.email, value: params?.clientId };
      }
      return undefined;
    })
    .filter((option) => option !== undefined);

  const selectedValues = recipientOptions.filter(option => to.includes(option.label));

  const handleRecipientEmail = (selectedOptions) => {
    // const email_ids = option?.map((a) => a.label);
  const email_ids = selectedOptions ? selectedOptions.map((option) => option.label) : [];
    setTo(email_ids);
    setRecipientClientId(params.clientId);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here, e.g., upload the file or save the tags.
    // console.log("to:", to);
    // console.log("subject:", subject);
    // console.log("editorContent:", editorContent);

    if (to && subject && editorContent && recipientClientId) {
      // Dispatch the action only when data is filled
      dispatch(sendEmailAction(to, subject, editorContent, recipientClientId));
      setTo("");
      setSubject("");
      setEditorContent("");
      setSidebarOpen(!sidebarOpen);
      toggleclass();
    } else {
      // Handle the case when data is empty or incomplete (e.g., show an error message)
      // console.log('Please fill in all required fields.');
      toast.error("Please fill in all required fields.", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
  };

  // const [selectedOption, setSelectedOption] = useState("");

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };

  const [showInput, setShowInput] = useState(false);

  const handleCheckboxChange = (event) => {
    setShowInput(event.target.checked);
  };

  // fetch client email data ----------------------------------------------------------------------------------------

  const [cards, setCards] = useState([]);
  const [fetchUser, setFetchUser] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    // Fetch data and update fetchUser when fetchClientWiseEmail changes
    setTimeout(() => {
      if (fetchClientWiseEmail) {
        const newUser = fetchClientWiseEmail.map((user) => {
          setLoading(true);

          // console.log(user.subject)
          // console.log(JSON?.parse(user?.to_email));
          // const emailArray = inputString.slice(1, -1).replaceAll('"','').split(',').map(email => email.trim());
          return {
            title: user.subject,
            subtitle: user.from_email,
            recipients: user.to_email,
            date: user.date,
            greeting: "",
            content: Parser(user.body),
            closing: (
              <>
                best, <br /> Shivanshi
              </>
            ),
            isExpanded: false,
          };
        });
        setFetchUser(newUser);
      } else {
        // If fetchClientWiseEmail is falsy, reset fetchUser to an empty array
        setFetchUser([]);
      }
      setLoading(false); // Set loading to false when data is fetched
    }, 1000);
  }, [fetchClientWiseEmail]);

  useEffect(() => {
    // Update cards state when fetchUser changes
    setCards([...fetchUser]);
  }, [fetchUser]);

  const cardData2 = [
    {
      title: "Notes",
      subtitle: "From Shivanshi Bhatia",
      recipients: "",
      date: "09/10/2023 at 2:07 EST",
      greeting: (
        <>
          Follow up with the sales guy about the numbers.{" "}
          <b style={{ color: "#27A376" }}>@Jack Stellao.</b> The MRR amount
          looks incorrect for Creto
        </>
      ),
      content: "",
      closing: (
        <>
          best, <br /> Shivanshi
        </>
      ),
      isExpanded: false,
    },
    {
      title: "Notes",
      subtitle: "From Ankur Bhatia",
      recipients: "",
      date: "09/11/2023 at 3:00 EST",
      greeting: (
        <>
          Follow up with the sales guy about the numbers.{" "}
          <b style={{ color: "#27A376" }}>@Jack Stellao.</b> The MRR amount
          looks incorrect for Creto
        </>
      ),
      content: "",
      closing: "Regards, Shivanshi",
      isExpanded: false,
    },
    // ... add more card data as needed
  ];
  const [cards2, setCards2] = useState(cardData2);

  // const updateCards = (newData) => {
  //   setCards(newData);
  // };

  const toggleExpand = (index) => {
    const updatedCards = cards.map((card, cardIndex) =>
      cardIndex === index ? { ...card, isExpanded: !card.isExpanded } : card
    );
    setCards(updatedCards);
  };

  const handleBack = (index) => {
    const updatedCards = cards.map((card, cardIndex) =>
      cardIndex === index ? { ...card, isExpanded: false } : card
    );
    setCards(updatedCards);
  };
  const toggleExpand2 = (index) => {
    const updatedCards = cards2.map((card, cardIndex) =>
      cardIndex === index ? { ...card, isExpanded: !card.isExpanded } : card
    );
    setCards2(updatedCards);
  };

  const handleBack2 = (index) => {
    const updatedCards = cards2.map((card, cardIndex) =>
      cardIndex === index ? { ...card, isExpanded: false } : card
    );
    setCards2(updatedCards);
  };

  const [selectedOption1, setSelectedOption1] = useState("1");

  const handleChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    setSelectedOption1(value);
    if (value === "1") dispatch(fetchEmailClientWise(params.clientId));
    else dispatch(fetchClientTasks(params.clientId));
  };

  const handleEmailSort = (e) => {
    let sortedEmailCards;
    if (e.target.value === "1") {
      sortedEmailCards = [...cards].sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
    } else {
      sortedEmailCards = [...cards].sort(
        (a, b) => new Date(a.date) - new Date(b.date)
      );
    }
    setCards(sortedEmailCards);
  };

  const [editContactSection, setEditContactSection] = useState(true);
  const [contactSection, setContactSection] = useState({
    pocName: clientWiseData?.contact_name || "",
    founder: clientWiseData?.designation || "",
    email: clientWiseData?.email || "",
    contact: clientWiseData?.phone_number || 9812,
  });

  const handleEditContactSection = (e) => {
    e.preventDefault();

    const data = {
      type: "primary",
      clientId: params.clientId,
      pocName: contactSection.pocName,
      pocDesignation: contactSection.founder,
      pocEmail: contactSection.email,
      pocPhoneNumber: contactSection.contact,
    };
    dispatch(updatePocDetail(data));
    setEditContactSection(!editContactSection);
    dispatch(fetchClientDataClientWise(params.clientId));
  };

  const [showTaskPopup, setShowTaskPopup] = useState(false);
  const tempTaskData = {
    task_id: 2,
    client_id: 2,
    client_name: "Shivanshi Bhatia",
    company_name: "Shivanshi Bhatia",
    status: "pending",
    due_date: "2024-03-26T19:21:00.000Z",
    assigned_user_id: 9,
    assigned_user_name: "Keshav",
    reminder_time: 30,
    priority: "urgent",
    type: "call",
    notes: "testing for new task on individual client",
    created_on: "2024-03-20T16:20:57.000Z",
    created_by: 9,
    task_name: "testing ",
    created_by_name: "Keshav",
  };

  const [taskData, setTaskData] = useState({});

  return (
    <div>
      <section class="custom-width" style={{ margin: "0 auto" }}>
        <Container>
          <Row>
            <div className="top-functions align-items-center">
              <Col md={6} className="ps-4">
                {/* breadcrumbs */}
                <div className="d-flex ms-4 align-items-center">
                  <span className="mx-2 font9 d-flex align-items-center" role="button" onClick={() => navigate('/homepage')}>
                    <HiOutlineHome className="fs-5 mx-1" /> Home
                  </span>
                  <IoIosArrowForward className="fs-5" />
                  <span className="mx-1 text-success font9 d-flex align-items-center" role="button" onClick={() => navigate('/client-dashboard')}>
                    <CgProfile className="fs-5 mx-1" /> Client Dashboard
                  </span>
                  <IoIosArrowForward className="fs-5" />
                  <span className="mx-1 text-success font9 d-flex align-items-center">
                    <CgProfile className="fs-5 mx-1" />
                    {companyName}
                  </span>
                </div>
              </Col>
              <Col md={6}>
                <div class="crt-buttons-outer2">
                  {/* <button
                    className="new-deal-btn"
                    onClick={() => setShowSidebar2(!showSidebar2)}
                  >
                    <button
                      className="chevron"
                      onClick={() => {
                        setShowSidebar2(!showSidebar2);
                      }}
                    >
                      <img src={chevron} alt="" />
                    </button>
                    Create Notes
                  </button> */}
                  <div
                    className={`client-sidebar sidebar-2 ${
                      showSidebar2 ? "show" : ""
                    }`}
                  >
                    <form>
                      <div className="cs-input-outer">
                        <h2>Create Notes</h2>
                      </div>
                      <div className="cs-input-outer">
                        <div style={{ width: "100%" }}>
                          <label>Title</label>
                          <input
                            className="custum-input"
                            type="text"
                            name="text"
                          />
                        </div>
                      </div>
                      <div className="tags-outer">
                        <label> Description </label>
                        {/* <ReactQuill
                                  value={editorContent} 
                                  onChange={setEditorContent} 
                                  modules={modules}
                              /> */}
                      </div>
                      <div className="submit-outer">
                        <button type="cancel">cancel</button>
                        <button type="submit">add</button>
                      </div>
                    </form>
                  </div>
                  {/* <Button>create notes</Button> */}
                  {/* <button className="new-deal-btn" onClick={() => {setNewSidebarOpen(!NewSidebarOpen)
                            toggleclass();
                            }}>
                            <button className="chevron" onClick={() => {setNewSidebarOpen(!NewSidebarOpen)
                            }}><img src={chevron} alt='' /></button>
                    <img style={{filter:"brightness(2)"}} className="plusicn-img" src={Email} alt='' /> create notes
                  </button> */}
                  {/* <div className={`client-sidebar ${sidebarOpen ? 'open' : 'close'}`}>
                    
                    </div> */}

                  <button
                    className="new-deal-btn"
                    onClick={() => {
                      setTaskData({});
                      setShowTaskPopup(!showTaskPopup);
                      // toggleclass();
                    }}
                  >
                    {/* <button
                      className="chevron"
                      onClick={() => {
                        setShowTaskPopup(!showTaskPopup);
                      }}
                    >
                      <img src={chevron} alt="" />
                    </button> */}
                    <img
                      style={{ filter: "brightness(2)" }}
                      className="plusicn-img"
                      src={Email}
                      alt=""
                    />{" "}
                    Create Task
                  </button>
                  <button
                    className="new-deal-btn"
                    onClick={() => {
                      setSidebarOpen(!sidebarOpen);
                      toggleclass();
                    }}
                  >
                    <button
                      className="chevron"
                      onClick={() => {
                        setSidebarOpen(!sidebarOpen);
                      }}
                    >
                      <img src={chevron} alt="" />
                    </button>
                    <img
                      style={{ filter: "brightness(2)" }}
                      className="plusicn-img"
                      src={Email}
                      alt=""
                    />{" "}
                    Send Email
                  </button>
                  <div
                    className={`client-sidebar ${
                      sidebarOpen ? "open" : "close"
                    }`}
                  >
                    <div className="form-grp-custom">
                      <form onSubmit={handleSubmit}>
                        {/* File input */}

                        <div className="cs-input-outer">
                          <div>
                            <h2>Send Email</h2>
                          </div>
                          <div></div>
                        </div>
                        <div className="cs-input-outer">
                          <div className="tags-outer">
                            <label>to</label>
                            {/* <ReactTags 
                              tags={tags1}
                              handleDelete={handleDelete1}
                              handleAddition={handleAddition1}
                              placeholder="Add a new tag"
                              inputFieldPosition="inline"
                              classNames={{
                                  tags: 'tagsClass',
                                  tagInput: 'tagInputClass',
                                  tagInputField: 'tagInputFieldClass',
                                  selected: 'selectedClass',
                                  tag: 'tagClass',
                                  remove: 'removeClass',
                                  suggestions: 'suggestionsClass',
                                  activeSuggestion: 'activeSuggestionClass'
                                  
                              }}
                          /> */}
                            <Select
                              isMulti
                              name="recipient email"
                              options={recipientOptions}
                              isClearable={true}
                              isSearchable={true}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              value={selectedValues}
                              onChange={handleRecipientEmail}
                            />
                          </div>
                        </div>
                        <div className="cs-input-outer">
                          <div className="cs-left">
                            {/* Tag input */}
                            <label>From</label>
                            <input
                              className="custum-input"
                              type="text"
                              name="name"
                              defaultValue={fromEmail || ""}
                              readOnly
                            />
                          </div>
                          {/* <div className='cs-left-inner'>
                              <label>
                                  <input type="checkbox" onChange={handleCheckboxChange} />
                                  Create a Task to follow up
                              </label>

                          </div> */}
                          {showInput && (
                            <div className="date-time">
                              <input type="date" />
                              <input type="time" />
                            </div>
                          )}
                        </div>
                        <div className="cs-input-outer">
                          {/* Tag input */}
                          <label>subject</label>
                          <input
                            className="custum-input"
                            type="text"
                            name="text"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </div>
                        <div className="tags-outer">
                          <label> Description </label>
                          <ReactQuill
                            value={editorContent}
                            onChange={setEditorContent}
                            modules={modules}
                            formats={formats}
                          />
                        </div>
                        <Button
                          className="text-white bg-success rounded border-0 p-1 px-3 "
                          type="submit"
                        >
                          Send Email
                        </Button>
                        {/* <div className="submit-outer">
                        </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </Col>
            </div>
            {showTaskPopup && (
              <TaskPopup
                showTaskPopup={showTaskPopup}
                setShowTaskPopup={setShowTaskPopup}
                data={taskData}
              />
            )}

            {/* user wise data form ------------------------------(clientWiseData)----------------------------------------------- */}
            <div className="top-functions top-functions2 mx-md-5">
              <Col className="title-outer justify-content-start">
                <div className="Creto-title">
                  {editCompanyName ? (
                    <>
                      <input
                        className="p-1 rounded"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <button
                        className="ms-2 rounded p-1 px-2 border-0 bg-success text-white outline-none"
                        onClick={(e) => {
                          handleInfoChange(e, "companyName");
                        }}
                      >
                        Save
                      </button>
                    </>
                  ) : (
                    <div className="d-flex">
                      <h6 className="m-auto fw-bold">{companyName}</h6>
                      <div className="Creto-edit-icn ms-3">
                        <span>
                          <img
                            onClick={() => seteditCompanyName(!editCompanyName)}
                            src={Edit}
                            alt="edit"
                          />
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
              <div class="crt-buttons-outer priority-btns">
                <select
                  className="rounded p-1 px-3"
                  onChange={(e) => handleInfoChange(e, "priority")}
                >
                  <option value={priority}>{priority}</option>
                  {priority === "P1" ? "" : <option value={"P1"}>P1</option>}
                  {priority === "P2" ? "" : <option value={"P2"}>P2</option>}
                  {priority === "P3" ? "" : <option value={"P3"}>P3</option>}
                </select>
              </div>
              <span className="health-outer d-flex align-items-center mx-2">
                <span className="px-2 fw-bold">Health:</span>
                {clientWiseData?.health === "green" && (
                  <img src={Good} alt="good" />
                )}
                {clientWiseData?.health === "yellow" && (
                  <img src={Average} alt="average" />
                )}
                {clientWiseData?.health === "red" && (
                  <img src={Poor} alt="poor" />
                )}
              </span>
              <Col className="priority-btns-outer title-outer justify-content-evenly">
                <span>
                  <span className="fw-bold">Timezone : </span>

                  <span className="color-change me-3 text-success fw-bold">
                    {clientWiseData?.timezone || "Not Set"}
                  </span>
                </span>
                <span>
                  <span className="fw-bold">Account Status :</span>

                  {clientWiseData?.active === true ? (
                    <span
                      className="p-1 px-3 border-0 font9 mx-3 rounded"
                      style={{ background: "#77dd77", color: "white" }}
                    >
                      Active
                    </span>
                  ) : (
                    <span
                      className="p-1 px-3 border-0 font9 mx-3 rounded"
                      style={{ background: "#FF7377", color: "white" }}
                    >
                      {" "}
                      Not Active
                    </span>
                  )}
                </span>
              </Col>
              <Col md={12}>
                <div className="d-flex align-items-center">
                  <label className="fw-bold">CS Managers:</label>
                  <Select
                    isMulti
                    name="cs-manager"
                    options={managerOptions}
                    className="basic-multi-select w-50 ms-3 my-3"
                    classNamePrefix="select"
                    value={managerValues}
                    onChange={handleManagerChange}
                  />
                  {/* <select
                    className="csm-select"
                    onChange={(e) => handleInfoChange(e, "csmanager")}
                  >
                    <option value={clientWiseData?.name}>
                      {clientWiseData?.name}
                    </option>
                    <option value={clientWiseData?.name}>
                      {clientWiseData?.name}
                    </option>
                    // {clientWiseData?.name.map((item,idx)=>{
                     // return <option key={idx} value={item} >item</option>
                    })} 
                  </select> */}
                </div>
              </Col>
              <Col md={12} className="d-flex align-items-center">
                <div className="my-1 font9 me-3">
                  <input
                    className="px-1 rounded border border-mute p-1"
                    value={newTag}
                    placeholder="Input Tag here"
                    type="text"
                    onChange={(e) => setnewTag(e.target.value)}
                  />
                  <button
                    className="ms-2 rounded px-2 p-1 font9 border-0 bg-success text-white outline-none"
                    onClick={(e) => {
                      handleInfoChange(e, "tags");
                    }}
                  >
                    Create Tag
                  </button>
                </div>
                <div className="d-flex">
                  <span className="fw-bold">Tags:</span>
                  {tags?.map((tg, k) => {
                    return (
                      <div
                        key={k}
                        className="rounded-5 bg-secondary p-1 mx-1 px-2 text-white"
                        style={{ fontSize: ".7rem" }}
                      >
                        {tg.label}
                        <IoIosCloseCircle
                          onClick={() => handleDeleteTag(tg)}
                          className="fs-6 ms-1 delete-tag"
                        />
                      </div>
                    );
                  })}
                </div>
              </Col>
            </div>
            <div className="client-main-content mx-lg-5">
              <div className="col-md-8 task-list">
                <div className="top-functions top-functions2">
                  <Col md={3} className="title-outer">
                    <div className="Creto-title task-options">
                      <h4>Overdue Tasks</h4>
                    </div>
                    <div className="Creto-edit-icn">
                      <span>{clientWiseData?.overdue_tasks}</span>
                    </div>
                  </Col>
                  <Col md={3} className="title-outer">
                    <div className="Creto-title task-options">
                      <h4>Open Tickets</h4>
                    </div>
                    <div className="Creto-edit-icn">
                      <span>{clientWiseData?.open_tickets}</span>
                    </div>
                  </Col>
                  <Col md={3} className="title-outer">
                    <div className="Creto-title task-options">
                      <h4>ARR</h4>
                    </div>
                    <div className="Creto-edit-icn">
                      <span>
                        $ {arr > 999 ? (arr / 1000).toFixed(1) + "K" : arr || 0}
                      </span>
                    </div>
                  </Col>
                  <Col md={editMrr ? 6 : 3} className="title-outer">
                    <div className="Creto-title task-options">
                      <h4>MRR</h4>
                    </div>
                    {editMrr ? (
                      <>
                        <input
                          className="p-1 rounded w-50"
                          value={mrr}
                          type="number"
                          onChange={(e) => setMrr(e.target.value)}
                        />
                        <button
                          className="ms-2 rounded p-1 px-2 border-0 bg-success text-white outline-none"
                          onClick={(e) => {
                            handleInfoChange(e, "mrr");
                          }}
                        >
                          Save
                        </button>
                      </>
                    ) : (
                      <>
                        <div className="Creto-edit-icn">
                          <span>
                            $
                            {mrr > 999
                              ? (mrr / 1000).toFixed(1) + "K"
                              : mrr || 0}
                          </span>
                        </div>
                        <div className="Creto-edit-icn ms-3">
                          <span>
                            <img
                              onClick={() => seteditMrr(!editMrr)}
                              src={Edit}
                              alt="edit"
                            />
                          </span>
                        </div>
                      </>
                    )}
                  </Col>
                </div>

                {/* email template starts------------------------------------------------------------------------------------------- */}

                <div className="top-functions top-functions2 top-function4">
                  <select className="csm-select" onChange={handleChange}>
                    <option value={1}>Emails</option>
                    {/* <option value={2}>Internal Notes</option> */}
                    <option value={3}>Tasks</option>
                  </select>

                  {selectedOption1 === "1" && (
                    <select className="csm-select" onChange={handleEmailSort}>
                      <option value={1}>Newest</option>
                      <option value={2}>Oldest</option>
                    </select>
                  )}
                </div>

                <div className="top-functions top-functions2 top-function4">
                  {selectedOption1 === "1" && (
                    <div
                      className="internal-notes-card"
                      style={{
                        height: "60vh",
                        scrollbarWidth: "thin",
                        overflowY: "scroll",
                      }}
                    >
                      {loading ? (
                        <ClipLoader
                          color="black"
                          loading={true}
                          size={10}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          width="80"
                        />
                      ) : fetchUser.length > 0 ? (
                        cards.map((card, index) => (
                          <Card key={index} style={{ width: "100%" }}>
                            <Card.Body>
                              <span onClick={() => toggleExpand(index)}>
                                <Card.Subtitle className="card-title-notes card-custom2">
                                  <div>
                                    <div className="notestitle">
                                      {card.title}
                                    </div>
                                    <span> {card.subtitle}</span>
                                    <div>
                                      <span>{card.recipients}</span>
                                    </div>
                                  </div>
                                  <span className="subtitle-date">
                                    {card.date}
                                  </span>
                                </Card.Subtitle>
                              </span>

                              <Card.Text>
                                <div
                                  className={`expandable-content ${
                                    card.isExpanded ? "expanded" : ""
                                  }`}
                                >
                                  <p>
                                    <span>{card.greeting}</span>
                                  </p>
                                  {card.isExpanded && (
                                    <div
                                      style={{
                                        overflow: "scroll",
                                        height: "220px",
                                        scrollbarWidth: "none",
                                      }}
                                    >
                                      <p>{card.content}</p>
                                      <p>
                                        <span>{card.closing}</span>
                                      </p>

                                      <div className="button-group button-group2 ">
                                        <div className="group2-left">
                                          <Button
                                            variant="outline-primary"
                                            size="sm"
                                          >
                                            <img src={Reply} alt="Phone" />{" "}
                                            Reply
                                          </Button>
                                          <Button
                                            variant="outline-primary"
                                            size="sm"
                                          >
                                            <img src={ReplyAll} alt="Phone" />{" "}
                                            Reply All
                                          </Button>
                                          <Button
                                            variant="outline-primary"
                                            size="sm"
                                          >
                                            <img src={Forward} alt="Phone" />{" "}
                                            Forward
                                          </Button>
                                        </div>
                                        <Button
                                          className="custom-back"
                                          variant="outline-secondary"
                                          size="sm"
                                          onClick={() => handleBack(index)}
                                        >
                                          Back
                                        </Button>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </Card.Text>
                            </Card.Body>
                          </Card>
                        ))
                      ) : (
                        <div>No Emails found...........</div>
                      )}
                    </div>
                  )}

                  {selectedOption1 === "2" && (
                    <div className="internal-notes-card internal-notes-card2">
                      {cards2.map((card, index) => (
                        <Card
                          key={index}
                          className={
                            card.isExpanded ? "expanded-card-custom" : ""
                          }
                        >
                          <Card.Body>
                            <span onClick={() => toggleExpand2(index)}>
                              <Card.Subtitle className="card-title-notes card-custom2">
                                <div>
                                  {/* Uncomment the below line if you're using the icon */}
                                  {/* <BsFileEarmarkText style={{ marginRight: "12px" }} /> */}
                                  {card.title}
                                  <span> {card.subtitle}</span>
                                  <div>
                                    <span>{card.recipients}</span>
                                  </div>
                                </div>
                                <span
                                  style={{ margin: "0px" }}
                                  className="subtitle-date"
                                >
                                  {card.date}
                                </span>
                              </Card.Subtitle>
                            </span>
                            <Card.Text>
                              <div
                                className={`expandable-content ${
                                  card.isExpanded ? "expanded" : ""
                                }`}
                                style={{ maxHeight: "100%" }}
                              >
                                <p>
                                  <span>{card.greeting}</span>
                                </p>
                                {card.isExpanded && (
                                  <div>
                                    <p>{card.content}</p>
                                    <p>
                                      <span>{card.closing}</span>
                                    </p>

                                    <div className="button-group button-group2 ">
                                      <div className="group2-left">
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                        >
                                          <img src={Edit} alt="Phone" /> Edit
                                        </Button>
                                        <Button
                                          variant="outline-primary"
                                          size="sm"
                                        >
                                          <img src={ReplyAll} alt="Phone" /> Add
                                          Comment
                                        </Button>
                                      </div>
                                      <Button
                                        className="custom-back"
                                        variant="outline-secondary"
                                        size="sm"
                                        onClick={() => handleBack2(index)}
                                      >
                                        Back
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  )}
                  {/* start of tasks section */}
                  {selectedOption1 === "3" &&
                    (fetchClientWiseTasks < 1 ? (
                      "No Tasks Found"
                    ) : (
                      <div className="client-wise-tasks w-100">
                        {fetchClientWiseTasks?.map((task, index) => (
                          <div
                            className="w-100 p-4 bg-white rounded my-2 border border-1 cursor-pointer hover-gray"
                            onClick={() => {
                              setTaskData(task);
                              setShowTaskPopup(!showTaskPopup);
                            }}
                            key={index + "task"}
                          >
                            <p className="div-row3">
                              {/* <span className="span-no border ">
                            id:{taskData?.task_id}
                            </span>{" "} */}
                              <span className="text-line fw-semibold px-4">
                                {task?.task_name}
                              </span>
                              <span className="span-date1 text-muted">
                                {" "}
                                <img
                                  src={Vector1}
                                  width={14}
                                  height={14}
                                  className="img-fluid1 me-1 mb-1 "
                                  alt="vector"
                                />
                                Due:{task?.due_date?.split("T")[0]}
                              </span>
                            </p>
                            <p className="div-row4 my-0">
                              Priority :{" "}
                              <span className="span-high">
                                {task?.priority}
                              </span>{" "}
                              <span className="dot1">
                                <p className="div-row4">
                                  Status :{" "}
                                  <span className="span-high">
                                    {task?.status}
                                  </span>{" "}
                                </p>
                              </span>
                            </p>
                          </div>
                        ))}
                      </div>
                    ))}
                </div>
                {/* end of tasks section */}
              </div>
              <div className="col-md-4">
                <div className="right-box ">
                  <Col md={12} className="title-outer title-outer2">
                    <div className="Creto-title task-options task-options2">
                      <div className="color-change cont-link follow-sec follow-sec2">
                        <b>Renewal Date</b>{" "}
                        <span>
                          <img src={calendar} alt="Share" />{" "}
                          {isRenewDateEditable ? (
                            <>
                              <input
                                type="date"
                                name="renewal_date"
                                id=""
                                value={renewalDate}
                                onChange={(e) => setRenewalDate(e.target.value)}
                              />
                              <button
                                onClick={() => {
                                  setIsRenewDateEditable(!isRenewDateEditable);
                                  dispatch(
                                    updateRenewalDate(
                                      params.clientId,
                                      renewalDate
                                    )
                                  );
                                  dispatch(
                                    fetchClientDataClientWise(params.clientId)
                                  );
                                }}
                                className="mx-2 rounded bg-success outline-none text-white"
                              >
                                Save
                              </button>
                              <RxCrossCircled
                                onClick={() =>
                                  setIsRenewDateEditable(!isRenewDateEditable)
                                }
                                className="fs-3 cursor-pointer text-danger"
                              />
                            </>
                          ) : (
                            <>
                              <span>{renewalDate}</span>
                              <img
                                src={Edit}
                                alt=""
                                onClick={() =>
                                  setIsRenewDateEditable(!isRenewDateEditable)
                                }
                              />
                            </>
                          )}
                        </span>
                      </div>
                      {/* <div className='color-change cont-link time-sec'>
                        <span>09/01/2023</span>
                        <span>5:07 PM</span></div> */}
                    </div>
                    <div className="Creto-edit-icn">
                      {/* <span className='color-change'>edit</span> */}
                    </div>
                  </Col>
                </div>
                <div className="right-box right-box2">
                  <Col md={12} className="title-outer">
                    <div className="Creto-title task-options">
                      <h4>Upcoming Meetings</h4>
                    </div>
                    <div className="Creto-edit-icn">
                      {/* <span className='color-change'>edit</span> */}
                    </div>
                  </Col>
                  <Col md={12} className="title-outer title-outer2">
                    <div className="Creto-title task-options task-options2">
                      <div className="color-change cont-link follow-sec">
                        <b>{clientWiseData?.upcoming_meeting_subject || ""}</b>{" "}
                        <img src={Share} alt="Share" />
                      </div>
                      <div className="color-change cont-link time-sec">
                        <span>
                          <img src={calendar} alt="Share" />{" "}
                          {clientWiseData?.upcoming_meeting_time.substring(
                            0,
                            10
                          ) || "---/--/--"}
                        </span>
                        <span>
                          <img src={timer} alt="Share" />{" "}
                          {clientWiseData?.upcoming_meeting_time.substring(
                            10,
                            19
                          ) || "--:--:--"}
                        </span>
                      </div>
                    </div>
                    <div className="Creto-edit-icn">
                      {/* <span className='color-change'>edit</span> */}
                    </div>
                  </Col>
                </div>
                <div className="right-box">
                  <Col md={12} className="title-outer">
                    <div className="Creto-title task-options">
                      <h4>Contacts</h4>
                    </div>
                    <div className="Creto-edit-icn">
                      {editContactSection ? (
                        <button
                          className="rounded p-1 text-success fw-bold border-0 bg-white px-3 font9"
                          onClick={() =>
                            setEditContactSection(!editContactSection)
                          }
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          className="rounded p-1 text-white border-0 bg-success px-3 font9"
                          onClick={handleEditContactSection}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </Col>
                  <Col md={12} className="title-outer title-outer2">
                    <div className="Creto-title task-options">
                      {editContactSection ? (
                        <h4>{contactSection.pocName}</h4>
                      ) : (
                        <input
                          className="rounded my-1 inputBorderChange"
                          type="text"
                          value={contactSection.pocName}
                          onChange={(e) =>
                            setContactSection((prev) => ({
                              ...prev,
                              pocName: e.target.value,
                            }))
                          }
                        />
                      )}
                      {editContactSection ? (
                        <span>{contactSection.founder}</span>
                      ) : (
                        <input
                          className="rounded my-1 inputBorderChange"
                          type="text"
                          value={contactSection.founder}
                          onChange={(e) =>
                            setContactSection((prev) => ({
                              ...prev,
                              founder: e.target.value,
                            }))
                          }
                        />
                      )}
                    </div>
                    <div className="Creto-edit-icn">
                      {/* <span className='color-change'>edit</span> */}
                    </div>
                  </Col>
                  <Col md={12} className="title-outer title-outer2">
                    <div className="Creto-title task-options w-100">
                      <div className="color-change cont-link text-secondary fs-6 d-flex justify-content-start">
                        <img src={Email} alt="Email" />

                        {editContactSection ? (
                          <span className="mx-2">{contactSection.email}</span>
                        ) : (
                          <input
                            className="rounded my-1 font9 w-100 mx-2 inputBorderChange"
                            type="text"
                            value={contactSection.email}
                            onChange={(e) =>
                              setContactSection((prev) => ({
                                ...prev,
                                email: e.target.value,
                              }))
                            }
                          />
                        )}
                      </div>{" "}
                      <div className="color-change cont-link my-1">
                        {/* <a href="tel:+918888898888"> */}
                        <img src={Phone} alt="Phone" />{" "}
                        {editContactSection ? (
                          <span>{contactSection.contact}</span>
                        ) : (
                          <input
                            className="rounded my-1 inputBorderChange"
                            type="text"
                            value={contactSection.contact}
                            onChange={(e) =>
                              setContactSection((prev) => ({
                                ...prev,
                                contact: e.target.value,
                              }))
                            }
                          />
                        )}
                        {/* </a> */}
                      </div>
                      {editContactSection ? (
                        <Link
                          to={`/secondary-contact/${params.clientId}`}
                          className="bg-success text-white p-1 px-2 rounded font9 border mt-4 border-2"
                        >
                          Show All
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="Creto-edit-icn">
                      {/* <span className='color-change'>edit</span> */}
                    </div>
                  </Col>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default ClientNotes;
