// reducers.js
import { combineReducers } from "redux";
import { fetchClientTasksReducer, fetchUnreadNFReducer, userReducer } from "./UserReducer";
import { authReducer } from "./UserReducer";
import { tokenReducer } from "./UserReducer";
import { fetchBasicHomeDataReducer } from "./UserReducer";
import { fetchUserDataForGraphReducer } from "./UserReducer";
import { fetchClientData } from "./UserReducer";
import { fetchProductionAdoptionGraphReducer } from "./UserReducer";
import { fetchOrganizationGeneralReducer } from "./UserReducer";
import { updateOrganizationGeneralDataReducer } from "./UserReducer";
import { fetchUpsellsDownsellsDataReducer } from "./UserReducer";
import { fetchHealthGraphDataReducer } from "./UserReducer";
import { fetchUserProfilePersonalBasicDataReducer } from "./UserReducer";
import { updatePasswordReducer } from "./UserReducer";
import { sendEmailReducer } from "./UserReducer";
import { fetchOrganizationTeamDataReducer } from "./UserReducer";
import { createNewTaskReducer } from "./UserReducer";
import { addNewDealReducerReducer } from "./UserReducer";
import { fetchUserReducer } from "./UserReducer";
import { fetchAllTagsReducer } from "./UserReducer";
import { fetchEmailClientWiseReducer } from "./UserReducer";
import { fetchClientDataClientWiseReducer } from "./UserReducer";
import { fetchCalenderEventsReducer } from "./UserReducer";
import { fetchAllTaskReducer } from "./UserReducer";
import { fetchUpdateTaskReducer } from "./UserReducer";
import { fetchTaskWithIdReducer } from "./UserReducer";
import { fetchOrganizationIntgrationDataReducer } from "./UserReducer";
import { fetchVerifyConnectorsReducer } from "./UserReducer";
import { fetchConnectorDisconnectedReducer } from "./UserReducer";
import {
  fetchAllClientsReducer,
  addNewTeamMemberReducer,
  loaderReducer,
} from "./UserReducer";
import { fetchedClientEmailsReducer } from "./UserReducer";
import { uploadCsvFileReducer } from "./UserReducer";
import {fetchClientWisePocContactsReducer} from "./UserReducer";

const rootReducer = combineReducers({
  user: userReducer,
  auth: authReducer,
  tokenReducer: tokenReducer,
  fetchBasicHomeDataReducer: fetchBasicHomeDataReducer,
  fetchUserDataForGraphReducer: fetchUserDataForGraphReducer,
  fetchClientData: fetchClientData,
  fetchProductionAdoptionGraphReducer: fetchProductionAdoptionGraphReducer,
  fetchOrganizationGeneralReducer: fetchOrganizationGeneralReducer,
  updateOrganizationGeneralDataReducer: updateOrganizationGeneralDataReducer,
  fetchUpsellsDownsellsDataReducer: fetchUpsellsDownsellsDataReducer,
  fetchHealthGraphDataReducer: fetchHealthGraphDataReducer,
  fetchUserProfilePersonalBasicDataReducer:
    fetchUserProfilePersonalBasicDataReducer,
  updatePasswordReducer: updatePasswordReducer,
  sendEmailReducer: sendEmailReducer,
  fetchOrganizationTeamDataReducer: fetchOrganizationTeamDataReducer,
  createNewTaskReducer: createNewTaskReducer,
  addNewDealReducerReducer: addNewDealReducerReducer,
  fetchUserReducer: fetchUserReducer,
  fetchAllTagsReducer: fetchAllTagsReducer,
  fetchEmailClientWiseReducer: fetchEmailClientWiseReducer,
  fetchClientDataClientWiseReducer: fetchClientDataClientWiseReducer,
  fetchCalenderEventsReducer: fetchCalenderEventsReducer,
  fetchAllTaskReducer: fetchAllTaskReducer,
  fetchUpdateTaskReducer: fetchUpdateTaskReducer,
  fetchTaskWithIdReducer: fetchTaskWithIdReducer,
  fetchOrganizationIntgrationDataReducer:
    fetchOrganizationIntgrationDataReducer,
  fetchVerifyConnectorsReducer: fetchVerifyConnectorsReducer,
  fetchConnectorDisconnectedReducer: fetchConnectorDisconnectedReducer,
  fetchAllClientsReducer: fetchAllClientsReducer,
  addNewTeamMemberReducer: addNewTeamMemberReducer,
  loaderReducer: loaderReducer,
  fetchedClientEmailsReducer: fetchedClientEmailsReducer,
  uploadCsvFileReducer: uploadCsvFileReducer,
  fetchClientWisePocContactsReducer:fetchClientWisePocContactsReducer,
  fetchClientTasksReducer:fetchClientTasksReducer,
  fetchUnreadNFReducer:fetchUnreadNFReducer
});

export default rootReducer;
