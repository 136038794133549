import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './RootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';

// Define your initial state if needed
const initialState = {};

// Define your middleware
const middleware = [thunk];

// Create the store
const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
