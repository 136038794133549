import { React, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";
import { checkAuthenticated } from "../ReduxSetup/UserActions";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.tokenReducer.isAuthenticated
  );

  useEffect(() => {
    // Check authentication status when the app initializes (page loads)
    const userToken = localStorage.getItem("accessToken");

    dispatch(checkAuthenticated(userToken));
  }, [dispatch]);

  // console.log("login : ", isAuthenticated);

  return isAuthenticated === true ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/" replace />
  );
};

export default PrivateRoute;
