import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  AiOutlineUsergroupAdd,
  AiOutlineCalendar,
  AiOutlineFall,
  AiOutlineArrowRight,
} from "react-icons/ai";
import { TbArrowZigZag } from "react-icons/tb";
import { BsFileEarmarkText } from "react-icons/bs";
import { GoAlert } from "react-icons/go";
import { HiBars2 } from "react-icons/hi2";
import Badge from "react-bootstrap/Badge";
import ActiveUserGraph from "../components/ActiveUserGraph";
import ActivePieChart from "../components/ActivePieChart";
import Card from "react-bootstrap/Card";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBasicHomeData } from "../ReduxSetup/UserActions";
import { fetchUpsellsDownsellsData } from "../ReduxSetup/UserActions";
import { fetchHealthGraphData } from "../ReduxSetup/UserActions";

import { fetchActiveUserGraphData } from "../ReduxSetup/UserActions";
import ProductAdoptionGraph from "../components/ProductAdoptionGraph";

import { fetchProductAdoptionGraphData } from "../ReduxSetup/UserActions";

const Homepage = () => {
  //  handle dropdown menues
  const [selectedValue, setSelectedValue] = useState("Month");
  const [customerDropdown, setCustomerDropdown] = useState("My Customer");

  // const formatDate = (date) => {
  //   const d = new Date(date);
  //   return d.toISOString().split("T")[0]; // Extract date portion
  // };

  // const formatDateTime = (date) => {
  //   const d = new Date(date);
  //   const year = d.getFullYear();
  //   const month = String(d.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's zero-based
  //   const day = String(d.getDate()).padStart(2, "0");
  //   const hours = String(d.getHours()).padStart(2, "0");
  //   const minutes = String(d.getMinutes()).padStart(2, "0");
  //   const seconds = String(d.getSeconds()).padStart(2, "0");
  //   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  // };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleSelectedValueChange = (value) => {
    setSelectedValue(value);
    let fromDate, toDate;

    const today = new Date();
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const oneYearAgo = new Date(today);
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    toDate = formatDate(today);

    switch (value) {
      case "Day":
        fromDate = formatDate(today);
        break;
      case "Week":
        fromDate = formatDate(oneWeekAgo);
        break;
      case "Month":
        fromDate = formatDate(oneMonthAgo);
        break;
      case "Year":
        fromDate = formatDate(oneYearAgo);
        break;

      default:
        break;
    }
    // const currentDate = new Date();
    // const currentYear = currentDate.getFullYear();
    // const currentMonth = currentDate.getMonth();
    // const CurrentDay = currentDate.getDate();

    // if (value === "Month") {
    //   // For Month, set fromDate to the 1st day of the current month and toDate to the last day.
    //   fromDate = formatDate(new Date(currentYear, currentMonth, 1));
    //   toDate = formatDate(new Date(currentYear, currentMonth + 1, 0));
    //   // console.log("month1", fromDate); // console the month
    //   // console.log("month2 ", toDate); // console the month
    // } else if (value === "Week") {
    //   // Calculate the starting and ending dates of the current week.
    //   const currentDay = currentDate.getDate();
    //   const firstDayOfWeek = new Date(
    //     currentYear,
    //     currentMonth,
    //     currentDay - currentDate.getDay()
    //   );
    //   const lastDayOfWeek = new Date(
    //     currentYear,
    //     currentMonth,
    //     currentDay + (6 - currentDate.getDay())
    //   );
    //   fromDate = formatDate(firstDayOfWeek);
    //   toDate = formatDate(lastDayOfWeek);
    //   // console.log("Week", fromDate, toDate); // console the week part
    // } else if (value === "Year") {
    //   // For Year, set fromDate to the 1st day of the current year and toDate to the last day.
    //   fromDate = formatDate(new Date(currentYear, 0, 1));
    //   toDate = formatDate(new Date(currentYear, 11, 31));
    //   // console.log("Year", fromDate, toDate); // console the year part
    // } else if (value === "Day") {
    //   // For Day, set fromDate and toDate to the current date.
    //   fromDate = formatDateTime(
    //     new Date(currentYear, currentMonth, currentDate.getDate(), 0, 0, 0)
    //   );
    //   toDate = formatDateTime(
    //     new Date(currentYear, currentMonth, currentDate.getDate(), 23, 59, 59)
    //   );
    //   // console.log("day", fromDate, toDate);
    // }
    dispatch(fetchActiveUserGraphData(fromDate, toDate));
    dispatch(fetchProductAdoptionGraphData(fromDate, toDate));
    dispatch(fetchHealthGraphData(fromDate, toDate));
  };

  useEffect(() => {
    // Fetch data with the default selected value ("Month") when the component mounts.
    handleSelectedValueChange("Month");
  }, []);

  const handleCustomerDropdownValueChange = (customer) => {
    setCustomerDropdown(customer);
  };

  // selector for basic homeData
  const dispatch = useDispatch();
  const basicHomeData = useSelector((state) => {
    if (
      state.fetchBasicHomeDataReducer &&
      state.fetchBasicHomeDataReducer.data &&
      state.fetchBasicHomeDataReducer.data.message &&
      state.fetchBasicHomeDataReducer.data.message.data
    ) {
      return state.fetchBasicHomeDataReducer.data.message.data[0];
    }
    return null; // Return a default value or handle the case where the property doesn't exist.
  });
  // console.log("home",basicHomeData);

  // selector for Upsells and downsells
  const upsellsAndDownsellsData = useSelector((state) => {
    if (
      state.fetchUpsellsDownsellsDataReducer &&
      state.fetchUpsellsDownsellsDataReducer.userData &&
      state.fetchUpsellsDownsellsDataReducer.userData.data
    ) {
      return state.fetchUpsellsDownsellsDataReducer.userData.data[0];
    }
    return null;
  });

  //  console.log("upsellsAndDownsellsData",upsellsAndDownsellsData);

  // selector for fetch GealthData
  const fetchHealthData = useSelector((state) => {
    if (
      state.fetchHealthGraphDataReducer &&
      state.fetchHealthGraphDataReducer.userData &&
      state.fetchHealthGraphDataReducer.userData.data
    ) {
      return state.fetchHealthGraphDataReducer.userData.data[0];
    } else {
      return null;
    }
  });

  useEffect(() => {
    dispatch(fetchBasicHomeData());
    dispatch(fetchUpsellsDownsellsData());
  }, [dispatch]);

  // basic home data validation condtion if data is not then shows 0.
  // console.log(basicHomeData);
  const totalMRR = basicHomeData ? basicHomeData.total_mrr : null;
  const totalARR = basicHomeData ? basicHomeData.total_arr : null;
  const mrr_percent_change = basicHomeData
    ? basicHomeData.mrr_percent_change
    : null;
  const arr_percent_change = basicHomeData
    ? basicHomeData.arr_percent_change
    : null;
  // console.log(totalMRR)
  // console.log(totalARR)
  // console.log(mrr_percent_change)
  // console.log(arr_percent_change)

  // Upsells and downsells data validation condtion if data is not then shows 0.
  const downsells = upsellsAndDownsellsData
    ? upsellsAndDownsellsData.downsells
    : null;
  const downsellsPercentage = upsellsAndDownsellsData
    ? upsellsAndDownsellsData.downsellsPercentage
    : null;
  const upsells = upsellsAndDownsellsData
    ? upsellsAndDownsellsData.upsells
    : null;
  const upsellsPercentage = upsellsAndDownsellsData
    ? upsellsAndDownsellsData.upsellsPercentage
    : null;

  // console.log(downsells)
  // console.log(downsellsPercentage)
  // console.log(upsells)
  // console.log(upsellsPercentage)

  // fetch healthData valdation condition if data is not then shows 0.
  const average_health_percentage = fetchHealthData
    ? parseInt(fetchHealthData.average_health_percentage)
    : null;
  const good_health_percentage = fetchHealthData
    ? parseInt(fetchHealthData.good_health_percentage)
    : null;
  const poor_health_percentage = fetchHealthData
    ? parseInt(fetchHealthData.poor_health_percentage)
    : null;
  const total_clients = fetchHealthData ? fetchHealthData.total_clients : null;

  // console.log(average_health_percentage)
  // console.log(good_health_percentage)
  // console.log(poor_health_percentage)
  // console.log(total_clients)

  return (
    <div className="handle-slide">
      <div className="container custom-width">
        <div className="container">
          <div className="toogle-dropdown">
            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="toogle-dropdown-home"
              >
                {customerDropdown}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    handleCustomerDropdownValueChange("All Customers")
                  }
                >
                  {" "}
                  All Customers
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleCustomerDropdownValueChange("My Customers")
                  }
                >
                  {" "}
                  My Customers
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="d-inline mx-2">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="toogle-dropdown-home"
              >
                {selectedValue}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => handleSelectedValueChange("Day")}>
                  Day
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleSelectedValueChange("Week")}
                >
                  Week
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleSelectedValueChange("Month")}
                >
                  Month
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleSelectedValueChange("Year")}
                >
                  Year
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        <Container>
          <Row className="custom-row">
            <Col xs={12} md={6} className="mb-3 custom-col custom-tab-size">
              <div
                className="custom-div"
                style={{ width: "100%", height: "421px" }}
              >
                <div className="step-1">
                  <span>
                    <AiOutlineUsergroupAdd className="icons" />
                  </span>
                  {!basicHomeData ? (
                    <h3>0</h3>
                  ) : (
                    <h3>{basicHomeData.total_clients}</h3>
                  )}
                  <p>No. of Customers</p>
                </div>
                <div className="step-1">
                  <span>
                    <AiOutlineUsergroupAdd className="icons" />
                  </span>
                  {!basicHomeData ? (
                    <h3>0</h3>
                  ) : (
                    <h3>{basicHomeData.clients_not_renewed}</h3>
                  )}
                  <p>Up for Renewal</p>
                </div>
                <div className="step-1">
                  <span>
                    <AiOutlineCalendar className="icons" />
                  </span>
                  {totalMRR === null ? (
                    <h3>0</h3>
                  ) : (
                    <h3>
                      {basicHomeData?.total_mrr > 1000
                        ? (basicHomeData?.total_mrr / 1000).toFixed(1) + "K"
                        : basicHomeData?.total_mrr}
                    </h3>
                  )}
                  <p>MRR</p>
                  <div className="badge">
                    <Badge bg={!mrr_percent_change < 0 ? "success" : "danger"}>
                      {!mrr_percent_change < 0 ? (
                        <TbArrowZigZag
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      ) : (
                        <AiOutlineFall
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      )}
                      {!mrr_percent_change ? (
                        0
                      ) : (
                        <p>{basicHomeData?.mrr_percent_change}%</p>
                      )}
                    </Badge>
                  </div>
                </div>
                <div className="step-1">
                  <span>
                    <AiOutlineCalendar className="icons" />
                  </span>
                  {totalARR === null ? (
                    <h3>0</h3>
                  ) : (
                    <h3>
                      {basicHomeData?.total_arr > 1000
                        ? (basicHomeData?.total_arr / 1000).toFixed(1) + "K"
                        : basicHomeData?.total_arr}
                    </h3>
                  )}

                  <p>ARR</p>
                  <div className="badge">
                    <Badge bg={arr_percent_change > 0 ? "success" : "danger"}>
                      {arr_percent_change > 0 ? (
                        <TbArrowZigZag
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      ) : (
                        <AiOutlineFall
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      )}
                      {!arr_percent_change ? (
                        0
                      ) : (
                        <p>{basicHomeData?.arr_percent_change}%</p>
                      )}
                    </Badge>
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} md={6} className="mb-3 custom-col custom-tab-size">
              <div
                className="custom-div"
                style={{ width: "100%", height: "421px" }}
              >
                <p>Active User's</p>
                <ActiveUserGraph />
                <h6>Days in the month</h6>
              </div>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row>
            <Col xs={12} md={6} className="mb-3 custom-tab-size">
              <div
                className="custom-div"
                style={{ width: "100%", height: "387px" }}
              >
                <p>Product Adoption</p>
                {/*todo  */}
                <ProductAdoptionGraph />
                <h6>Days in the month</h6>
              </div>
            </Col>
            <Col md="3" className="custom-tab-size">
              <div
                className="custom-div"
                // style={{ width: "320px", height: "387px" }}
              >
                <p>Health Status</p>
                {/* health status graph starts------------------------------------------------------------------------------------ */}
                <ActivePieChart
                  good_health_percentage={good_health_percentage}
                  average_health_percentage={average_health_percentage}
                  poor_health_percentage={poor_health_percentage}
                  total_clients={total_clients}
                />
                <div className="content">
                  <div className="dot-outer">
                    <div className="dot"></div>
                    <span className="text">Good</span>
                  </div>
                  <span className="percentage">{good_health_percentage}</span>
                </div>
                <div className="content">
                  <div className="dot-outer">
                    <div className="dot-2"></div>
                    <span className="text">Average</span>
                  </div>
                  <span className="percentage">
                    {average_health_percentage}
                  </span>
                </div>
                <div className="content">
                  <div className="dot-outer">
                    <div className="dot-3"></div>
                    <span className="text">Poor</span>
                  </div>
                  <span className="percentage">{poor_health_percentage}</span>
                </div>
              </div>
            </Col>
            {/* upsells and downsells starts --------------------------------------------------------------------------------------------------- */}
            <Col xs lg="3" className="custom-tab-size">
              <div className="custom-div">
                <div className="step-2">
                  <span>
                    <TbArrowZigZag className="icons" />
                  </span>
                  <h3>{upsells}</h3>
                  <p>Upsells</p>
                  <div className="badge">
                    <Badge bg={upsellsPercentage > 0 ? "success" : "danger"}>
                      {upsellsPercentage > 0 ? (
                        <TbArrowZigZag
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      ) : (
                        <AiOutlineFall
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      )}
                      {upsellsPercentage}%
                    </Badge>
                  </div>
                </div>

                <div className="step-2">
                  <span>
                    <AiOutlineFall className="icons" />
                  </span>
                  <h3>{downsells}</h3>
                  <p>Downgrades</p>
                  <div className="badge">
                    <Badge bg={downsellsPercentage < 0 ? "success" : "danger"}>
                      {downsellsPercentage > 0 ? (
                        <TbArrowZigZag
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      ) : (
                        <AiOutlineFall
                          style={{ fontSize: "16px", marginRight: "2px" }}
                        />
                      )}
                      {downsellsPercentage}%
                    </Badge>
                  </div>
                </div>
              </div>
            </Col>
            {/* upsells and downsells ends --------------------------------------------------------------------------------------------------- */}
          </Row>
        </Container>

        <Container>
          <Row className="custom-row">
            <Col xs={12} md={6} className="mb-3 custom-col custom-tab-size">
              {/* <div
                className="custom-div intenal-notes"
                style={{ width: "100%", height: "421px" }}
              >
                <div className="d-flex notes">
                  <h4>Internal Notes</h4>
                  <Link to="/ViewAllNotes">
                    <p>
                      View All <AiOutlineArrowRight />
                    </p>
                  </Link>
                </div>

                <div className="internal-notes-card">
                  <Card style={{ width: "100%", marginTop: "24px" }}>
                    <Card.Body>
                      <Card.Subtitle className="card-title-notes">
                        <BsFileEarmarkText style={{ marginRight: "12px" }} />
                        Note <span>by Shivanshi Bhatia</span>
                        <span className="subtitle-date">
                          09/10/2023 at 2:07 EST
                        </span>
                      </Card.Subtitle>
                      <Card.Text
                        className="card-text"
                        style={{ paddingTop: "16px", paddingLeft: "24px" }}
                      >
                        Follow up with the sales guy about the numbers.{" "}
                        <span style={{ fontWeight: "700", color: "green" }}>
                          @Apoorv M.
                        </span>{" "}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </Card.Text>
                    </Card.Body>
                  </Card>

                  <Card style={{ width: "100%", marginTop: "24px" }}>
                    <Card.Body>
                      <Card.Subtitle className="card-title-notes">
                        <BsFileEarmarkText style={{ marginRight: "12px" }} />
                        Note <span>by Shivanshi Bhatia</span>
                        <span className="subtitle-date">
                          09/10/2023 at 2:07 EST
                        </span>
                      </Card.Subtitle>
                      <Card.Text
                        className="card-text"
                        style={{ paddingTop: "16px", paddingLeft: "24px" }}
                      >
                        Follow up with the sales guy about the numbers.{" "}
                        <span style={{ fontWeight: "700", color: "green" }}>
                          @Apoorv M.
                        </span>{" "}
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div> */}
            </Col>
            <Col xs={12} md={6} className="mb-3 custom-col custom-tab-size">
              <div
                className="custom-div-last custom-div "
                style={{ width: "100%", height: "201px" }}
              >
                <div className="step-1">
                  <span>
                    <HiBars2 className="icons" />
                  </span>
                  <h3>{basicHomeData?.pendingtasks}</h3>
                  <p>Pending Task</p>
                </div>
                <div className="step-1">
                  <span>
                    <GoAlert className="icons" />
                  </span>
                  <h3>
                    {basicHomeData?.poorHealthPercentage > 1000
                      ? (basicHomeData?.poorHealthPercentage / 1000).toFixed(
                          1
                        ) + "K"
                      : basicHomeData?.poorHealthPercentage}
                  </h3>
                  <p>High Risk Clients</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Homepage;
