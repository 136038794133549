// https://recharts.org/en-US/guide/getting-started

import { React } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useSelector } from "react-redux";

const ProductAdoptionGraph = () => {
  const activeProductAdoptionGraphData = useSelector(
    (state) => state.fetchProductionAdoptionGraphReducer.activeUserData.data
  );

  const data =
  activeProductAdoptionGraphData?.map((data) => ({
      name: data.date_series, //days in month
      AdoptionCount: parseInt(data.entry_count), //in box below
    })) || [];

  return (
    <>
      <ResponsiveContainer width="100%" height="80%">
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip  />
          <Legend />
          <Line
            type="monotone"
            dataKey="AdoptionCount"
            stroke="#0CAF60"
            activeDot={{ r: 8 }}
          />
          {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
};

export default ProductAdoptionGraph;
