// Sidebar.js
import { React, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { RiTodoLine } from "react-icons/ri";
import { SlCalender } from "react-icons/sl";
import { BsSearch } from "react-icons/bs";
import Logo from "../images/sidebar-logo2.png";
import Popups from "../components/Popups.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Search.css";
import { fetchOrganizationGeneralData } from "../ReduxSetup/UserActions.js";
import { useDispatch, useSelector } from "react-redux";

const SidebarItem = ({ icon, name, to }) => {
  const location = useLocation();

  return (
    <>
      <NavLink
        to={to}
        className={`sidebar-item ${
          location.pathname === to ? "sidebar-items" : ""
        }`}
      >
        {icon}
        <span className="sidebar-item-name">{name}</span>
      </NavLink>
    </>
  );
};

const Sidebar = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  // const [companyLogo, setCompanyLogo] = useState("");
const dispatch = useDispatch();
  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  const organizationGeneralData = useSelector(
    (state) => state.fetchOrganizationGeneralReducer.dataUser?.data[0]
  );



  useEffect(() => {
    dispatch(fetchOrganizationGeneralData());
  }, [dispatch]);

  return (


    <div className="sidebar">
      <img src={organizationGeneralData?.company_logo} alt="" className="sidebar-logo rounded-circle" />
      <SidebarItem icon={<AiOutlineHome />} name="Home" to="/homepage" />
      <SidebarItem
        icon={<BiUserCircle />}
        name="Clients Dashboard"
        to="/client-dashboard"
      />
      <SidebarItem icon={<RiTodoLine />} name="Tasks" to="/taskpage" />
      <SidebarItem icon={<SlCalender />} name="Calendar" to="/calender" />

      <div onClick={openPopup} ><SidebarItem  icon={<BsSearch />} name="Search" /></div>
     
      {isPopupOpen && (
      <div className="blur-background">
        <Popups onClose={closePopup} />
      </div>
    )
  }

    </div>
    
  );
};

export default Sidebar;
