import React, { useState, useEffect } from "react";
import Fullcalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { useSelector, useDispatch } from "react-redux";
import { fetchCalendersEvents } from "../ReduxSetup/UserActions";
const Calendars = () => {
  const dispatch = useDispatch();

  const fetchCalenderEvents = useSelector((state) => {
    if (
      state.fetchCalenderEventsReducer &&
      state.fetchCalenderEventsReducer.userData &&
      state.fetchCalenderEventsReducer.userData.data
    ) {
      return state.fetchCalenderEventsReducer.userData.data;
    } else {
      return [];
    }
  });
  // console.log("fetchCalenderEventsPPP",fetchCalenderEvents)

  // console.log(fetchCalenderEvents[0]?.start.dateTime)
  // console.log(fetchCalenderEvents?.summary)
  // console.log(object)
  // console.log(object)

  // const dateTimeString = fetchCalenderEvents[0]?.start.dateTime;
  // const timePart = dateTimeString.split("T")[1];
  // console.log(timePart)

  useEffect(() => {
    const today = new Date();
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);
    const todayDateOnly = today.toISOString().split("T")[0];
    const oneWeekAgoDateOnly = oneWeekAgo.toISOString().split("T")[0];

    // Calculate the previous Sunday
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - today.getDay());
  
    // Calculate the next Sunday
    const nextSunday = new Date(today);
    nextSunday.setDate(today.getDate() + (7 - today.getDay()));
  
    const getLastSunday = lastSunday.toISOString().split("T")[0];
    const getNextSunday = nextSunday.toISOString().split("T")[0];
      
    dispatch(fetchCalendersEvents(getLastSunday,getNextSunday));
  }, [dispatch]);

  const fetchData = fetchCalenderEvents?.map((data) => {
    const dateTimeString = data?.start_date_time;
    const timePart = dateTimeString.split("T")[1];
    // console.log(timePart)

    return {
      title: "Event 1",
      start: data?.start_date_time,
      description: data?.summary, //summary
      author: timePart, // start
      url: data?.conference_uri,
    };
  });

  const eventsWithAdditionalData = [...fetchData];

  const handleDateChange = (arg) => {
    const start = arg.startStr.split("T")[0]; // Start of the visible range
    const end = arg.endStr.split("T")[0]; // End of the visible range
    // Dispatch function with date endpoints to fetch data between start and end
    // console.log(start,end)
    dispatch(fetchCalendersEvents(start, end));
  };

  // {
  //   title: "Event 2",
  //   start: "2023-10-08T14:00:00",
  //   end: "2023-10-05T16:00:00",
  //   description: "Event 2 details.",
  //   author: "Jane Smith",
  //   creator: "contact@stagdrone.com",
  //   organizer: "contact@stagdrone.com",
  // },
  // {
  //   title: "Event 3",
  //   start: "2023-10-12T09:30:00",
  //   end: "2023-10-12T11:00:00",
  //   description: "Description.",
  //   author: "Alice Johnson",
  //   creator: "User E",
  //   organizer: "User F",
  // },

  const eventContent = (arg) => {
    const { title, description, author } = arg.event._def.extendedProps;
    // const { title, description, author, creator, organizer } = arg.event._def.extendedProps;

    return (
      <>
        <div>{title}</div>
        <div>{description}</div>
        <div>{author}</div>
      </>
    );
  };
  const eventClick = (info) => {
    info.jsEvent.preventDefault(); // don't let the browser navigate

    if (info.event.url !== 'null') {
      window.open(info.event.url, "_blank");
    }
  };

  return (
    <div style={{ marginTop: "80px" }}>
      <Fullcalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView={"timeGridWeek"}
        headerToolbar={{
          start: "today prev,next", // will normally be on the left. if RTL, will be on the right
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay", // will normally be on the right. if RTL, will be on the left
        }}
        height={"90vh"}
        events={eventsWithAdditionalData}
        eventContent={eventContent}
        eventClick={eventClick}
        datesSet={handleDateChange}
      />
    </div>
  );
};

export default Calendars;
