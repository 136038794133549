import React from 'react'
import ClientsDashboard from '../components/ClientsDashboard'

const ClientsPage = () => {
  return (
    <div className='adjust-custm'>
        <ClientsDashboard/>
    </div>
  )
}

export default ClientsPage