import { React, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Calendarpage.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import image21 from "../images/claincoaster-integration-img-2.svg";
import Button1 from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Popup from "../components/CalenderPop.js";

import { AiOutlineRight } from "react-icons/ai";
import TagsInput from "../components/TagsInput";
import Calendars from "../components/Calendar";
import { useSelector } from "react-redux";
import { MdEventAvailable } from "react-icons/md";

export default function CalenderPage({ name, ...props }) {
  const [host, setHost] = useState("");
  const [title, setTitles] = useState("");
  const [meetingType, setMeetingType] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [duration, setDuration] = useState("");
  const [location, setLocation] = useState("");
  const [attendee, setAttemdee] = useState([]);
  const [attendeeDiscriptive, setAttemdeeDiscriptive] = useState("");
  const [notes, setNotes] = useState("");

  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);

  const handleCloseLeft = () => setShow(false);
  const handleShowLeft = () => setShow(true);
  const handleCloseRight = () => setShows(false);
  const handleShowRight = () => setShows(true);

  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
    setTimeout(() => {
      setPopupOpen(false);
    }, 2000);
  };

  const fetchCalenderEvents = useSelector((state) => {
    if (
      state.fetchCalenderEventsReducer &&
      state.fetchCalenderEventsReducer.userData &&
      state.fetchCalenderEventsReducer.userData.data
    ) {
      return state.fetchCalenderEventsReducer.userData.data;
    } else {
      return [];
    }
  });

  const [searchCalender, setSearchCalender] = useState([]);

  const handleSearch = (e) => {
    if (e.target.value === "") {
      setSearchCalender([]);
    } else {
      const temp = fetchCalenderEvents.filter((item) =>
        item.summary.toLowerCase().includes(e.target.value)
      );
      if(temp.length>0) setSearchCalender(temp);
      else setSearchCalender([{summary:"No Event Found"}])
    }
  };

  const handleCreateMeeting = (e) => {
    e.preventDefault();
    console.log(host);
    console.log(title);
    console.log(meetingType);
    console.log(date);
    console.log(time);
    console.log(duration);
    console.log(location);
    console.log(attendee);
    console.log(attendeeDiscriptive);
    console.log(notes);
  };

  return (
    <Container className="task-container-1">
      <div className="task-container-2 pb-1">
        <div className="div-header custom-width mx-auto">
          <p className="mt-4 ms-5">Calendar</p>{" "}
          <Button1
            type="button"
            onClick={handleShowLeft}
            className="calendar-btn-header mt-3 "
            variant="secondary"
          >
            Log Meeting
          </Button1>{" "}
          <Button1
            type="button"
            onClick={handleShowRight}
            className="calendar-btn-header-1 mt-3 "
            variant="success"
          >
            Create Meeting
          </Button1>
        </div>

        <div className="div-header custom-width">
          {/* <Form.Select
            className="task-dropdown-div-1 mt-3"
            aria-label="Default select example"
          >
            <option>My Calender</option>
            <option value="1">one</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select> */}
          {/* <Form.Select className='task-dropdown-1  mt-3 ms-2' aria-label="Default select example">
          <option>Month</option>
          <option value="1">one</option>
          <option value="2">Two</option>
          <option value="3">Three</option>
          </Form.Select> */}
          <div className="">
            <input
              type="search"
              className="task-dropdown-div-10 mt-3 ms-2 "
              placeholder="Search for the meeting......"
              onChange={handleSearch}
            ></input>
            <div className="search-calendar z-1 shadow bg-white">
              {searchCalender &&
                searchCalender?.map((item) => {
                  return (
                    <a
                      key={item}
                      href={item?.conference_uri}
                      className="d-block p-2 text-secondary bg-muted border-bottom-2"
                    >
                      <MdEventAvailable />
                      <span className="px-2">{item?.summary}</span>
                    </a>
                  );
                })}
            </div>
            <a className="task-dropdown-div-11 mt-3 ms-2 ">
              <img
                src={image21}
                width={20}
                height={20}
                className="img-fluid1"
                alt="vector"
              />
            </a>
          </div>
        </div>

        {/* calender component strats ------------------------------------------------------------------------------------------------ */}
        <Calendars />
        {/* calender component ends ------------------------------------------------------------------------------------------------ */}
      </div>

      <Container className="fluid">
        <Offcanvas
          style={{ width: "800px" }}
          show={show}
          onHide={handleCloseLeft}
          placement="end"
          {...props}
        >
          <Offcanvas.Header closeButton className="close-btn-offcanvas">
            <Offcanvas.Title>Log Meeting</Offcanvas.Title>
            <AiOutlineRight className="svg-offcanvas" />
            {/* <Offcanvas.Button closeButton className='close-button-offcanvas'></Offcanvas.Button> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Row>
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={7}
                  sm={12}
                  className="offcanvas-col-1 "
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="12" className="lable-text">
                        Title<span className="text-span">*</span>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          className="p-3"
                          type="text"
                          placeholder="name"
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                  className="offcanvas-col-1 mt-3"
                >
                  <div>
                    <span className="offcanvas-span mb-1">Outcome</span>
                    <Form.Select
                      className="task-dropdown-20 mt-2 p-3 "
                      aria-label="Default select example"
                    >
                      {/* <option>Shivanshi bhatiya</option> */}
                      <option value="1">one</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 "
                >
                  <div>
                    <Form>
                      <Form.Group
                        as={Row}
                        className=" offcanvas-lable mb-1"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="4" className="lable-text">
                          Date<span className="text-span">*</span>
                        </Form.Label>
                        <br></br>
                        <Col sm="12">
                          <Form.Control
                            className="p-3"
                            type="Date"
                            placeholder="09/01/2023"
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable mb-1"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        Time<span className="text-span">*</span>
                      </Form.Label>
                      <br></br>
                      <Col sm="12">
                        <Form.Control
                          className="p-3"
                          type="Time"
                          placeholder="12:00"
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-2"
                >
                  <div>
                    <span className="offcanvas-span ">Duration</span>
                    <Form.Select
                      className="task-dropdown-20  p-3 mt-3 "
                      aria-label="Default select example"
                    >
                      <option>30 Minute Before</option>
                      <option value="1">25 Minute Before</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xxl={12} xl={12} lg={8} md={12} sm={12} className="mt-2">
                  <Form.Group
                    as={Row}
                    className="mb-1"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="12" className="lable-text">
                      Add Note<span className="text-span">*</span>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        className="p-3"
                        as="textarea"
                        rows={2}
                        placeholder="note"
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={12}
                  xl={10}
                  lg={8}
                  md={6}
                  sm={4}
                  className="offcanvas-col "
                >
                  <p className="text-heading-offcanvas">Attendees</p>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  className="offcanvas-col-input"
                >
                  <Form.Group
                    className="calender-input-tage"
                    controlId="exampleForm.ControlInput1"
                  >
                    {/* <Form.Control type="text" className='claincoaster-general-input py-3' placeholder="Company name" /> */}
                    <TagsInput />
                  </Form.Group>
                </Col>
              </Row>

              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className="col-offcanvas mt-3 mb-2"
              >
                <div>
                  <Button
                    type="button"
                    onClick={handleCloseLeft}
                    className="btn-offcanvas-1 py-2 px-5"
                  >
                    Cancel
                  </Button>
                  <Button type="button" className="btn-offcanvas-2 py-2 px-5">
                    Add
                  </Button>
                </div>
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>

      {isPopupOpen && (
        <div className="overlay">
          <Popup onClose={togglePopup} />
        </div>
      )}

      <Row>
        <Offcanvas
          style={{ width: "800px" }}
          show={shows}
          onHide={handleCloseRight}
          placement="end"
          {...props}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Create New Meeting</Offcanvas.Title>
            <AiOutlineRight className="svg-offcanvas" />
          </Offcanvas.Header>
          <Offcanvas.Body>
            {/* create meeting form starts ------------------------------------------------------------------------------------------------------ */}
            <Form className="meeting-form" onSubmit={handleCreateMeeting}>
              <Row>
                <Col
                  xxl={11}
                  xl={11}
                  lg={11}
                  md={11}
                  sm={11}
                  className="offcanvas-col-1"
                >
                  <div>
                    <Form.Label column sm="4" className="lable-text">
                      <span className="offcanvas-span mt-3 ">Host</span>
                      <span className="text-span-1">*</span>
                    </Form.Label>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable "
                      controlId="formPlaintextEmail"
                    >
                      <Form.Control
                        className="p-3"
                        type="text"
                        placeholder="host name"
                        value={host}
                        onChange={(e) => setHost(e.target.value)}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={7}
                  sm={12}
                  className="offcanvas-col-0"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className="mb-3"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="12" className="lable-text">
                        Title<span className="text-span-1">*</span>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          className="p-3"
                          type="text"
                          placeholder="title"
                          value={title}
                          onChange={(e) => setTitles(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col
                  xxl={5}
                  xl={5}
                  lg={5}
                  md={5}
                  sm={12}
                  className="offcanvas-col-1 mt-3"
                >
                  <div>
                    <span className="offcanvas-span mb-1">Meeting Type</span>
                    <Form.Select
                      className="task-dropdown-20 mt-2 p-3 "
                      aria-label="Default select example"
                      value={meetingType}
                      onChange={(e) => setMeetingType(e.target.value)}
                    >
                      <option value="Scheduled">Scheduled</option>
                      <option value="Instant">Instant</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={4}
                  xl={7}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1"
                >
                  <div>
                    <Form>
                      <Form.Group
                        as={Row}
                        className=" offcanvas-lable mb-1"
                        controlId="formPlaintextEmail"
                      >
                        <Form.Label column sm="4" className="lable-text">
                          Date<span className="text-span-1">*</span>
                        </Form.Label>
                        <br></br>
                        <Col sm="12">
                          <Form.Control
                            className="p-3"
                            type="Date"
                            placeholder="09/01/2023"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Form>
                  </div>
                </Col>
                <Col
                  xxl={4}
                  xl={7}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-2"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable mb-1"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        Time<span className="text-span-1">*</span>
                      </Form.Label>
                      <br></br>
                      <Col sm="12">
                        <Form.Control
                          className="p-3"
                          type="Time"
                          placeholder="12:00"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
                <Col
                  xxl={4}
                  xl={5}
                  lg={7}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1 mt-3"
                >
                  <div>
                    <span className="offcanvas-span">Duration</span>
                    <Form.Select
                      className="task-dropdown-20  p-3 mt-1"
                      aria-label="Default select example"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    >
                      <option value="30">30 Minute Before</option>
                      <option value="30">30 Minute</option>
                      <option value="1">1 hour</option>
                      <option value="2">2 hour</option>
                      <option value="3">3 hour</option>
                      <option value="4">4 hour</option>
                      <option value="5">5 hour</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={4}
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  className="offcanvas-col"
                >
                  <div>
                    <span className="offcanvas-span  ms-2">Mode *</span>
                    <Form.Select
                      className="task-dropdown-3 mt-1 "
                      aria-label="Default select example"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                    >
                      <option>Select Mode</option>
                      <option value="gmeet">G-Meet</option>
                      <option value="personal">Personal</option>
                    </Form.Select>
                  </div>
                </Col>
                <Col
                  xxl={7}
                  xl={7}
                  lg={7}
                  md={7}
                  sm={8}
                  className="offcanvas-col"
                >
                  <div>
                    <span className="offcanvas-span mb-5 ms-2">Attendee *</span>
                    <Form.Select
                      className="task-dropdown-3 mt-1 "
                      aria-label="Default select example"
                      value={attendee}
                      onChange={(e) => setAttemdee(e.target.value)}
                    >
                      <option>d</option>
                      <option value="1">a</option>
                      <option value="2">b</option>
                      <option value="3">c</option>
                    </Form.Select>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xxl={12}
                  xl={12}
                  lg={8}
                  md={12}
                  sm={12}
                  className="meetting-note"
                >
                  <Form.Group
                    as={Row}
                    className="mb-5"
                    controlId="formPlaintextEmail"
                  >
                    <Form.Label column sm="12" className="lable-text">
                      Attendee Description<span className="text-span-1">*</span>
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        className="p-3"
                        as="textarea"
                        rows={2}
                        placeholder="Attendee Description"
                        value={attendeeDiscriptive}
                        onChange={(e) => setAttemdeeDiscriptive(e.target.value)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>

              <Col
                xxl={12}
                xl={12}
                lg={8}
                md={12}
                sm={12}
                className="meetting-note"
                style={{ marginTop: "-44px" }}
              >
                <Form.Group
                  as={Row}
                  className="mb-5"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="12" className="lable-text">
                    Add Note<span className="text-span-1">*</span>
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      className="p-3"
                      as="textarea"
                      rows={2}
                      placeholder="note"
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col
                xxl={12}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                className="col-offcanvas "
              >
                <div>
                  <Button
                    type="button"
                    onClick={handleCloseRight}
                    className="btn-offcanvas-4 py-2 px-5"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="btn-offcanvas-2 py-2 px-5">
                    Create
                  </Button>
                </div>
              </Col>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </Row>
    </Container>
  );
}

// function TaskExample() {
//   return (
//     <>
//       {['start', 'end', 'top', 'bottom'].map((placement, idx) => (
//         <Task key={idx} placement={placement} name={placement} />
//       ))}
//     </>
//   );
// }

// ReactDOM.render(<TaskExample />, document.getElementById('root')); // Render the Example component into a DOM element with the id 'root'
