// userReducer.js
import {
  FETCH_CLIENT_DASHBOARD_DATA_FAILURE,
  FETCH_CLIENT_DASHBOARD_DATA,
  GET_ACTIVE_USER_DATA,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  SET_AUTHENTICATED,
  LOGOUT_USER,
  FETCH_BASIC_HOME_DATA_REQUEST,
  FETCH_BASIC_HOME_DATA_SUCCESS,
  FETCH_BASIC_HOME_DATA_FAILURE,
  GET_PRODCTION_ADOPTION_GRAPH_DATA,
  FETCH_ORGANIZATION_DATA,
  UPDATE_ORGANOZATION_GENERAL_DATA,
  FETCH_UPSELL_DOWNSELL_DATA,
  FETCH_HEALTH_GRAPH_DATA,
  FETCH_USER_PROFILE_PERSONAL_BASIC_DATA,
  UPDATE_PASSWORD,
  SEND_EMAIL,
  FETCH_ORGANIZATION_TEAM_DATA,
  CREATE_NEW_TASK,
  SAVE_NEW_DEAL,
  FETCH_ALL_USERS,
  FETCH_ALL_TAGS,
  FETCH_EMAIL_CLIENT_WISE,
  FETCH_CLIENT_DATA_CLIENT_WISE,
  FETCH_CALENDER_EVENTS,
  FETCH_ALL_TASK_LIST,
  FETCH_UPDATE_TASK_LIST,
  FETCH_TASK_WITH_ID,
  FETCH_ORGAIZATION_DATA,
  VERIFY_CONNECTORS,
  DISCONNECT_SUCCESS,
  FETCH_ALL_CLIENTS,
  ADD_NEW_TEAM_MEMBER,
  TOGGLE_LOADER,
  FETCH_CLIENT_EMAILS,
  UPLOAD_CSV_CLIENTS,
  FETCH_POC_CONTACT_CLIENTWISE,
  FETCH_CLIENT_TASKS,
  FETCH_UNREAD_NF
} from "./UserActions";

export const loaderReducer = (state = { isLoading: false }, action) => {
  switch (action.type) {
    case TOGGLE_LOADER:
      return { isLoading: !state.isLoading };
    default:
      return state;
  }
};

const initialState35 = {
  userData:null,
};

export const fetchUnreadNFReducer = (state = initialState35,action)=>{
  switch(action.type){
    case FETCH_UNREAD_NF:
      return {
        ...state,
        userData:action.payload,
      };
      default:
        return state;
  }
}

const initialState34 = {
  userData:null,
};

export const fetchClientTasksReducer = (state = initialState34,action)=>{
  switch(action.type){
    case FETCH_CLIENT_TASKS:
      return {
        ...state,
        userData:action.payload,
      };
      default:
        return state;
  }
}

const initialState33 = {
  userData:null,
};

export const fetchClientWisePocContactsReducer = (state = initialState33,action)=>{
  switch(action.type){
    case FETCH_POC_CONTACT_CLIENTWISE:
      return {
        ...state,
        userData:action.payload,
      };
      default:
        return state;
  }
}

const initialState32 = {
  userData:null,
};

export const uploadCsvFileReducer = (state = initialState32,action)=>{
  switch(action.type){
    case UPLOAD_CSV_CLIENTS:
      return {
        ...state,
        userData:action.payload,
      };
      default:
        return state;
  }
}

//add new team member reducer
const intiallState31 = {
  userData: null,
};
export const fetchedClientEmailsReducer = (state = intiallState31, action) => {
  switch (action.type) {
    case FETCH_CLIENT_EMAILS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

//add new team member reducer
const intiallState30 = {
  userData: null,
};
export const addNewTeamMemberReducer = (state = intiallState30, action) => {
  switch (action.type) {
    case ADD_NEW_TEAM_MEMBER:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH ALL CLIENTS AND CLIENT ID REDUCER
const intiallState29 = {
  userData: null,
};
export const fetchAllClientsReducer = (state = intiallState29, action) => {
  switch (action.type) {
    case FETCH_ALL_CLIENTS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH CONNECTOR DISCONNECTED REDUCER
const intiallState28 = {
  userData: null,
};
export const fetchConnectorDisconnectedReducer = (
  state = intiallState28,
  action
) => {
  switch (action.type) {
    case DISCONNECT_SUCCESS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH VERIFY CONNECTOR REDUCER
const intiallState27 = {
  userData: null,
};
export const fetchVerifyConnectorsReducer = (
  state = intiallState27,
  action
) => {
  switch (action.type) {
    case VERIFY_CONNECTORS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH UPDATE TASK DETAIL REDUCER
const intiallState26 = {
  userData: null,
};
export const fetchOrganizationIntgrationDataReducer = (
  state = intiallState26,
  action
) => {
  switch (action.type) {
    case FETCH_ORGAIZATION_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH UPDATE TASK DETAIL REDUCER
const intiallState25 = {
  userData: null,
};
export const fetchTaskWithIdReducer = (state = intiallState25, action) => {
  switch (action.type) {
    case FETCH_TASK_WITH_ID:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH UPDATE TASK DETAIL REDUCER
const intiallState24 = {
  userData: null,
};
export const fetchUpdateTaskReducer = (state = intiallState24, action) => {
  switch (action.type) {
    case FETCH_UPDATE_TASK_LIST:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH ALL TASK REDUCER
const intiallState23 = {
  userData: null,
};
export const fetchAllTaskReducer = (state = intiallState23, action) => {
  switch (action.type) {
    case FETCH_ALL_TASK_LIST:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH CALENDERS EVENTS REDUCERS
const intiallState22 = {
  userData: null,
};
export const fetchCalenderEventsReducer = (state = intiallState22, action) => {
  switch (action.type) {
    case FETCH_CALENDER_EVENTS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH CLIENT DATA CLIENT WISE REDUCER
const intiallState21 = {
  userData: null,
};
export const fetchClientDataClientWiseReducer = (
  state = intiallState21,
  action
) => {
  switch (action.type) {
    case FETCH_CLIENT_DATA_CLIENT_WISE:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH EMAIL CLIENT WISE REDUCER
const intiallState20 = {
  userData: null,
};
export const fetchEmailClientWiseReducer = (state = intiallState20, action) => {
  switch (action.type) {
    case FETCH_EMAIL_CLIENT_WISE:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH ALL TAGS REDUCER
const intiallState19 = {
  userData: null,
};
export const fetchAllTagsReducer = (state = intiallState19, action) => {
  switch (action.type) {
    case FETCH_ALL_TAGS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH ALL USER DATA REDUCER
const intiallState18 = {
  userData: null,
};
export const fetchUserReducer = (state = intiallState18, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// ADD NEW DEAL ACTIONS REDUCER
const intiallState17 = {
  userData: null,
};
export const addNewDealReducerReducer = (state = intiallState17, action) => {
  switch (action.type) {
    case SAVE_NEW_DEAL:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// create new task reducer
const intiallState16 = {
  userData: null,
};
export const createNewTaskReducer = (state = intiallState16, action) => {
  switch (action.type) {
    case CREATE_NEW_TASK:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// fetch organization team data Reducer
const intiallState15 = {
  userData: null,
};
export const fetchOrganizationTeamDataReducer = (
  state = intiallState15,
  action
) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_TEAM_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// send email action  API Reducer --------------------------

const intiallState14 = {
  userData: null,
};
export const sendEmailReducer = (state = intiallState14, action) => {
  switch (action.type) {
    case SEND_EMAIL:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// update password Reducer
const intiallState13 = {
  userData: null,
};
export const updatePasswordReducer = (state = intiallState13, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// Reducer for User Profile Setting
const intiallState12 = {
  userData: null,
};
export const fetchUserProfilePersonalBasicDataReducer = (
  state = intiallState12,
  action
) => {
  switch (action.type) {
    case FETCH_USER_PROFILE_PERSONAL_BASIC_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// Active health status graph Reducer

const intiallState11 = {
  selectedFilter: "Month",
  activeUserData: [],
};
export const fetchHealthGraphDataReducer = (state = intiallState11, action) => {
  switch (action.type) {
    case FETCH_HEALTH_GRAPH_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// upsell downsell reducer graph
const intiallState10 = {
  userData: null,
};

export const fetchUpsellsDownsellsDataReducer = (
  state = intiallState10,
  action
) => {
  switch (action.type) {
    case FETCH_UPSELL_DOWNSELL_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// updateOrganizationGeneralDataReducer

const intiallState9 = {
  userData: null,
};

export const updateOrganizationGeneralDataReducer = (
  state = intiallState9,
  action
) => {
  switch (action.type) {
    case UPDATE_ORGANOZATION_GENERAL_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
};

// update-organization-team-data               todo: update-organization-team-data

// fetch  Organization  General  Reducer
const intiallState8 = {
  dataUser: null,
};

export const fetchOrganizationGeneralReducer = (
  state = intiallState8,
  action
) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_DATA:
      return {
        ...state,
        dataUser: action.payload,
      };
    default:
      return state;
  }
};

// fetch productAdoption  graph data

const intiallState7 = {
  selectedFilter: "Month",
  activeUserData: [],
};

export const fetchProductionAdoptionGraphReducer = (
  state = intiallState7,
  action
) => {
  switch (action.type) {
    case GET_PRODCTION_ADOPTION_GRAPH_DATA:
      return {
        ...state,
        activeUserData: action.payload,
      };
    default:
      return state;
  }
};

//  fetch client dashboard data
const initialState6 = {
  activeClientData: [],
  error: null,
};

export const fetchClientData = (state = initialState6, action) => {
  switch (action.type) {
    case FETCH_CLIENT_DASHBOARD_DATA:
      return {
        ...state,
        activeClientData: action.payload,
      };
    case FETCH_CLIENT_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        activeClientData: action.error,
      };
    default:
      return state;
  }
};

//reducer for active user graph
const intiallState5 = {
  selectedFilter: "Month",
  activeUserData: [],
};

export const fetchUserDataForGraphReducer = (state = intiallState5, action) => {
  switch (action.type) {
    case GET_ACTIVE_USER_DATA:
      return {
        ...state,
        activeUserData: action.payload,
      };
    default:
      return state;
  }
};

// FETCH BASIC HOME DATA
const initialState4 = {
  data: null,
  loading: false,
  error: null,
};

export const fetchBasicHomeDataReducer = (state = initialState4, action) => {
  switch (action.type) {
    case FETCH_BASIC_HOME_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_BASIC_HOME_DATA_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_BASIC_HOME_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        action: action.error,
      };
    default:
      return state;
  }
};

// check the access token while user is logged in and validate through access token
const initialState3 = {
  isAuthenticated: !!localStorage.getItem("accessToken"),
};

export const tokenReducer = (state = initialState3, action) => {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      };

    case LOGOUT_USER:
      return {
        ...state,
        isAuthenticated: false,
      };

    default:
      return state;
  }
};

// login success and login faliure reducers

const initialState2 = {
  success: false,
  userData: true,
};

export const authReducer = (state = initialState2, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        success: true,
        userData: action.payload,
        error: null,
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        success: false,
        userData: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

// register user and user Faliure handle reducers

const initialState = {
  success: null,
  message: null,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_USER_SUCCESS:
      return {
        success: true,
        message: action.payload,
      };
    case REGISTER_USER_FAILURE:
      return {
        success: false,
        message: action.payload,
      };
    default:
      return state;
  }
};
