import React, { useEffect } from "react";
import "../css/Popup.css";
import image5 from "../images/01.png";
import { useSelector } from "react-redux";

const Popup = ({ onClose }) => {
  const CsvUploadStatus = useSelector(
    (state) => state?.uploadCsvFileReducer.userData
  );

  return (
    <div className="popup">
      <div className="popup-content">
        <img
          src={image5}
          width={150}
          height={100}
          className="img-fluid"
          alt="laptop"
        />
        <p className="overlay-heading1 fs-5">
          {CsvUploadStatus?.total_count} Client Entries Found
        </p>
        <p className="overlay-text2 text-success">Clients Successfully Added:{CsvUploadStatus?.success_count}</p>
        <p className="overlay-text2 text-warning">Client Entries Failed:{CsvUploadStatus?.failed_count}</p>
        <button onClick={() => onClose()} className="btn-overlay-1">
          Close
        </button>
      </div>
    </div>
  );
};

export default Popup;
