import React, { useEffect, useState } from "react";
// import '../css/App.css';
import "../css/ClientDashboard.css";
import Popup from "./Popup";
// import { WithContext as ReactTags } from "react-tag-input";
// import { Dropdown, Form } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
// import { Link } from "react-router-dom";
import Good from "../images/good-icn.svg";
import Average from "../images/average-icn.svg";
import Poor from "../images/poor-icn.svg";
// import UserImg from "../images/cs-user.svg";
import PlusIcn from "../images/plus-icn.svg";
import chevron from "../images/chevron-right.svg";
import Fileicn from "../images/prime_file-import.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  archiveClients,
  fetchClientDashboardData,
  toggleLoader,
  uploadCsvFile,
} from "../ReduxSetup/UserActions";
import { saveNewDealAction } from "../ReduxSetup/UserActions";
import { fetchAllUsers } from "../ReduxSetup/UserActions";
import { fetchAllTags } from "../ReduxSetup/UserActions";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import AWS from "aws-sdk";
import { MdOutlineArchive, MdOutlineUnarchive } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { HiOutlineHome } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";

function ClientsDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUrl = window.location.href.split("/")[3];

  const clientType = currentUrl === "client-dashboard" ? "client" : "archived";

  const [companyName, setCompanyName] = useState("");
  const [priority, setPriority] = useState("P1");
  const [email, setEmail] = useState("");
  const [checkedIds, setCheckedIds] = useState([]);
  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
    setTimeout(() => {
      setPopupOpen(false);
    }, 2000);
  };

  //const [allignTags, setAllignTags] = useState([]); ----------------------------------------------------------------
  // const [selectedOptions, setSelectedOptions] = useState([]);

  // const handleCheckboxChange = (option) => {
  //   if (selectedOptions.includes(option)) {
  //     setSelectedOptions(
  //       selectedOptions.filter((selected) => selected !== option)
  //     );
  //   } else {
  //     setSelectedOptions([...selectedOptions, option]);
  //   }
  // };
  // const [cssManager, setCssManager] = useState([]); ----------------------------------------------------------------
  // const [teamMemberName, setTeamMemberName] = useState("");
  const [file, setFile] = useState(null);
  // const [tags1, setTags1] = useState([]);
  // const [tags2, setTags2] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission logic here, e.g., upload the file or save the tags.
    // console.log("File:", file);
    // console.log("Tags:", tags1);
    // console.log("Tags:", tags2);
    // console.log("companyName",companyName)
    // console.log("priority",priority)
    // console.log("email",email)
    // console.log("allignTags",allignTags)
    // console.log('cssManager',cssManager)
    // console.log("teamMemberName",teamMemberName)

    // const assignees = tags1.map((item) => item.text);
    // const assignees = [2];
    // const teammembers = tags2.map((item) => item.text);
    const assignees = assignTagsValues.map((item) => item.value);
    const csmanager = managerValues.map((item) => item.value);
    // const teammembers = teamMemberValues.map((item) => item.value);
    const teamMemberName = pocTeamMember;
    // const teammembers = tags2[0]?.text;
    // console.log(
    //   companyName,
    //   priority,
    //   email,
    //   assignees,
    //   csmanager,
    //   teammembers
    // );

    if ((companyName, priority, email, assignees, csmanager, teamMemberName)) {
      dispatch(
        saveNewDealAction(
          companyName,
          priority,
          email,
          assignees,
          csmanager,
          teamMemberName
        )
      );
      setSidebarOpen(!sidebarOpen);
      toggleclass();
      setCompanyName("");
      setPriority("");
      setEmail("");
      setAssignTagsValues([]);
      setManagerValues([]);
      setPocTeamMember("");
      // setteamMemberValues([]);
    } else {
      toast.warning("Please Fill All Fields");
    }
  };

  const fetchClientDataList = useSelector((state) => {
    const activeClientData = state.fetchClientData.activeClientData;
    // Check if activeClientData.message and activeClientData.message.data are defined and data is an array
    if (
      activeClientData?.message?.data &&
      Array.isArray(activeClientData.message.data)
    ) {
      return activeClientData.message.data;
    }
    return [];
  });

  // fetch all user
  const fetchAllUser = useSelector((state) => {
    if (
      state.fetchUserReducer &&
      state.fetchUserReducer.userData &&
      state.fetchUserReducer.userData.data
    ) {
      return state.fetchUserReducer.userData.data;
    }
    return [];
  });
  // console.log('fetchAllUser',fetchAllUser);
  const [managerValues, setManagerValues] = useState([]);

  const managerOptions = fetchAllUser.map((key) => {
    return { value: key.user_id, label: key.name };
  });

  const handleManagerChange = (managerOptions) => {
    setManagerValues(managerOptions);
  };
  // fetch All Tags
  const fetchAllTag = useSelector((state) => {
    if (
      state.fetchAllTagsReducer &&
      state.fetchAllTagsReducer.userData &&
      state.fetchAllTagsReducer.userData.data
    ) {
      return state.fetchAllTagsReducer.userData.data;
    }
    return [];
  });

  const [assignTagsValues, setAssignTagsValues] = useState([]);

  const assignTagsOptions = fetchAllTag.map((key) => {
    return { value: key.tag_id, label: key.tag_name };
  });
  const handleAssignTagsChange = (assignTagsOptions) => {
    setAssignTagsValues(assignTagsOptions);
  };

  // const [teamMemberValues, setteamMemberValues] = useState([]);
  const [pocTeamMember, setPocTeamMember] = useState("");

  // const handleTeamMemberChange = (teamMemberOptions) => {
  //   setteamMemberValues(teamMemberOptions);
  // };

  // console.log('fetchAllTags',fetchAllTag);

  useEffect(() => {
    dispatch(fetchClientDashboardData(clientType));
    dispatch(fetchAllUsers());
    dispatch(fetchAllTags());
    setCheckedIds([]);
  }, [dispatch, window.location.href]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [addclass, setaddclass] = useState(false);
  const toggleclass = () => {
    setaddclass(!addclass);
  };
  useEffect(() => {
    if (addclass) {
      document.body.classList.add("body-blur");
    } else {
      document.body.classList.remove("body-blur");
    }
    return () => {
      document.body.classList.remove("body-blur");
    };
  }, [addclass]);

  const usedFetchClientData = fetchClientDataList?.map((clientData, index) => {
    // console.log(clientData.client_name)
    // Convert 'mrr' and 'productAdoption' to integers by rounding
    // console.log("productAdoption", productAdoption);
    // console.log(clientData)
    let mrr = clientData.mrr || 0;
    mrr =
      mrr > 1000 ? (mrr / 1000).toFixed(1) + "K" : parseFloat(mrr).toFixed(0);
    const productAdoption = parseFloat(clientData.product_adoption).toFixed(0);

    return {
      srNo: index + 1,
      client_id: clientData.client_id, // Assuming 'srNo' is present in 'fetchClientDataList'
      clientName: clientData.client_name, // Assuming 'client_name' is present in 'fetchClientDataList'
      companyName: clientData.company_name,
      csManager: clientData.cs_managers_list || "N/A",
      mrr: mrr,
      productAdoption: parseInt(productAdoption),
      tags: clientData.tags_list,
      health: clientData.health_color,
      tasksPending: clientData.pending_tasks,
      priority: clientData.priority,
    };
  });
  // console.log("pp", usedFetchClientData);

  const data = [...usedFetchClientData];

  // const [csManagerTextValues, setCsManagerTextValues] = useState([]);
  // const [assignTags, setAssignTags] = useState([]);
  // console.log("csManagerTextValues",csManagerTextValues)

  const [numRows, setNumRows] = useState(8); // Default to 8 rows
  const [currentPage, setCurrentPage] = useState(1);

  const totalRows = data.length;
  const totalPage = Math.ceil(totalRows / numRows);

  // Get current rows
  const indexOfLastRow = currentPage * numRows;
  const indexOfFirstRow = indexOfLastRow - numRows;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  // Pagination function
  const paginate = (pageNumber) => {
    if (pageNumber < 1) pageNumber = 1;
    if (pageNumber > totalPage) pageNumber = totalPage;

    setCurrentPage(pageNumber);
  };

  // console.log("preet1",tags1);

  // console.log("preet2", tags2);

  // const handleDelete1 = (i) => {
  //   setTags1(tags1.filter((tag, index) => index !== i));
  //   const updatedTagValues = tags1
  //     .filter((tag, index) => index !== i)
  //     .map((tag) => tag.text);
  //   setAssignTags(updatedTagValues);
  // };

  // const handleAddition1 = (tag) => {
  //   setTags1([...tags1, tag]);
  //   setAssignTags([...assignTags, tag.text]);
  // };

  // const handleDelete2 = (i) => {
  //   setTags2(tags2.filter((tag, index) => index !== i));
  //   const updatedTextValues = tags2
  //     .filter((tag, index) => index !== i)
  //     .map((tag) => tag.text);
  //   setCsManagerTextValues(updatedTextValues);
  // };

  // const handleAddition2 = (tag) => {
  //   setTags2([...tags2, tag]);
  //   setCsManagerTextValues([...csManagerTextValues, tag.text]);
  // };

  function getCurrentDateTime() {
    const now = new Date();
    const date = now.getDate();
    const month = now.getMonth() + 1; // Month is zero-based
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    const formattedDate = `${date}-${month}-${year}`;
    const formattedTime = `${hours}-${minutes}-${seconds}`;

    return `${formattedDate}_${formattedTime}`;
  }

  const handleFileChange = (e) => {
    AWS.config.update({
      accessKeyId: "AKIAUMDTMLYSMADRTYHU",
      secretAccessKey: "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",
      region: "ap-south-1",
    });

    const s3 = new AWS.S3();
    const file = e.target.files[0];

    const currentDateTime = getCurrentDateTime();

    const fileName = `${currentDateTime}_${file.name}`; // Construct filename with date
    if (file) {
      dispatch(toggleLoader());
      // Define S3 upload parameters
      const params = {
        Bucket: "viasuccess",
        Key: `csvfiles/${fileName}`,
        Body: file,
        ACL: "public-read", // Set appropriate access permissions
      };

      // Upload the image to S3
      s3.upload(params, (err, data) => {
        dispatch(toggleLoader());
        if (err) {
          toast.error("Error Uploading File");
          console.error("Error uploading file:", err);
        } else {
          dispatch(uploadCsvFile(data.Location));
          setPopupOpen(!isPopupOpen);
          setSidebarOpen(!sidebarOpen);
          toggleclass();
        }
      });
    }
  };

  // tag input store in the array starts
  // const selectedTagValues1 = tags1.map((tag) => tag.text);
  // console.log(selectedTagValues1);

  // const selectedTagValues = tags2.map((tag) => tag.text);
  // console.log(selectedTagValues)

  // tag input store in the array starts

  // const [selectedOption, setSelectedOption] = useState("");

  // const handleSelectChange = (event) => {
  //   setSelectedOption(event.target.value);
  // };
  const [searchTerm, setSearchTerm] = useState("");

  function handleSearchChange(event) {
    setSearchTerm(event.target.value.toLowerCase());
  }

  const filteredRows = data.filter((client) => {
    const clientValues = Object.values(client); // Get all values of the client object
    return clientValues.some((value) => {
      if (typeof value === "string") {
        return value.toLowerCase().includes(searchTerm);
      }
      return false; // Ignore non-string properties
    });
  });
  const [sortColumn, setSortColumn] = useState(null);
  const [sortOrder, setSortOrder] = useState(true); // true for ascending, false for descending

  function handleSortChange(event) {
    const column = event.target.value;
    // console.log("sorting colmn ----------", column)

    if (column === sortColumn) {
      setSortOrder(!sortOrder); // Toggle sort order if column remains the same
    } else {
      setSortColumn(column);
      setSortOrder(true); // Default to ascending
    }
  }

  //--------sorting of fields
  let sortedRows;
  if (sortColumn === "health") {
    // console.log(sortColumn, "heal==------")
    const customSort = (a, b) => {
      // Define the order of health statuses
      const order = { red: 3, yellow: 2, green: 1 };

      // Use the order to compare the health values
      const healthA = order[a.health] || 0;
      const healthB = order[b.health] || 0;

      return healthA - healthB;
    };

    // Sort the array based on the custom sorting function
    sortedRows = filteredRows.sort(customSort);
  } else if (sortColumn === "companyName" || sortColumn === "clientName") {
    const customSort = (array, key) => {
      return array.sort((a, b) => {
        const valueA = (a[key] || "").toUpperCase();
        const valueB = (b[key] || "").toUpperCase();

        if (!valueA && valueB) {
          return 1;
        }
        if (valueA && !valueB) {
          return -1;
        }

        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
        return 0;
      });
    };

    // Sort by clientName
    sortedRows = customSort([...filteredRows], sortColumn);
  } else if (
    sortColumn === "tasksPending" ||
    sortColumn === "mrr" ||
    sortColumn === "productAdoption"
  ) {
    function customSort(array, property) {
      return array.sort((a, b) => {
        // Handle empty values or "NaN"
        const aValue = isNaN(a[property]) ? -Infinity : a[property];
        const bValue = isNaN(b[property]) ? -Infinity : b[property];

        return bValue - aValue;
      });
    }

    sortedRows = customSort([...filteredRows], sortColumn);
  } else {
    sortedRows = filteredRows.sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortOrder ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortOrder ? 1 : -1;
      return 0;
    });
  }

  // const [filterPriority, setFilterPriority] = useState("all");

  // function handleFilterChange(event) {
  //   setFilterPriority(event.target.value);
  // }

  const finalRows = searchTerm ? sortedRows : currentRows;

  if (!fetchClientDataList) {
    return (
      <div className="react-spinner">
        <ClipLoader
          color="black"
          loading={true}
          size={70}
          aria-label="Loading Spinner"
          data-testid="loader"
          width="80"
        />
      </div>
    ); // or some loading indicator
  }

  const handleNavigateClientData = (client_id) => {
    navigate(`/ClientNotes/${client_id}`);
  };

  const handleCheckboxChange = (event) => {
    const { id } = event.target;
    if (event.target.checked) {
      // Checkbox is checked, add ID to the array
      setCheckedIds((prevIds) => [...prevIds, id]);
    } else {
      // Checkbox is unchecked, remove ID from the array
      setCheckedIds((prevIds) => prevIds.filter((prevId) => prevId !== id));
    }
  };

  const handleArchive = () => {
    const temp = clientType === "client" ? "archive" : "unarchive";

    dispatch(archiveClients(temp, checkedIds));
    dispatch(fetchClientDashboardData(clientType));
    setCheckedIds([]);
  };

  return (
    <>
      <div className="container">
        {isPopupOpen && (
          <div className="overlay">
            <Popup onClose={togglePopup} />
          </div>
        )}
        <div className="d-flex align-items-center justify-content-between">
          <div className="mt-4 ms-4">
            {clientType === "client" ? (
              <div className="d-flex ms-4 align-items-center">
                <span className="mx-2 font9 d-flex align-items-center" role="button" onClick={() => navigate('/homepage')}>
                  <HiOutlineHome className="fs-5 mx-1" /> Home
                </span>
                <IoIosArrowForward className="fs-5" />
                <span className="mx-1 text-success font9 d-flex align-items-center" role="button" onClick={() => navigate('/client-dashboard')}>
                  <CgProfile className="fs-5 mx-1" /> Client Dashboard
                </span>
              </div>
            ) : (
              <h2 className="text-secondary text-bold ms-5">Archived Clients</h2>
            )}
          </div>
          <div className="buttons table-filters">
            {checkedIds.length > 0 ? (
              <div className="archive-chat p-2 px-2 rounded fs-6 mx-2 d-flex align-items-center">
                <IoClose className="fs-4" onClick={() => setCheckedIds([])} />
                <span className="mx-3 fs-6">{checkedIds.length} selected</span>
                {clientType === "client" ? (
                  <MdOutlineArchive
                    title="Archive"
                    className="fs-4"
                    onClick={handleArchive}
                  />
                ) : (
                  <MdOutlineUnarchive
                    title="Unarchive"
                    className="fs-4"
                    onClick={handleArchive}
                  />
                )}
              </div>
            ) : (
              ""
            )}
            <div className="buttons-outer">
              {clientType === "client" ? (
                <button
                  className="new-deal-btn p-2 font9"
                  onClick={() => {
                    setSidebarOpen(!sidebarOpen);
                    toggleclass();
                  }}
                >
                  <button
                    className="chevron"
                    onClick={() => {
                      setSidebarOpen(!sidebarOpen);
                    }}
                  >
                    <img src={chevron} alt="" />
                  </button>
                  <img className="plusicn-img" src={PlusIcn} alt="" /> New Deal
                </button>
              ) : (
                ""
              )}

              <div
                className={`client-sidebar ${sidebarOpen ? "open" : "close"}`}
              >
                {/* ADD NEW DEAL FORM STARTS------------------------------------------------------------------------- */}
                <form onSubmit={handleSubmit}>
                  {/* File input */}

                  <div className="cs-input-outer">
                    <div>
                      <h2>Add a New Deal</h2>
                    </div>
                    <div>
                      <a href="https://viasuccess.s3.ap-south-1.amazonaws.com/Sample+CSV/Sample+csv-+ViaSuccess+-+sample+csv.csv">
                        <label className="custom-file-label">
                          <img src={Fileicn} alt="Fileicn" />
                          Download Sample CSV
                        </label>
                      </a>
                    </div>
                    <div>
                      <label className="custom-file-label">
                        <img src={Fileicn} alt="Fileicn" />
                        Import CSV
                        <input
                          type="file"
                          accept=".csv"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="cs-input-outer">
                    <div className="cs-left name-left">
                      <label>Company Name</label>
                      <input
                        className="custum-input"
                        type="text"
                        name="name"
                        value={companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </div>
                    <div className="cs-left name-right">
                      {/* Tag input */}
                      <label>Priority</label>
                      <select
                        className="custum-input"
                        value={priority}
                        onChange={(e) => setPriority(e.target.value)}
                      >
                        <option value="P1">P1</option>
                        <option value="P2">P2</option>
                        <option value="P3">P3</option>
                      </select>
                    </div>
                  </div>
                  <div className="cs-input-outer">
                    <div className="tags-outer">
                      <label> CS Manager (POC) </label>

                      <Select
                        isMulti
                        name="cs-manager"
                        options={managerOptions}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        value={managerValues}
                        onChange={handleManagerChange}
                      />

                      {/* <Dropdown>
                      <Dropdown.Toggle
                        variant="primary"
                        id="dropdown-basic"
                        className="text-dark"
                      >
                        Select Manager
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {fetchAllUser.map((option) => (
                          <Form.Check
                            type="checkbox"
                            key={option.user_id}
                            label={option.name}
                            checked={selectedOptions.includes(option.user_id)}
                            onChange={() =>
                              handleCheckboxChange(option.user_id)
                            }
                          />
                        ))}
                      </Dropdown.Menu>
                    </Dropdown> */}

                      {/* <select
                      className="custum-input"
                      value={cssManager}
                      onClick={(e) => setCssManager(e.target.value)}
                    >
                      <option>Select Manager</option>
                      {fetchAllUser.map((csm) => (
                        <option key={csm.user_id} value={csm.user_id}>
                          {csm.name}
                        </option>
                      ))}
                    </select> */}
                    </div>

                    {/* <div className="cs-left cs-right">

                    <label>CS Manager (POC 2)</label>
                    <input className="custum-input" type="text" name="name" />
                  </div> */}
                  </div>
                  <div className="cs-input-outer">
                    {/* Tag input */}
                    <label>Add POC Email</label>
                    <input
                      className="custum-input"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="tags-outer">
                    <label> Assign Tags </label>

                    <CreatableSelect
                      isMulti
                      options={assignTagsOptions}
                      value={assignTagsValues}
                      onChange={handleAssignTagsChange}
                    />

                    {/* <select
                    className="custum-input"
                    value={allignTags}
                    onChange={(e) => setAllignTags(e.target.value)}
                  >
                    <option>Select Tag</option>
                    {fetchAllTag.map((tag) => (
                      <option key={tag.tag_id} value={tag.tag_id}>
                        {tag.tag_name}
                      </option>
                    ))}
                  </select> */}
                    {/* <ReactTags
                    tags={tags1}
                    handleDelete={handleDelete1}
                    handleAddition={handleAddition1}
                    handleDrag={() => {}}
                    placeholder="Add a new tag"
                    inputFieldPosition="inline"
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "tagInputFieldClass",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                    }}
                  /> */}
                  </div>
                  <div>
                    <div className="cs-left d-flex flex-column">
                      <label>Add POC Team Member</label>

                      <input
                        type="text"
                        className="rounded p-2 border border-muted"
                        value={pocTeamMember}
                        onChange={(e) => setPocTeamMember(e.target.value)}
                      />

                      {/* 
                    <CreatableSelect
                      isMulti
                      // value={teamMemberValues}
                      onChange={handleTeamMemberChange}
                    /> */}

                      {/* <ReactTags
                    tags={tags2}
                    handleDelete={handleDelete2}
                    handleAddition={handleAddition2}
                    placeholder="Add Client Team Members"
                    inputFieldPosition="inline"
                    classNames={{
                      tags: "tagsClass",
                      tagInput: "tagInputClass",
                      tagInputField: "tagInputFieldClass",
                      selected: "selectedClass",
                      tag: "tagClass",
                      remove: "removeClass",
                      suggestions: "suggestionsClass",
                      activeSuggestion: "activeSuggestionClass",
                    }}
                  /> */}
                    </div>
                    <button
                      className="bg-secondary text-white p-2 rounded my-5 px-5 "
                      type="submit"
                    >
                      Add
                    </button>
                  </div>
                  {/* <div className="submit-outer"> */}
                  {/* <button onClick={()=>setSidebarOpen(!sidebarOpen)}>cancel</button> */}
                  {/* </div> */}
                </form>

                {/* ADD NEW DEAL FORM STARTS------------------------------------------------------------------------- */}
              </div>
            </div>
            <div className="tableTopControls">
              <div className="tableControl">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchInput p-2"
                  onChange={handleSearchChange}
                />
              </div>
              <div className="tableControl">
                <label>Sort By: </label>
                <select onChange={handleSortChange} className="p-2">
                  <option value="">Select Option</option>
                  <option value="clientName">POC name</option>
                  <option value="companyName">Company Name</option>
                  <option value="csManager">CS Manager</option>
                  <option value="mrr">MRR</option>
                  <option value="productAdoption">Product Adoption </option>
                  <option value="tags">Tags</option>
                  <option value="health">Health</option>
                  <option value="tasksPending">Tasks Pending</option>
                  <option value="priority">Priority</option>
                </select>
              </div>
              {/* <div className="tableControl">
              <label>Filter: </label>
              <select onChange={handleSortChange}>
                <option value="clientName">Client Name</option>
                <option value="companyName">Company Name</option>
                <option value="csManager">CS Manager</option>
                <option value="mrr">MRR</option>
                <option value="productAdoption">Product Adoption </option>
                <option value="tags">Tags</option>
                <option value="health">Health</option>
                <option value="tasksPending">Tasks Pending</option>
                <option value="priority">Priority</option>
              </select>

              <select onChange={handleFilterChange}>
                <option value="all">All</option>
                <option value="p1">High Priority</option>
                <option value="p2">Low Priority</option>
              </select>
            </div> */}
            </div>
          </div>
        </div>

        <div className="tableContainer">
          <div className="tableContainer2">
            <table className="styledTable">
              <thead>
                <tr>
                  <th></th>
                  <th>Sr.No.</th>
                  <th>POC Name</th>
                  <th>Company Name</th>
                  <th>CS Manager</th>
                  <th>MRR</th>
                  <th>Product Adoption</th>
                  <th>Tags</th>
                  <th>Health</th>
                  <th>Tasks Pending</th>
                  <th>Priority</th>
                </tr>
              </thead>
              <tbody>
                {/* actuall cient detail starts ------------------------------------------------------------------------- */}
                {finalRows.map((client, index) => (
                  <tr key={client.srNo} style={{ cursor: "pointer" }}>
                    <td>
                      <input
                        type="checkbox"
                        id={client.client_id}
                        checked={checkedIds.includes(
                          client.client_id.toString()
                        )}
                        onClick={handleCheckboxChange}
                      />
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {searchTerm || sortColumn ? index + 1 : client.srNo}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.clientName}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.companyName}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.csManager
                        ? client.csManager.split(", ").map((csm) => {
                            return (
                              <span key={csm}>
                                {csm} <br />
                              </span>
                            );
                          })
                        : "N/A"}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.mrr}{" "}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      <div className="progressBarmain">
                        <div className="progressBarOuter">
                          <div
                            className="progressBarInner"
                            style={{ width: client.productAdoption || 0 }}
                          ></div>
                        </div>
                        <span className="percentageLabel">
                          {client.productAdoption || 0}
                        </span>
                      </div>
                    </td>

                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.tags
                        ? client.tags
                            .split(", ")
                            .slice(0, 2)
                            .map((tag) => (
                              <span
                                key={tag}
                                className={`tag-client-dash text-secondary rounded p-1 ${tag
                                  .replace(" ", "-")
                                  .toLowerCase()}`}
                              >
                                {tag}
                                <br />
                              </span>
                            ))
                        : "N/A"}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.health === "green" && (
                        <img src={Good} alt="good" />
                      )}
                      {client.health === "yellow" && (
                        <img src={Average} alt="average" />
                      )}
                      {client.health === "red" && <img src={Poor} alt="poor" />}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.tasksPending}
                    </td>
                    <td
                      onClick={() => handleNavigateClientData(client.client_id)}
                    >
                      {client.priority}
                    </td>
                  </tr>
                ))}

                {/* end------------------------------------------------------------------------------------------------------------- */}
              </tbody>
            </table>
          </div>
          {/* Pagination Controls */}
          <div className="page-func-outer">
            <div className="pagination">
              <button
                onClick={() => paginate(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              {Array.from({ length: totalPage }, (_, idx) => idx + 1).map(
                (page) => (
                  <button
                    key={page}
                    onClick={() => paginate(page)}
                    className={
                      currentPage === page ? "bg-success text-white" : ""
                    }
                  >
                    {page}
                  </button>
                )
              )}
              <button
                onClick={() => paginate(currentPage + 1)}
                disabled={currentPage === totalPage}
              >
                Next
              </button>
            </div>
            <div className="rowControl">
              <span>
                Showing {indexOfFirstRow + 1} to {indexOfLastRow} of {totalRows}{" "}
                entries
              </span>
              <label>Show </label>
              <input
                type="number"
                value={numRows}
                onChange={(e) => {
                  let value = parseInt(e.target.value, 10);
                  if (!value || value <= 0) {
                    // Check for 0, negative values, or NaN
                    value = 8; // Default to 8
                  }
                  setNumRows(value);
                }}
                className="numRowsInput"
                min="1"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientsDashboard;
