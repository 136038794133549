import { React } from "react";
import "../css/LoginRegister.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import image1 from "../images/ic_google logo.svg";
import image2 from "../images/linkedin 2.png";
import image5 from "../images/my-image1.jpg";
import { useState } from "react";
import showPwdImg from "../images/show-password.svg";
import hidePwdImg from "../images/hide-password.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUser } from "../ReduxSetup/UserActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function RegisterPage() {
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  const registrationMessage = useSelector((state) => state.user.message);
  console.log(registrationMessage);
  const registrationSuccess = useSelector((state) => state.user.success);

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    workEmail: "",
    companyName: "",
    password: "",
  });

  const handleOnSubmit = async (e) => {
    e.preventDefault();




    const nameRegex = /^(?!\s*$)[A-Za-z\s'-]+$/;
    const companyRegex = /^(?!\s+$)[A-Za-z\s-_0-9&'()]+$/;
    const passwordRegex = /^(?!\s{8,}).{8,}$/;
    const emailRegex = /^[\w.-]+@[a-zA-Z\d.-]+.[a-zA-Z]{2,}$/;

    if (!nameRegex.test(userData.name)) {
      toast.error("Invalid name format", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      return;
    }

    if (!emailRegex.test(userData.workEmail)) {
      toast.error("Invalid email format", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      return;
    }

    if (!companyRegex.test(userData.companyName)) {
      toast.error("Invalid company name format", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      return;
    }

    if (!passwordRegex.test(userData.password)) {
      toast.error("Password must be at least 8 characters", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      return;
    }

    if (registrationSuccess === true) {
      toast.success("Registration successful", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      Navigate("/");
    } else {
      toast.error("User with the same email already exists", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      await dispatch(registerUser(userData));
    }

  };

  return (
    <section className="dom">
      <Container>
        <Row>
          <Col xxl={6} xl={6} md={6} sm={12} xs={12} className="image1">
            <img
              src={image5}
              width={750}
              height={700}
              className="custom-img-f"
              alt="laptop"
            />
          </Col>

          <Col xxl={6} xl={6} md={6} sm={12} xs={12} className="form-template">
            {/* regestrartion form stats here */}

            <Form className="form-1" onSubmit={handleOnSubmit}>
              <h3 className="heading-form">Registration copy here</h3>
              <Form.Group as={Row} className="mb-1">
                <Form.Label column sm="12" className="lable-text">
                  Name<span className="text-span">*</span>
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    className="p-3"
                    type="text"
                    placeholder="name"
                    value={userData.name}
                    onChange={(e) =>
                      setUserData({ ...userData, name: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-1">
                <Form.Label column sm="12" className="lable-text">
                  Email Address<span className="text-span">*</span>
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    className="p-3"
                    type="email"
                    placeholder="email@example.com"
                    value={userData.workEmail}
                    onChange={(e) =>
                      setUserData({ ...userData, workEmail: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1">
                <Form.Label column sm="12" className="lable-text">
                  Company Name<span className="text-span">*</span>
                </Form.Label>
                <Col sm="12">
                  <Form.Control
                    className="p-3"
                    type="text"
                    placeholder="company name"
                    value={userData.companyName}
                    onChange={(e) =>
                      setUserData({ ...userData, companyName: e.target.value })
                    }
                  />
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-1">
                <Form.Label column sm="12" className="lable-text">
                  Password<span className="text-span">*</span>
                </Form.Label>
                <br></br>
                <Col sm="12">
                  <Form.Control
                    className="p-3"
                    type={isRevealPwd ? "text" : "password"}
                    id="show_hide_password"
                    name="pwd"
                    placeholder="Password"
                    value={userData.password}
                    onChange={(e) =>
                      setUserData({ ...userData, password: e.target.value })
                    }
                  />
                  <img
                    width={20}
                    height={20}
                    title={isRevealPwd ? "Hide password" : "Show password"}
                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    className="hide-unhide"
                  />
                </Col>
              </Form.Group>
              <Row>
                <button className="btn-form" type="submit">
                  Sign Up
                </button>
              </Row>
              <Row>
                <Link className="my-4 text-center" to="/" ><span className="text-secondary">Already have an account? </span><span className="text-decoration-underline text-dark" >Login</span></Link>
              </Row>
              {/* <p className="divider">or</p>
              <br></br> */}
              {/* <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Link to="">
                    <button className="google-btn-form me-2">
                      {" "}
                      <img
                        src={image1}
                        width={16}
                        height={16}
                        className="img-fluid1 me-1"
                        alt="dog"
                      />
                      Login with google{" "}
                    </button>
                  </Link>
                </Col>

                <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Link to="">
                    <button className="google-btn-form">
                      {" "}
                      <img
                        src={image2}
                        width={16}
                        height={16}
                        className="img-fluid1 me-1"
                        alt="dog"
                      />
                      Login with Linkedin{" "}
                    </button>
                  </Link>
                </Col>
              </Row> */}
            </Form>

            {/* regestrartion form ends here */}

            <p className="footer-line">
              <span className="text-footer mt-2">
                © 2023 ViaSucess. All rights reserved.
              </span>
              <span>
                <a className="btn" href="#">
                  Terms & Conditions
                </a>
                <a className="btn" href="#">
                  Privacy Policy
                </a>
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default RegisterPage;
