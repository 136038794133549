import { React, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/Task.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { BsArrowRightCircle } from "react-icons/bs";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { toast } from "react-hot-toast";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllUsers,
  fetchAllTaskList,
  fetchUserProfilePersonalBasicData,
  fetchUpdateTaskDetails,
  fetchClientDashboardData,
  fetchTaskWithId,
  fetchclients,
  createNewTask,
} from "../ReduxSetup/UserActions";
import { useNavigate } from "react-router";

const TaskPopup = ({
  showTaskPopup: show,
  setShowTaskPopup: setShow,
  data,
  ...props
}) => {
  const dispatch = useDispatch();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const navigate = useNavigate();
  const [ides, setIdes] = useState("");
  const [taskData, setTaskData] = useState({
    clientId: data?.client_id || "",
    status: data?.status || "",
    dueDate: data?.due_date,
    assignedUserId: data?.assigned_user_id || "",
    reminderTime: data?.reminder_time || "",
    priority: data?.priority || "",
    type: data?.type || "",
    notes: data?.notes || "",
    taskName: data?.task_name || "",
    // created_by_name: data?.created_by_name || "",
    // clientName: data?.client_name || "",
    // company_name: data?.company_name || "",
    // assigned_user_name: data?.assigned_user_name,
    // created_on: data?.created_on,
    // created_by: data?.created_by,
  });

  const handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    if (name === "dueDate")
      setTaskData({ ...taskData, [name]: value.replace("T", " ") });
    else setTaskData({ ...taskData, [name]: value });
  };

  const createdBY = useSelector((state) => {
    if (
      state.fetchUserProfilePersonalBasicDataReducer &&
      state.fetchUserProfilePersonalBasicDataReducer.userData &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data[0]
    ) {
      return state.fetchUserProfilePersonalBasicDataReducer.userData.data[0];
    } else {
      return null;
    }
  });

  const fetchAllUserTask = useSelector((state) => {
    if (
      state.fetchUserReducer &&
      state.fetchUserReducer.userData &&
      state.fetchUserReducer.userData.data
    ) {
      return state.fetchUserReducer.userData.data;
    }
    return [];
  });

  const fetchAllClients = useSelector((state) => {
    if (state.fetchAllClientsReducer && state.fetchAllClientsReducer.userData) {
      return state.fetchAllClientsReducer.userData;
    }
    return [];
  });

  useEffect(() => {
    dispatch(fetchUserProfilePersonalBasicData());
    dispatch(fetchClientDashboardData("client"));
    dispatch(fetchAllUsers());
    dispatch(fetchclients());
  }, [dispatch]);

  const [shows, setShows] = useState(false);

  const handleCloseLeft = () => {
    setShow(false);
    setTaskData({
      clientId: "",
      status: "",
      dueDate: null,
      assignedUserId: "",
      reminderTime: "",
      priority: "",
      type: "",
      notes: "",
      taskName: "",
      // created_by_name: "",
    });
  };
  const handleCloseRight = () => {
    navigate("/taskpage");
    setShows(false);
  };
  const handleShowRight = () => {
    setShows(true);
    // navigate(`/taskpage/${id}`);
  };

  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
    setTimeout(() => {
      setPopupOpen(false);
    }, 2000);
  };

  const fetchTaskIdDetails = useSelector((state) => {
    if (
      state.fetchTaskWithIdReducer &&
      state.fetchTaskWithIdReducer.userData &&
      state.fetchTaskWithIdReducer.userData.data
    ) {
      return state.fetchTaskWithIdReducer.userData.data[0];
    } else {
      return null;
    }
  });

  const timestamp = fetchTaskIdDetails?.due_date;
  const datePart = timestamp?.substring(0, 10);

  //   useEffect(() => {
  //     if (fetchTaskIdDetails) {
  //       setUpdateNotes(fetchTaskIdDetails.notes);
  //       setUpdateTaskName(fetchTaskIdDetails.task_name);
  //       setUpdateDueDate(datePart);
  //       setUpdateReminderTime(fetchTaskIdDetails.reminder_time);
  //       setUpdatePriority(fetchTaskIdDetails.priority);
  //       setUpdateType(fetchTaskIdDetails.type);
  //       setUpdateStatus(fetchTaskIdDetails.status);
  //       setUpdateClientUserId(fetchTaskIdDetails.assigned_user_id);
  //       setUpdateAsignUserId(fetchTaskIdDetails.client_id);
  //     }
  //   }, [fetchTaskIdDetails]);

  useEffect(() => {
    if (fetchAllUserTask) {
      const reloadOptions = fetchAllUserTask.map((key) => {
        return { value: key.user_id, label: key.name };
      });
      setSelectedOptions(reloadOptions);
    }
  }, [fetchAllUserTask]);

  const MapTaskId = (id) => {
    setIdes(id);
    dispatch(fetchTaskWithId(id));
  };

  const handleTaskSubmit = (e) => {
    e.preventDefault();

    if (data.task_id) {
      dispatch(fetchUpdateTaskDetails(data.task_id, taskData));
      console.log(taskData);
    } else {
      const updateTaskData = {
        ...taskData,
        created_by: createdBY?.user_id,
      }
      dispatch(createNewTask(updateTaskData));
      console.log(updateTaskData);
    }
    setShow(false);
  };

  return (
    <Container className="fluid">
      <Offcanvas
        style={{ width: "800px" }}
        show={show}
        onHide={handleCloseLeft}
        placement="end"
        {...props}
      >
        <Offcanvas.Header closeButton className="close-btn-offcanvas px-5">
          <BsArrowRightCircle className="svg-offcanvas" />
        </Offcanvas.Header>
        <Offcanvas.Body>
          {/* add new task real form starts --------------------------------------------------------------------------------------------- */}
          <Form onSubmit={handleTaskSubmit} className="px-5">
            {data.task_id && (
              <>
                <Offcanvas.Title className="my-2 text-capitalize fw-semibold">
                  {taskData?.taskName}
                </Offcanvas.Title>
                <div className="d-flex">
                  <p>
                    <span>Created by : </span>{" "}
                    <span>{fetchAllClients?.find(item=>item.client_id===taskData?.clientId)?.client_name}</span>
                  </p>
                  <p className="ms-5">
                    <span>Created On : </span>{" "}
                    <span>{taskData.dueDate.split("T")[0]}</span>
                  </p>
                </div>
              </>
            )}
            {!data.task_id && (
              <Offcanvas.Title className="my-2">Add New Task</Offcanvas.Title>
            )}

            <Row className="offcanvas-row-1">
              {!data.task_id && (
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  className="offcanvas-col-1"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className=""
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="12" className="lable-text">
                        Task Name<span className="text-span">*</span>
                      </Form.Label>
                      <Col sm="12">
                        <Form.Control
                          className="p-2"
                          type="text"
                          placeholder="Enter task name"
                          value={taskData.taskName}
                          name="taskName"
                          onChange={handleChange}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
              )}
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                className="offcanvas-col-1"
              >
                <div>
                  <Form.Label column sm="12" className="lable-text">
                    Assigned To<span className="text-span">*</span>
                  </Form.Label>
                  {/* <span className="offcanvas-span mb-2">Assigned User</span> */}
                  <Form.Select
                    className="task-dropdown-20 p-2 "
                    aria-label="Default select example"
                    name="assignedUserId"
                    value={taskData.assignedUserId}
                    onChange={handleChange}
                  >
                    <option>Select User</option>
                    {fetchAllUserTask.map((task, i1) => (
                      <option key={i1} value={task.user_id}>
                        {task.name}
                      </option>
                    ))}
                  </Form.Select>
                  {/* <Form.Control
                      className="task-dropdown-20 mt-2 p-3"
                      type="number"
                      placeholder="Assigned UserId"
                      value={assignedUserId}
                      onChange={(e) => setAssignedUserId(e.target.value)}
                    /> */}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                sm={12}
                className="offcanvas-col-1 "
              >
                <Form.Group
                  as={Row}
                  className=" offcanvas-lable"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="4" className="lable-text">
                    Date<span className="text-span">*</span>
                  </Form.Label>
                  <br></br>
                  <Col sm="12">
                    <Form.Control
                      className="p-2"
                      type="date"
                      placeholder="DD/MM/YYYY"
                      name="dueDate"
                      value={taskData.dueDate}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col xxl={2} xl={2} lg={2} md={2} sm={12}>
                <Form.Group
                  as={Row}
                  className=" offcanvas-lable"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="4" className="lable-text">
                    Time<span className="text-span">*</span>
                  </Form.Label>
                  <br></br>
                  <Col sm="12">
                    <Form.Control
                      className="p-2"
                      type="Time"
                      placeholder="12:00"
                      // value={time}
                      // onChange={(e) => setTime(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>

              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                className="offcanvas-col-1"
              >
                <div>
                  <Form.Label column sm="12" className="lable-text">
                    Send Reminder<span className="text-span">*</span>
                  </Form.Label>
                  {/* <span className="offcanvas-span mb-2  ">Send Reminder</span> */}
                  <Form.Select
                    className="task-dropdown-20  p-2 "
                    aria-label="Default select example"
                    name="reminderTime"
                    value={taskData.reminderTime}
                    onChange={handleChange}
                  >
                    <option>Select Minutes</option>
                    <option value="30">30 minutes before</option>
                    <option value="40">40 minutes before</option>
                    <option value="50">50 minutes before</option>
                    <option value="60">60 minutes before</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              {!data.task_id && (
                <Col
                  xxl={6}
                  xl={6}
                  lg={6}
                  md={6}
                  sm={4}
                  className="offcanvas-col-1"
                >
                  <div>
                    <Form.Group
                      as={Row}
                      className=" offcanvas-lable"
                      controlId="formPlaintextEmail"
                    >
                      <Form.Label column sm="4" className="lable-text">
                        Created By<span className="text-span">*</span>
                      </Form.Label>
                      <br></br>
                      <Col sm="12">
                        <Form.Control
                          disabled
                          className="task-dropdown-20 p-2"
                          type="text"
                          placeholder="Assigned UserId"
                          value={createdBY?.name}
                          readOnly
                          // onChange={(e)=> setCreatedBy(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                </Col>
              )}

              <Col
                xxl={4}
                xl={4}
                lg={4}
                md={4}
                sm={12}
                className="offcanvas-col-1"
              >
                <div>
                  <Form.Label column sm="12" className="lable-text">
                    Priority<span className="text-span">*</span>
                  </Form.Label>
                  {/* <span className="offcanvas-span mb-2">Priority *</span> */}

                  <Form.Select
                    className=" py-2"
                    aria-label="Default select example"
                    ariaValueText
                    name="priority"
                    value={taskData.priority}
                    onChange={handleChange}
                  >
                    <option>Select Priority</option>
                    <option value="urgent">urgent</option>
                    <option value="high">high</option>
                    <option value="medium">medium</option>
                    <option value="low">low</option>
                  </Form.Select>
                </div>
              </Col>
              <Col
                xxl={2}
                xl={2}
                lg={2}
                md={2}
                sm={12}
                className="offcanvas-col-1"
              >
                <div>
                  <Form.Label column sm="12" className="lable-text">
                    Type<span className="text-span">*</span>
                  </Form.Label>
                  {/* <span className="offcanvas-span mb-2">Type *</span> */}
                  <Form.Select
                    className="task-dropdown py-2 "
                    aria-label="Default select example"
                    name="type"
                    value={taskData.type}
                    onChange={handleChange}
                  >
                    <option>select</option>
                    <option value="call">call</option>
                    <option value="email">E-Mail</option>
                    <option value="emeet">E-Meet</option>
                    <option value="todo">To-Do</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <p className="m-0 mt-3 fw-semibold">Associate task with</p>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={6}
                className="offcanvas-col-1"
              >
                <div>
                  <Form.Label column sm="12" className="lable-text">
                    Client Name<span className="text-span">*</span>
                  </Form.Label>
                  {/* <span className="offcanvas-span mb-2 mx-2 ">
                    Client Name *
                  </span> */}
                  <Form.Select
                    className="task-dropdown-3 w-100 py-2"
                    aria-label="Default select example"
                    name="clientId"
                    value={taskData?.clientId}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    {fetchAllClients?.map((item, index) => {
                      return (
                        <option key={index} value={item.client_id}>
                          {item.client_name}
                        </option>
                      );
                    })}
                  </Form.Select>
                  {/* <Form.Control
                  className="task-dropdown-20 mt-2 p-3"
                  type="number"
                  placeholder="Assigned Clinet Id"
                  value={clientId}
                  onChange={(e) => setClientId(e.target.value)}
                /> */}
                </div>
              </Col>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                className="offcanvas-col-1"
              >
                <div>
                  <Form.Label column sm="4" className="lable-text">
                    Status<span className="text-span">*</span>
                  </Form.Label>
                  {/* <span className="offcanvas-span mb-2 ">Status *</span> */}
                  <Form.Select
                    className="task-dropdown-3 w-100 py-2 "
                    aria-label="Default select example"
                    name="status"
                    value={taskData.status}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="pending">Pending</option>
                    <option value="Hold">Hold</option>
                    <option value="Done">Done</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xxl={12} xl={12} lg={8} md={12} sm={12} className="mt-2">
                <Form.Group
                  as={Row}
                  className="mb-1"
                  controlId="formPlaintextEmail"
                >
                  <Form.Label column sm="12" className="lable-text">
                    Note<span className="text-span">*</span>
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      className="p-3"
                      as="textarea"
                      rows={2}
                      placeholder="note"
                      name="notes"
                      value={taskData.notes}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Col
              xxl={12}
              xl={12}
              lg={12}
              md={12}
              sm={12}
              className="col-offcanvas mt-3 mb-2"
            >
              {/* <div className="mb-4"> */}
              {/* <Button type="button" className="btn-offcanvas-1 py-2 px-5">
                Cancel
              </Button> */}
              <Button
                type="submit"
                className="bg-secondary p-2 px-4"
                // onClick={togglePopup}
              >
                {data?.task_id ? "Update Task" : "Create Task"}
              </Button>
              {/* </div> */}
            </Col>
          </Form>
          {/* add new task real form ends ---------------------------------- */}
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default TaskPopup;
