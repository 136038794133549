import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { createPocClient, fetchAllPocContacts, updatePocDetail } from "../ReduxSetup/UserActions";

const ContactDetailPopup = ({ detail, onClose }) => {
  const { clientId } = useParams();
  const dispatch = useDispatch();
  const [ContactData, setContactData] = useState({
    name: detail.contact_name || "",
    email: detail.email || "",
    contact: detail.contact_number || "",
    designation: detail.designation || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContactData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    let tempData = {
      clientId: clientId,
      pocName: ContactData.name,
      pocDesignation: ContactData.designation,
      pocEmail: ContactData.email,
      pocPhoneNumber: ContactData.contact,
    };

    if (
      tempData.pocName === "" ||
      tempData.pocDesignation === "" ||
      tempData.pocEmail === "" ||
      tempData.pocPhoneNumber === ""
    ) {
      toast.error("Please fill all fields");
    } else {
      if (detail.length === 0) {
        dispatch(createPocClient(tempData));
        dispatch(fetchAllPocContacts(clientId));
      } else {
        tempData["type"] = "secondary";
        tempData["contactId"] = detail.contact_id;
        dispatch(updatePocDetail(tempData));
        dispatch(fetchAllPocContacts(clientId));
      }
      onClose();
    }
  };
  return (
    <div className="contact-popup">
      <div className="contact-popup-container text-secondary shadow bg-white p-4 rounded rounded-4 animate__animated animate__fadeInDown">
        <h4 className="fw-semibold ms-3">Contact Details</h4>
        <button
          className="position-absolute end-0 me-4 bg-danger text-white rounded border-0 px-2 font9 top-0 mt-4"
          onClick={onClose}
        >
          X
        </button>
        <div className="my-3 mx-2">
          <label htmlFor="" className="fw-bold fs-6 p-2">
            POC Name
          </label>{" "}
          <br />
          <input
            type="text"
            placeholder="Enter Name"
            name="name"
            className="p-2 px-3 font9 rounded w-100"
            value={ContactData.name}
            onChange={handleChange}
            required
          />{" "}
          <br />
          <label htmlFor="" className="p-2 fw-bold fs-6">
            Email
          </label>{" "}
          <br />
          <input
            type="text"
            name="email"
            placeholder="Enter Email"
            className="p-2 px-3 font9 rounded w-100"
            value={ContactData.email}
            onChange={handleChange}
            required
          />{" "}
          <br />
          <label htmlFor="" className="p-2 fw-bold fs-6">
            Contact
          </label>{" "}
          <br />
          <input
            type="number"
            name="contact"
            placeholder="Enter Contact"
            className="p-2 px-3 font9 rounded w-100"
            value={ContactData.contact}
            onChange={handleChange}
            required
          />{" "}
          <br />
          <label htmlFor="" className="p-2 fw-bold fs-6">
            Designation
          </label>{" "}
          <br />
          <input
            type="text"
            name="designation"
            placeholder="Enter Designation"
            className="p-2 px-3 font9 rounded w-100"
            value={ContactData.designation}
            onChange={handleChange}
            required
          />
        </div>
        <div className="save-button d-flex justify-content-center align-items-center mt-2">
          <button
            className="font9 rounded p-2 px-3 shadow mx-2 bg-success border-0 text-white"
            onClick={handleUpdate}
          >
            {" "}
            Save Details
          </button>
          <button
            className="font9 rounded p-2 px-3 shadow mx-2 bg-secondary border-0 text-white"
            onClick={onClose}
          >
            {" "}
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContactDetailPopup;
