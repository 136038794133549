// userActions.js
import axios from "axios";
import { toast } from "react-hot-toast";
// Action Types
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAILURE = "REGISTER_USER_FAILURE";

export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";

export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const LOGOUT_USER = "LOGOUT_USER";

export const FETCH_BASIC_HOME_DATA_REQUEST = "FETCH_BASIC_HOME_DATA_REQUEST";
export const FETCH_BASIC_HOME_DATA_SUCCESS = "FETCH_BASIC_HOME_DATA_SUCCESS";
export const FETCH_BASIC_HOME_DATA_FAILURE = "FETCH_BASIC_HOME_DATA_FAILURE";

export const GET_ACTIVE_USER_DATA = "GET_ACTIVE_USER_DATA"; //get user data for graph actions...
export const GET_PRODCTION_ADOPTION_GRAPH_DATA =
  "GET_PRODCTION_ADOPTION_GRAPH_DATA";

export const FETCH_CLIENT_DASHBOARD_DATA = "FETCH_CLIENT_DASHBOARD_DATA";
export const FETCH_CLIENT_DASHBOARD_DATA_FAILURE =
  "FETCH_CLIENT_DASHBOARD_DATA_FAILURE";

export const FETCH_ORGANIZATION_DATA = "FETCH_ORGANIZATION_DATA";

export const UPDATE_ORGANOZATION_GENERAL_DATA =
  "UPDATE_ORGANOZATION_GENERAL_DATA";

export const FETCH_UPSELL_DOWNSELL_DATA = "FETCH_UPSELL_DOWNSELL_DATA";

export const FETCH_HEALTH_GRAPH_DATA = "FETCH_HEALTH_GRAPH_DATA";

export const FETCH_USER_PROFILE_PERSONAL_BASIC_DATA =
  "FETCH_USER_PROFILE_PERSONAL_BASIC_DATA";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

export const SEND_EMAIL = "SEND_EMAIL";

export const FETCH_ORGANIZATION_TEAM_DATA = "FETCH_ORGANIZATION_TEAM_DATA";

export const CREATE_NEW_TASK = "CREATE_NEW_TASK";

export const SAVE_NEW_DEAL = "SAVE_NEW_DEAL";

export const FETCH_ALL_USERS = "FETCH_ALL_USERS";

export const FETCH_ALL_TAGS = "FETCH_ALL_TAGS";

export const FETCH_EMAIL_CLIENT_WISE = "FETCH_EMAIL_CLIENT_WISE";

export const FETCH_CLIENT_DATA_CLIENT_WISE = "FETCH_CLIENT_DATA_CLIENT_WISE";

export const FETCH_CALENDER_EVENTS = "FETCH_CALENDER_EVENTS";

export const FETCH_ALL_TASK_LIST = "FETCH_ALL_TASK_LIST";

export const FETCH_UPDATE_TASK_LIST = "FETCH_UPDATE_TASK_LIST";

export const FETCH_TASK_WITH_ID = "FETCH_TASK_WITH_ID";

export const FETCH_ORGAIZATION_DATA = "FETCH_ORGAIZATION_DATA";

export const VERIFY_CONNECTORS = "VERIFY_CONNECTORS";

export const DISCONNECT_SUCCESS = "DISCONNECT_SUCCESS";

export const FETCH_ALL_CLIENTS = "FETCH_ALL_CLIENTS";

export const ADD_NEW_TEAM_MEMBER = "ADD_NEW_TEAM_MEMBER";

export const TOGGLE_LOADER = "TOGGLE_LOADER";

export const FETCH_CLIENT_EMAILS = "FETCH_CLIENT_EMAILS";

export const UPLOAD_CSV_CLIENTS = "UPLOAD_CSV_CLIENTS";

export const FETCH_POC_CONTACT_CLIENTWISE = "FETCH_POC_CONTACT_CLIENTWISE";

export const FETCH_UNREAD_NF = "FETCH_UNREAD_NF";

export const FETCH_CLIENT_TASKS = "FETCH_CLIENT_TASKS";

export const toggleLoader = () => ({ type: TOGGLE_LOADER });

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    common: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  },
});

export const handleUnauthorized = (dispatch) => {
  dispatch(setAuthenticated(false));
  localStorage.removeItem("accessToken");
  window.location.href = "/";
};

//update renewal date in individual client's page
export const updateRenewalDate = (clientId,date) => {
  return async (dispatch) => {
    dispatch(toggleLoader());
    try {
      const response = await api.post(`/update-renewal-date?clientId=${clientId}&renewalDate=${date}`);
      console.log(response.data);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
}

// Update Poc Contact
export const updatePocDetail = (data) => {
  return async (dispatch) => {
    dispatch(toggleLoader());
    try {
      const response = await api.post(`/update-poc-contacts`, data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// Create poc client
export const createPocClient = (data) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post(`/create-poc-contact`,data);
      console.log(response.data)
      // toast.success(response.data)
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// Fetch ClientWise Poc
export const fetchAllPocContacts = (id) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/fetch-poc-contacts?clientId=${id}`);
      dispatch({
        type: "FETCH_POC_CONTACT_CLIENTWISE",
        payload: response.data.message.data,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};
// Fetch unread Notifications
export const fetchUnreadNF = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/notification-unread`);
      dispatch({
        type: "FETCH_UNREAD_NF",
        payload: response.data.message.data,
      });
      console.log(response.data.message.data)
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// Upload csv file
export const uploadCsvFile = (url) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post(`/bulk-client-upload`, {
        csvUrl: url,
      });
      dispatch({
        type: "UPLOAD_CSV_CLIENTS",
        payload: response.data.message.data,
      });
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// updating personal profile data
export const archiveClients = (typee, clientIds) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/change-archive-status", {
        type: typee,
        clientList: clientIds,
      });
      // console.log(response.data);
      toast.success(`Clients ${typee}d Successfully`);
      // toast.success(response.data.message)
    } catch (error) {
      console.log("send eamil errro", error.response.data);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// updating personal profile data
export const updatePersonalProfileData = (dataObj) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/update-personal-basic-data", dataObj);
      // console.log(response.data);
      toast.success(response.data.message.message);
      // toast.success(response.data.message)
    } catch (error) {
      console.log("send eamil errro", error.response.data);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//Delete a team Member
export const deleteTeamMember = (userId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      await api.get(`/delete-user?userId=${userId}`);
      toast.success("Deleted Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//updating client data on individual page
export const updateClientData = (dataObj) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/update-client-data", dataObj);
      console.log(response.data);
      // toast.success(response.data.message)
    } catch (error) {
      console.log("send eamil errro", error.response.data);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//Fetching team members with their id
export const fetchclients = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/fetch-all-clients`);
      dispatch({
        type: "FETCH_ALL_CLIENTS",
        payload: response.data.message.data,
      });

      // console.log("send email", response.data);
    } catch (error) {
      console.log("send eamil errro", error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//Disconnecting connector status
export const disconnectConnector = (connector_id) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(
        `/disconnect-connector?connectorId=${connector_id}`
      );
      dispatch({
        type: "DISCONNECT_SUCCESS",
        payload: response.data,
      });
      //  console.log(response.data)
      // console.log("send email", response.data);
    } catch (error) {
      console.log("send eamil errro", error.response.data);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//verify connectors API actions
export const verifyConnectorsSuccess = (connectorsData) => ({
  type: VERIFY_CONNECTORS,
  payload: connectorsData,
});

export const verifyConnectorsAction = (connector_id, keyValues) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/verify-connector", {
        connector_id: connector_id,
        required_keys: keyValues,
      });
      dispatch(verifyConnectorsSuccess(response.data));
      if (connector_id === 8) window.location.href = "/user-profile";
    } catch (error) {
      console.log("send eamil errro", error.response.data);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// FETCH TASK WITH THE HELP OF ASK ID   (todo mapping)
export const fetchOrganizationIntegrationData = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/organization-integration-data`);
      // console.log("fetchOrganizationData", response.data.message);
      dispatch({
        type: "FETCH_ORGAIZATION_DATA",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//Fetch Client's Tasks
export const fetchClientTasks = (clientId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());
    try {
      const response = await api.get(`/fetch-client-task?clientId=${clientId}`);
      console.log("fetchClientTasks", response.data);
      dispatch({
        type: "FETCH_CLIENT_TASKS",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
}

// FETCH TASK WITH THE HELP OF ASK ID   (todo mapping)
export const fetchTaskWithId = (taskId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/fetch-task-details?taskId=${taskId}`);
      console.log("fetchTaskById", response.data.message);
      dispatch({
        type: "FETCH_TASK_WITH_ID",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// UPDATE TASK DETAIL WITH TASK ID    (todo mapping and fetching)

export const fetchUpdateTaskDetails = (
  taskId,
  taskData
) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post(`/update-task-details?taskId=${taskId}`, taskData);
      // console.log("fetchUpdateTaskDetails", response.data.message);
      toast.success(response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      // window.location.href = "/taskpage";
      dispatch({
        type: "FETCH_UPDATE_TASK_LIST",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
      // toast.error(error.response.data.message.message, {
      //   position: "top-center",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   newestOnTop: false,
      //   closeOnClick: true,
      //   rtl: false,
      //   pauseOnFocusLoss: true,
      //   draggable: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
    dispatch(toggleLoader());
  };
};

// FETCH FETCH_ALL_TASK_LIST USER WISE   (todo mapping)

export const fetchAllTaskList = (clientIds) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post(`/fetch-tasks`, {
        assignedUserId: clientIds,
      });
      console.log("fetchTaskList", response.data.message);
      dispatch({
        type: "FETCH_ALL_TASK_LIST",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// FETCH CALENDERS EVENTS ACTION

export const fetchCalendersEvents = (start, end) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(
        `/fetch-calendar-events?startDate=${start}&endDate=${end}`
      );
      // console.log("fetchCalendersEvents", response.data.message);
      dispatch({
        type: "FETCH_CALENDER_EVENTS",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

export const fetchClientWiseEmailRecipients = (clientId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/fetch-client-emails?clientId=${clientId}`);
      // console.log("fetchClientDataClientWise", response.data.message);
      dispatch({
        type: "FETCH_CLIENT_EMAILS",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// FETCH EMAIL CLIENT Data Client Wise

export const fetchClientDataClientWise = (clientId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/client-data?clientId=${clientId}`);
      // console.log("fetchClientDataClientWise", response.data.message);
      dispatch({
        type: "FETCH_CLIENT_DATA_CLIENT_WISE",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// FETCH EMAIL CLIENT WISE

export const fetchEmailClientWise = (clientId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(
        `/fetch-incoming-emails?clientId=${clientId}`
      );
      // console.log("fetchEmailClientWise", response.data.message);
      dispatch({
        type: "FETCH_EMAIL_CLIENT_WISE",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//Delete a Tag on individual client page
export const deleteTag = (tagId, clientId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      await api.post(`/delete-tags`, {
        tagId: tagId,
        clientId: clientId,
      });
      toast.success("Tag Deleted Successfully");
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//FETCH ALL TAGS FOR PASSING THE DATA INTO THE SELECT BOX WHILE CRAETE THE NEW DEAL
export const fetchAllTags = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/all-tags`);
      dispatch({
        type: "FETCH_ALL_TAGS",
        payload: response.data.message,
      });
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//FETCH ALL USERS FOR PASSING THE DATA INTO THE SELECT BOX WHILE CRAETE THE NEW DEAL
export const fetchAllUsers = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/fetch-all-users`);
      dispatch({
        type: "FETCH_ALL_USERS",
        payload: response.data.message,
      });
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// ADD NEW DEAL ACTIONS
export const addNewDeal = (dealData) => ({
  type: SAVE_NEW_DEAL,
  payload: dealData,
});

export const saveNewDealAction = (
  companyName,
  priority,
  email,
  tags,
  csManagers,
  teamMemberName
) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      console.log(
        companyName,
        priority,
        email,
        tags,
        csManagers,
        teamMemberName
      );
      const response = await api.post("/save-new-deal", {
        companyName,
        priority,
        email,
        tags,
        csManagers,
        teamMemberName,
      });
      dispatch(addNewDeal(response.data.message));
      // console.log("add new deal", response.data.message);
      toast.success("New Deal Added Successfully", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      window.location.href = "/client-dashboard";
    } catch (error) {
      console.log("add new deal errro", error.response.data);
      toast.error(error.response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// create-new-task  action
export const createNewTaskSuccess = (taskData) => ({
  type: CREATE_NEW_TASK,
  payload: taskData,
});

export const createNewTask = (
  taskData
) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/create-new-task",taskData);
      dispatch(createNewTaskSuccess(response.data.message));
      // console.log("send email", response.data.message);
      toast.success(response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      // window.location.href = "/taskpage";
    } catch (error) {
      console.log("send eamil errro", error.response.data);
      toast.error(error.response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// update-organization-team-data               todo: update-organization-team-data
export const addNewTeamMemberSuccess = (memberData) => ({
  type: ADD_NEW_TEAM_MEMBER,
  payload: memberData,
});

export const addNewTeamMember = (newMembers) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/update-organization-team-data", {
        team_data: newMembers,
      });
      dispatch(addNewTeamMemberSuccess(response.data.message));
      window.location.reload();
      console.log(response.data);
      // console.log("send email", response.data.message);
      // toast.success(response.data.message.message, {
      //   position: "top-center",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   newestOnTop: false,
      //   closeOnClick: true,
      //   rtl: false,
      //   pauseOnFocusLoss: true,
      //   draggable: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
      // window.location.href = "/taskpage";
    } catch (error) {
      console.log("send eamil errro", error.response.data);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
      // toast.error(error.response.data.message.message, {
      //   position: "top-center",
      //   autoClose: 1000,
      //   hideProgressBar: true,
      //   newestOnTop: false,
      //   closeOnClick: true,
      //   rtl: false,
      //   pauseOnFocusLoss: true,
      //   draggable: true,
      //   pauseOnHover: true,
      //   theme: "light",
      // });
    }
    dispatch(toggleLoader());
  };
};

// fetch organization team data
export const fetchOrganizationTeamData = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/organization-team-data`);

      dispatch({
        type: "FETCH_ORGANIZATION_TEAM_DATA",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// send email action  API --------------------------
export const sendEmailSuccess = (emailData) => ({
  type: SEND_EMAIL,
  payload: emailData,
});

export const sendEmailAction = (to, subject, text, clientId) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/send-email", {
        to,
        subject,
        text,
        clientId,
      });
      dispatch(sendEmailSuccess(response.data.message.message));
      console.log("send email", response.data.message);
      toast.success(response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
    } catch (error) {
      // console.log("send eamil errro", error.response.data.message);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
      toast.error(error.response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
    } finally {
      dispatch(toggleLoader());
    }
  };
};

// update password API action
export const updatePasswordSuccess = (userData) => ({
  type: UPDATE_PASSWORD,
  payload: userData,
});

export const updatePassword = (
  old_password,
  new_password,
  confirm_password
) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/change-password", {
        old_password,
        new_password,
        confirm_password,
      });
      dispatch(updatePasswordSuccess(response.data.message));
      // console.log("updatePasswordResponse", response.data.message.message);
      toast.success(response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      window.location.href = "/homepage";
    } catch (error) {
      // console.log("updatePassword", error.response.data.message.message);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
      toast.error(error.response.data.message.message, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
    }
    dispatch(toggleLoader());
  };
};

// actions for User Profile Setting
export const fetchUserProfilePersonalBasicData = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get("/personal-basic-data");
      dispatch({
        type: FETCH_USER_PROFILE_PERSONAL_BASIC_DATA,
        payload: response.data.message,
      });
    } catch (error) {
      // console.log("upsell-downsell", error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// Active health status graph actions
export const fetchHealthGraphData = (fromDate, toDate) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(
        `/health-status?fromDate=${fromDate}&toDate=${toDate}`
      );
      // console.log("HealthGraphData", response.data.message);
      dispatch({
        type: "FETCH_HEALTH_GRAPH_DATA",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// upsells-downsells actions
export const fetchUpsellsDownsellsData = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get("/upsells-downsells");
      // console.log("upsell-downsell", response.data.message);
      dispatch({
        type: FETCH_UPSELL_DOWNSELL_DATA,
        payload: response.data.message,
      });
    } catch (error) {
      console.log("upsell-downsell", error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// actions for update-organization-general-data
export const updateOrganizationSuccess = (userData) => ({
  type: UPDATE_ORGANOZATION_GENERAL_DATA,
  payload: userData,
});

export const updateOrganizationGeneralData = (
  companyName,
  organizationSize,
  logoUrl
) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/update-organization-general-data", {
        companyName,
        organizationSize,
        logoUrl,
      });

      // console.log("response", response.data);
      dispatch(updateOrganizationSuccess(response.data));
      toast.success("Updated sucessfully", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error("Please make a valid input", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// fetch organization general data API
export const fetchOrganizationGeneralData = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/organization-general-data`);

      dispatch({
        type: "FETCH_ORGANIZATION_DATA",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// fetch product Adoption graph
export const fetchProductAdoptionGraphData = (fromDate, toDate) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(
        `/product-adoption?fromDate=${fromDate}&toDate=${toDate}`
      );

      // console.log("ProductAdoptionGraph", response.data.message);
      dispatch({
        type: "GET_PRODCTION_ADOPTION_GRAPH_DATA",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

// fetch client dashboard data
export const fetchClientDashboardData = (clientType) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(`/client-list?type=${clientType}`);
      dispatch({ type: FETCH_CLIENT_DASHBOARD_DATA, payload: response.data });
      // console.log(response);
    } catch (error) {
      // console.log("client DashboardData error",error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
      dispatch({
        type: FETCH_CLIENT_DASHBOARD_DATA_FAILURE,
        payload: error.message,
      });
    }
    dispatch(toggleLoader());
  };
};

//action for active user graph
export const fetchActiveUserGraphData = (fromDate, toDate) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.get(
        `/active-users?fromDate=${fromDate}&toDate=${toDate}`
      );

      // console.log("activeuserdata", response.data.message);
      dispatch({
        type: "GET_ACTIVE_USER_DATA",
        payload: response.data.message,
      });
    } catch (error) {
      console.log(error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
    }
    dispatch(toggleLoader());
  };
};

//fetch basic home data action
export const fetchBasicHomeData = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());
    dispatch({ type: FETCH_BASIC_HOME_DATA_REQUEST });

    try {
      const response = await api.get("/basic-home-data");
      dispatch({ type: FETCH_BASIC_HOME_DATA_SUCCESS, payload: response.data });
    } catch (error) {
      console.log("homedata", error);
      if (error.response && error.response.status === 401) {
        handleUnauthorized(dispatch);
      }
      dispatch({ type: FETCH_BASIC_HOME_DATA_FAILURE, payload: error.message });
    }
    dispatch(toggleLoader());
  };
};

// action creater for logout user
export const logoutUser = () => ({
  type: "LOGOUT_USER",
});

export const setAuthenticated = (authenticated) => ({
  type: SET_AUTHENTICATED,
  payload: authenticated,
});

export const checkAuthenticated = () => {
  return async (dispatch) => {
    dispatch(toggleLoader());
    let token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const response = await api.get("/basic-home-data", {
          headers: {
            // Set your authorization header here if needed
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        });

        token = JSON.parse(atob(token.split(".")[1]));
        if (token.exp * 1000 < Date.now()) {
          dispatch(setAuthenticated(false));
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        } else {
          // console.log(token.exp)
          dispatch(setAuthenticated(true));
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(setAuthenticated(false));
          localStorage.removeItem("accessToken");
          window.location.href = "/";
        }
      }
    } else {
      dispatch(setAuthenticated(false));
      localStorage.removeItem("accessToken");
      window.location.href = "/";
    }
    // console.log(token);
    dispatch(toggleLoader());
    //check if token is ture
  };
};

// Action Creators for login user
export const loginUserSuccess = (userData) => ({
  type: LOGIN_USER_SUCCESS,
  payload: userData,
});

export const loginUserFailure = (error) => ({
  type: LOGIN_USER_FAILURE,
  payload: error,
});

export const loginUser = (email, password, rememberMe) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/login", {
        email,
        password,
        rememberMe,
      });
      // console.log("response", response);

      if (response.data.message.success === true) {
        localStorage.setItem(
          "accessToken",
          response.data.message.data.accessToken
        );
        dispatch(loginUserSuccess(response.data));
        localStorage.setItem("isTrial", response.data.message.data.isTrial);
        localStorage.setItem("role", response.data.message.data?.role);
        localStorage.setItem(
          "daysRemaining",
          response.data.message.data.daysRemaining
        );
        // console.log(" login successfuly 20 ");
        // console.log(response.data.message.success);
        // toast.success("Login successfully");
        window.location.href = "/homepage";
      } else {
        console.log("erro2 login errro");
        dispatch(loginUserFailure(response.data.message.message));
      }
    } catch (error) {
      console.log("pret", error);
      if (error.response.data.message.message === "Invalid credentials") {
        toast.error("invalid Credentials");
      }

      dispatch(loginUserFailure("An error occurred while logging in."));
    }
    dispatch(toggleLoader());
  };
};

export const loginGoogleUser = (token) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      // console.log(token)
      const response = await api.post("/google-login", {
        idToken: token,
      });
      // console.log("response", response);
      // console.log("logged in with google", token);
      if (response.data.message.success === true) {
        localStorage.setItem(
          "accessToken",
          response.data.message.data.accessToken
        );
        dispatch(loginUserSuccess(response.data));
        console.log(" login successfuly 20 ");
        console.log(response.data.message.success);
        toast.success("Login successfully");
        window.location.href = "/homepage";
      } else {
        console.log("erro2 login errro");
        dispatch(loginUserFailure(response.data.message.message));
      }
    } catch (error) {
      console.log("google login error", error);

      // dispatch(loginUserFailure("An error occurred while logging in."));
    }
    dispatch(toggleLoader());
  };
};

// Action Creators fro register user
export const registerUser = (userData) => {
  return async (dispatch) => {
    dispatch(toggleLoader());

    try {
      const response = await api.post("/signup", userData);
      // console.log(response);

      if (response.data.message.success) {
        // console.log("successfuly");
        localStorage.setItem(
          "accessToken",
          response.data.message.data.accessToken
        );

        dispatch({
          type: REGISTER_USER_SUCCESS,
          payload: response.data.message.message,
        });
      } else {
        console.log("erro2");
        dispatch({
          type: REGISTER_USER_FAILURE,
          payload: response.data.message.message,
        });

        dispatch({
          type: REGISTER_USER_FAILURE,
          payload: response.data.message.message,
        });
      }
    } catch (error) {
      console.log(error);

      dispatch({
        type: REGISTER_USER_FAILURE,
        payload: "username and email are already exist",
      });
    }
    dispatch(toggleLoader());
  };
};
