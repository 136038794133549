import { React, useEffect } from "react";
import "../css/LoginRegister.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import image1 from "../images/ic_google logo.svg";
import image2 from "../images/linkedin 2.png";
import { useState } from "react";
import image5 from "../images/my-image1.jpg";
import showPwdImg from "../images/show-password.svg";
import hidePwdImg from "../images/hide-password.svg";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../ReduxSetup/UserActions";
import { loginGoogleUser } from "../ReduxSetup/UserActions";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import jwtDecode from "jwt-decode";

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginError = useSelector((state) => state.auth);
  const isAuthenticated = useSelector(
    (state) => state.tokenReducer.isAuthenticated
  );
  // console.log(isAuthenticated)

  // console.log("s1", loginError);

  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) =>
      dispatch(loginGoogleUser(tokenResponse.access_token)),
  });

  const handleGoogleLogin = (event) => {
    event.preventDefault();
    login();
  };

  const handleChange = (event) => {
    setRememberMe((current) => !current);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    if (!email || !password) {
      toast.error("Please enter the details first", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        newestOnTop: false,
        closeOnClick: true,
        rtl: false,
        pauseOnFocusLoss: true,
        draggable: true,
        pauseOnHover: true,
        theme: "light",
      });
      return;
    }

    dispatch(loginUser(email, password, rememberMe));
  };

  return (
    <section className="dom">
      {isAuthenticated ? (
        navigate("/homepage")
      ) : (
        <Container className="form-section full-width fluid">
          <Row>
            <Col xxl={6} xl={6} md={6} sm={12} xs={12} className="image1">
              <div className="image-12">
                <img
                  src={image5}
                  width={750}
                  height={700}
                  className="img-fluid"
                  alt="laptop"
                />
              </div>
            </Col>

            <Col
              xxl={6}
              xl={6}
              md={6}
              sm={12}
              xs={12}
              className="form-template ps-lg-5"
            >
              {/* login form start here */}

              <Form className="form-1" onSubmit={handleLogin}>
                <h3 className="heading-form">Login text here</h3>
                <Form.Group as={Row} className="mb-0">
                  <Form.Label column sm="12" className="lable-text">
                    Email Address<span className="text-span">*</span>
                  </Form.Label>
                  <Col sm="12">
                    <Form.Control
                      className="p-3"
                      type="email"
                      placeholder="email@example.com"
                      id="show-hide-email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-1">
                  <Form.Label column sm="12" className="lable-text">
                    Password<span className="text-span">*</span>
                  </Form.Label>
                  <br />
                  <Col sm="12">
                    <Form.Control
                      className="p-3"
                      type={isRevealPwd ? "text" : "password"}
                      id="show_hide_password"
                      name="pwd"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <img
                      width={20}
                      height={20}
                      title={isRevealPwd ? "Hide password" : "Show password"}
                      src={isRevealPwd ? hidePwdImg : showPwdImg}
                      onClick={() => setIsRevealPwd((prevState) => !prevState)}
                      className="hide-unhide"
                    />
                  </Col>
                </Form.Group>
                <Row className="form-foget-btn-1 my-3">
                  <Col xxl={7} xl={7} lg={7} md={6} sm={6} xs={6}>
                    <p className="box">
                      {" "}
                      <Form.Check
                        type="checkbox"
                        value={rememberMe}
                        onChange={handleChange}
                        id="inputPassword5"
                        aria-describedby="passwordHelpBlock"
                        label="Stay Signed In For A Week"
                      />
                    </p>
                  </Col>

                  <Col xxl={5} xl={5} lg={5} md={6} sm={6} xs={6}>
                    <a
                      className="forget-btn btn btn-outline-light text-black"
                      href="https://www.w3schools.com/"
                    >
                      Forget Password
                    </a>{" "}
                    <b />
                  </Col>
                </Row>
                <Row>
                  <button className="btn-form ">Login </button>

                  {/* <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <Link to="/registration" className="link-tag">
                    {" "}
                    <button className="btn-form ms-1" type="submit">SignUp </button>
                  </Link>git 
                </Col> */}
                </Row>
                <p className="divider">or</p>
                <br />
                <Row>
                  <Col
                    xxl={12}
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="loagin-google-linkdin"
                  >
                    <Link className="loginWithGoogleBUttonSetting" to="/">
                      <button
                        className="google-btn-form me-2"
                        onClick={(e) => handleGoogleLogin(e)}
                      >
                        {" "}
                        <img
                          src={image1}
                          width={16}
                          height={16}
                          className="img-fluid1 me-1"
                          alt="dog"
                        />
                        Login with google{" "}
                      </button>
                    </Link>
                  </Col>

                  {/* <Col xxl={6} xl={6} lg={6} md={6} sm={6} xs={6}>
                  <a href="#">
                    <button className="google-btn-form">
                      {" "}
                      <img
                        src={image2}
                        width={16}
                        height={16}
                        className="img-fluid1 me-1"
                        alt="dog"
                      />
                      Login with Linkedin{" "}
                    </button>
                  </a>
                </Col> */}
                </Row>
              </Form>

              {/* login form start here */}

              <div className="footer-line">
                <div className="text-footer mt-2">
                  © 2023 ViaSucess. All rights reserved.
                </div>
                <p>
                  <a className="term-condition btn" href="#">
                    Terms & Conditions
                  </a>
                </p>
                <p>
                  <a className="privacy-policy btn" href="#">
                    Privacy Policy
                  </a>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      )}
    </section>
  );
}

export default LoginPage;
