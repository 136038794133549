import React from 'react'
import Task from '../components/Task'
import { Container } from 'react-bootstrap'

const TaskPage = () => {
  return (
    <div className='taskpage-slide'>
      <Container>
      <Task/>
      </Container>
    </div>
  )
}

export default TaskPage
