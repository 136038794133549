// css Aapp .css file line 1886 starts.

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useSelector, useDispatch } from "react-redux";
import { verifyConnectorsAction } from "../ReduxSetup/UserActions";
import { useGoogleLogin } from "@react-oauth/google";
import React, { useEffect, useState } from "react";

const MyVerticalModal = (props) => {
  // Initialize state to store the values for each key
  const [keyValues, setKeyValues] = useState({});

  // Function to handle changes for a specific key
  const handleKeyChange = (key, value) => {
    setKeyValues({ ...keyValues, [key]: value });
  };

  const dispatch = useDispatch();

  const verifyConnectors = useSelector(
    (state) => state.fetchVerifyConnectorsReducer
  );

  // console.log("preet-connectors", verifyConnectors);

  function formatKey(key) {
    // Split the string by underscores and capitalize the first letter of each word
    const words = key.split("_").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    // Join the words with spaces
    return words.join(" ");
  }

  // useEffect(() => {
  //   dispatch(verifyConnectorsAction(5, 'rzp_test_29J0TzxrNStxV3', 'aCrCr4GejLe3I0R96pgUm4yz'));
  // }, [dispatch]);

  // console.log(props.organizationDatas);
  // console.log(props.requiredKeys);

  const handleToken = (e) => {
    // console.log(e);
    // setKeyValues((prevKeyValues) => ({
    //   ...prevKeyValues,
    //   access_token: e?.code || "",
    // }));
    let arrayOfObjects = [
      {
        access_token: e?.code,
      },
    ];
    dispatch(verifyConnectorsAction(8, arrayOfObjects));
  };

  const handleGmailToken = (e) => {
    // console.log(e);
    let arrayOfObjects = [
      {
        access_token: e?.code,
      },
    ];
    dispatch(verifyConnectorsAction(4, arrayOfObjects));
  };

  const login = useGoogleLogin({
    onSuccess: handleToken,
  });

  const loginGmail = useGoogleLogin({
    onSuccess: handleGmailToken,
    scope: "https://mail.google.com/",
    flow: "auth-code",
  });

  // const handleGoogleLogin = event => {
  //   login();
  // };

  const handleConnectorData = (e) => {
    e.preventDefault();

    if (props.connectorId === 8) {
      window.location.href = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?client_id=1077243870382-14f81fsilbvohvbd77go9bpmf7r9mb18.apps.googleusercontent.com&redirect_uri=${process.env.REACT_APP_GOOGLE_URL}user-profile&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fcalendar.readonly&response_type=code&access_type=offline&service=lso&o2v=1&theme=glif&flowName=GeneralOAuthFlow`;
      // login();
    } else if (props.connectorId === 4) {
      loginGmail();
    } else {
      const arrayOfObjects = [];

      // Loop through the key-value pairs and push each pair as an object to the array
      for (const key in keyValues) {
        if (keyValues.hasOwnProperty(key)) {
          const obj = {};
          obj[key] = keyValues[key];
          arrayOfObjects.push(obj);
        }
      }
      dispatch(verifyConnectorsAction(props.connectorId, arrayOfObjects));
    }
  };

  useEffect(() => {
    if (verifyConnectors?.userData?.message?.success) {
      window.location.reload();
    }
  }, [verifyConnectors]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <img
            src={props.organizatioDataLogo}
            width={20}
            height={20}
            className="claincoaster-integration-im  g-fluid-4"
            alt="dog"
          />
          {props.organizationDatas}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleConnectorData}>
          {props.requiredKeys?.map((key, index) => (
            <Form.Group
              as={Row}
              className="mb-3 custom-label"
              controlId={`formKey${index}`}
              key={index}
            >
              <Form.Label column sm="2">
                {formatKey(key)}
              </Form.Label>
              <Col sm="10 custom-field">
                <Form.Control
                  type="text"
                  placeholder={`Enter ${formatKey(key)}`}
                  value={keyValues[key] || ""}
                  onChange={(e) => handleKeyChange(key, e.target.value)}
                />
              </Col>
            </Form.Group>
          ))}
          <Button type="submit">Connect Now</Button>
          {/* <Button onClick={() => handleGoogleLogin()}>Connect Now</Button> */}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button className="modal_connect_button" onClick={props.onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MyVerticalModal;
