import React from 'react'
import '../css/Loader.css'
import logo from '../images/header-logo.png'

const Loader = () => {
  return (
    <div className='loading-screen'>
        <div className="">
            <img src={logo}/>
        </div>
        
    </div>
  )
}

export default Loader