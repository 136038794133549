import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegPenToSquare } from "react-icons/fa6";
import "../css/ContactPopup.css";
import { IoIosArrowForward } from "react-icons/io";
import { HiOutlineHome } from "react-icons/hi2";
import { CgProfile } from "react-icons/cg";
import { TiContacts } from "react-icons/ti";
import { Button } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import { useParams } from "react-router";
import { fetchAllPocContacts } from "../ReduxSetup/UserActions";
import "animate.css";
import ContactDetailPopup from "../components/ContactDetailPopup";

const ContactPage = () => {
  const ContactDetailss = useSelector(
    (state) => state?.fetchClientWisePocContactsReducer?.userData
  );
  const { clientId } = useParams();
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState(null);

  const handleOpenPopup = (detail) => {
    setShowPopup(true);
    setPopupData(detail);
  };

  useEffect(() => {
    dispatch(fetchAllPocContacts(clientId));
  }, [dispatch]);

  return (
    <div className="container my-4">
      {showPopup && (
        <ContactDetailPopup
          detail={popupData}
          onClose={() => setShowPopup(false)}
        />
      )}
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center my-2 ms-5">
          <span className="mx-2 font9 d-flex align-items-center">
            <HiOutlineHome className="fs-5 mx-1" /> Home
          </span>
          <IoIosArrowForward className="fs-5 text-secondary" />
          <span className="mx-1 text-success font9 d-flex align-items-center">
            <CgProfile className="fs-5 mx-1" /> Client Dashboard
          </span>
          <IoIosArrowForward className="fs-5 text-secondary" />
          <span className="mx-1 text-success font9 d-flex align-items-center">
            <TiContacts className="fs-5 mx-1" /> POC Clients Contact
          </span>
        </div>
        <Button
          className="bg-secondary d-flex align-items-center border-0"
          onClick={() => handleOpenPopup([])}
        >
          <FaPlus className="me-2" /> Add New
        </Button>
      </div>
      <div className="tableContainer">
        <div className="tableContainer2">
          <table className="styledTable">
            <thead>
              <tr>
                <th className="fs-6">S No.</th>
                <th className="fs-6">POC name</th>
                <th className="fs-6">designation</th>
                <th className="fs-6">email</th>
                <th className="fs-6">contact</th>
              </tr>
            </thead>
            <tbody>
              {/* actuall cient detail starts ------------------------------------------------------------------------- */}
              {ContactDetailss?.map((item, index) => {
                return (
                  <tr key={item}>
                    <td className="fs-6">{index + 1}</td>
                    <td className="fs-6">{item.contact_name}</td>
                    <td className="fs-6">{item.designation}</td>
                    <td className="fs-6">{item.email}</td>
                    <td className="fs-6">{item.contact_number}</td>
                    <td>
                      <FaRegPenToSquare
                        role="button"
                        onClick={() => handleOpenPopup(item)}
                        className="fs-5"
                      />
                    </td>
                  </tr>
                );
              })}
              {/* end------------------------------------------------------------------------------------------------------------- */}
            </tbody>
          </table>
        </div>
        {/* Pagination Controls */}
        <div className="page-func-outer mx-5">
          <div className="pagination">
            <button
            //   onClick={() => paginate(currentPage - 1)}
            //   disabled={currentPage === 1}
            >
              Previous
            </button>
            {Array.from({ length: 2 }, (_, idx) => idx + 1).map((page) => (
              <button
                key={page}
                //   onClick={() => paginate(page)}
                //   className={
                //     currentPage === page ? "bg-success text-white" : ""
                //   }
              >
                {page}
              </button>
            ))}
            <button
            //   onClick={() => paginate(currentPage + 1)}
            //   disabled={currentPage === totalPage}
            >
              Next
            </button>
          </div>
          <div className="rowControl">
            <span>
              {/* Showing {indexOfFirstRow + 1} to {indexOfLastRow} of {totalRows}{" "} */}
              entries
            </span>
            <label>Show </label>
            <input
              type="number"
              value={1}
              //   onChange={(e) => {
              //     let value = parseInt(e.target.value, 10);
              //     if (!value || value <= 0) {
              //       value = 8;
              //     }
              //     setNumRows(value);
              //   }}
              className="numRowsInput"
              min="1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
