import React, { useState, useEffect, useRef } from "react";
import { AiOutlineBell } from "react-icons/ai";
import { AiOutlineUser } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";
import { CgOrganisation } from "react-icons/cg";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { AiOutlineLogout } from "react-icons/ai";
import { MdArrowDropDown } from "react-icons/md";
import HeaderLogo from "../images/header-logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser, fetchUnreadNF } from "../ReduxSetup/UserActions";
// import { logoutUserApi } from "../ReduxSetup/UserActions"
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { MdOutlineArchive } from "react-icons/md";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { LuBellDot } from "react-icons/lu";
import "../css/header.css";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isScrolled, setScrolled] = useState(false);

  // const userDataIsTrialOn = useSelector((state) => {
  //   if (state.authReducer) console.log(state.authReducer)
  // })

  const handleLogout = async () => {
    const accessToken = localStorage.getItem("accessToken");

    // Make an API call to log out the user
    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logout`, null, {
        headers: {
          // Set your authorization header here if needed
          Authorization: `Bearer ${accessToken}`,
        },
      });

      dispatch(logoutUser());
      localStorage.removeItem("accessToken");
    } catch (error) {
      console.log("header", error);
    }

    // Use replaceState to remove the ability to go back
    window.history.replaceState(null, null, "/");

    toast.success("Logout successful", {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      newestOnTop: false,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: true,
      draggable: true,
      pauseOnHover: true,
      theme: "light",
    });

    navigate("/");
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // Notification functions
  const handleOpenNF = () => {
    const tempData = [
      {
        notification_id: 1,
        user_id: 191,
        title: "Task Reminder - abc",
        message: "abc",
        timestamp: "2024-03-04T20:57:07.491Z",
        is_read: false,
        deleted: false,
        type: "task",
        type_id: 96,
      },
      {
        notification_id: 7,
        user_id: 191,
        title: "Task Reminder - abc",
        message: "abc",
        timestamp: "2024-03-04T20:57:07.491Z",
        is_read: false,
        deleted: false,
        type: "task",
        type_id: 96,
      },
      {
        notification_id: 17,
        user_id: 191,
        title: "Task Reminder - abc",
        message: "abc",
        timestamp: "2024-03-04T20:57:07.491Z",
        is_read: false,
        deleted: false,
        type: "task",
        type_id: 96,
      },
      {
        notification_id: 18,
        user_id: 191,
        title: "Task Reminder - abc",
        message: "abc",
        timestamp: "2024-03-04T20:57:07.491Z",
        is_read: false,
        deleted: false,
        type: "task",
        type_id: 96,
      },
      {
        notification_id: 19,
        user_id: 191,
        title: "Task Reminder - abc",
        message: "abc",
        timestamp: "2024-03-04T20:57:07.491Z",
        is_read: false,
        deleted: false,
        type: "task",
        type_id: 96,
      },
      {
        notification_id: 20,
        user_id: 191,
        title: "Task Reminder - abc",
        message: "abc",
        timestamp: "2024-03-04T20:57:07.491Z",
        is_read: false,
        deleted: false,
        type: "task",
        type_id: 96,
      },
      {
        notification_id: 21,
        user_id: 191,
        title: "Task Reminder - abc",
        message: "abc",
        timestamp: "2024-03-04T20:57:07.491Z",
        is_read: false,
        deleted: false,
        type: "task",
        type_id: 96,
      },
    ];

    setNotification(tempData);
  };

  useEffect(() => {
    dispatch(fetchUnreadNF());
  }, [dispatch]);

  const notificationsReducer = useSelector((state) => {
    if (
      state.notificationsReducer &&
      state.notificationsReducer.userData &&
      state.notificationsReducer.userData.data
    ) {
      return state.notificationsReducer.userData.data;
    }
    return [];
  });
  const [notification, setNotification] = useState(notificationsReducer || []);

  //Tawk functions
  const loadTawkToScript = () => {
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = process.env.REACT_APP_TAWK_URL;
    s1.charset = "UTF-8";
    s1.setAttribute("crossorigin", "*");
    s0.parentNode.insertBefore(s1, s0);
  };
  const handleTawk = () => {
    if (window.Tawk_API.isChatMaximized()) {
      window.Tawk_API.minimize();
      window.Tawk_API.shutdown();
    } else {
      window.Tawk_API.maximize();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Load Tawk.to script when component mounts
    loadTawkToScript();
    // window.Tawk_API.shutdown();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 300) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={`header-outer  ${isScrolled ? "nav-fix" : ""}`}>
      <div className={`container`}>
        <div className="header">
          <div className="header-left">
            <img src={HeaderLogo} alt="HeaderLogo" />
          </div>

          <div className="header-right">
            {localStorage.getItem("isTrial") === "true" ? (
              <div className="bg-secondary px-2 py-1 text-white rounded">
                {" "}
                Trial Version: {localStorage.getItem("daysRemaining")} Days
                Remaining
              </div>
            ) : (
              ""
            )}

            <Button
              onClick={handleTawk}
              variant=""
              className="shadow-sm bg-white"
            >
              Talk To Us
            </Button>

            {/* Notification section */}
            <OverlayTrigger
              trigger="click"
              key={"bottom"}
              placement={"bottom"}
              overlay={
                <Popover id={`popover-positioned-bottom`} className="nf-box">
                  <Popover.Header className="d-flex justify-content-between my-0">
                    <p className="font-normal fs-5 my-0">Notifications</p>
                    <p className="font9 my-0 text-secondary hover-darkgray cursor-pointer">
                      Mark All as Read
                    </p>
                  </Popover.Header>
                  {notification?.map((item, index) => {
                    return (
                      <React.Fragment key={item.title + index}>
                        <Popover.Body className="hover-gray border border-bottom-2 cursor-pointer">
                          <div className="d-flex justify-content-between">
                            <div>
                              <h6>{item.title}</h6>
                              <p>{item.message}</p>
                            </div>
                            <div>
                              <p className="text-primary fs-4">•</p>
                            </div>
                          </div>
                        </Popover.Body>
                      </React.Fragment>
                    );
                  })}
                </Popover>
              }
            >
              <Button variant="" onClick={handleOpenNF}>
                {notification.length < 1 ? (
                  <AiOutlineBell className="bell-icon" />
                ) : (
                  <LuBellDot className="bell-icon text-secondary" />
                )}
              </Button>
            </OverlayTrigger>
            {/* <AiOutlineBell className="bell-icon" onClick={handleOpenNF} /> */}

            <button className="dropdown-btn" onClick={toggleDropdown}>
              <AiOutlineUser />
              <MdArrowDropDown className="downarrow-btn" />
            </button>
            {isDropdownOpen && (
              <div
                className="dropdown-content"
                ref={dropdownRef}
                onClick={toggleDropdown}
              >
                <Link to="/user-profile">
                  <BiUserCircle /> Profile Settings
                </Link>
                <Link to="/archived-clients">
                  <MdOutlineArchive /> Archived Clients
                </Link>
                {localStorage.getItem("role") === "admin" ? (
                  <>
                    <Link to="/organization-setting">
                      <CgOrganisation /> Organizational Settings
                    </Link>
                    <a href="">
                      <AiOutlineDollarCircle /> Billing & Subscriptions
                    </a>
                  </>
                ) : (
                  ""
                )}
                <a style={{ color: "red" }} onClick={handleLogout}>
                  <AiOutlineLogout /> Log Out
                </a>
                <a href="">Privacy Policy</a>
                <a href="https://viasuccess.tawk.help/" target="_blank">
                  Help Desk
                </a>
                <a href="">About</a>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
