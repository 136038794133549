import React, { useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import image2 from "../images/building.svg";
import image4 from "../images/claincoaster.2.svg";
// import image3 from "../images/claincoaster.1.png";
// import image5 from "../images/claincoaster-zapier.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// import TagsInput from "../components/TagsInput";
import { useState } from "react";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";

import "../css/Organization.css";
import { BsChevronRight } from "react-icons/bs";
import { RxCrossCircled } from "react-icons/rx";
import image1 from "../images/Vector.svg";
import { FaPlusCircle } from "react-icons/fa";
// import { BiSolidChevronRight } from "react-icons/bi";
// import image6 from "../images/claincoaster-img -1.png";
// import image7 from "../images/claincoaster-img-2.png";
// import image8 from "../images/claincoaster-img-3.png";
import AWS from "aws-sdk";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewTeamMember,
  deleteTeamMember,
  fetchAllUsers,
  fetchOrganizationGeneralData,
} from "../ReduxSetup/UserActions";
import { updateOrganizationGeneralData } from "../ReduxSetup/UserActions";
import { fetchOrganizationTeamData } from "../ReduxSetup/UserActions";
import { fetchOrganizationIntegrationData } from "../ReduxSetup/UserActions";
import { disconnectConnector } from "../ReduxSetup/UserActions";
import MyVerticalModal from "./MyVerticalModal";

export default function Claincoaster() {
  const [modalShow, setModalShow] = useState(false);
  const [organizationDatas, setOrganizationDatas] = useState(null);
  const [organizatioDataLogo, setOrganizatioDataLogo] = useState(null);
  const [connectorId, setConnectorId] = useState("");
  const [requiredKeys, setRequiredKeys] = useState(null);
  // const [disconnectState, setDisconnectState] = useState(null);

  const dispatch = useDispatch();
  const organizationGeneralData = useSelector(
    (state) => state.fetchOrganizationGeneralReducer.dataUser?.data[0]
  );
  // console.log(organizationGeneralData)

  // const updateOrganizationGeneralDataReducer = useSelector(
  //   (state) => state.updateOrganizationGeneralDataReducer
  // );
  // console.log("up", updateOrganizationGeneralDataReducer);
  // console.log("pip", organizationGeneralData);

  // team data useSelector
  const fetchTeamData = useSelector(
    (state) => state.fetchOrganizationTeamDataReducer.userData?.data
  );
  // console.log("fetchTeamData" , fetchTeamData)

  // team data useSelector
  const fetchDisconnectionInfo = useSelector(
    (state) => state.fetchConnectorDisconnectedReducer?.userData
  );

  const fetchAllOrganizationData = useSelector((state) => {
    if (
      state.fetchOrganizationIntgrationDataReducer &&
      state.fetchOrganizationIntgrationDataReducer.userData &&
      state.fetchOrganizationIntgrationDataReducer.userData.data
    ) {
      return state.fetchOrganizationIntgrationDataReducer.userData.data;
    } else {
      return null;
    }
  });
  // console.log(fetchAllOrganizationData);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchData = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredData = fetchAllOrganizationData?.filter((organization) => {
    const connectorType = organization.type;
    const connectorName = organization.connector_name;

    // Check if connectorType includes "personal" and connectorName includes searchTerm
    if (
      typeof connectorType === "string" &&
      typeof connectorName === "string"
    ) {
      return (
        connectorType.toLowerCase().includes("organization") &&
        connectorName.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return false; // Ignore non-string properties
  });

  const handleConnect = async (connectionStatus, id) => {
    if (connectionStatus === "not_connected") {
      setModalShow(true);
    } else {
      try {
        await dispatch(disconnectConnector(id));
      } catch (error) {
        console.error("Error disconnecting:", error);
        // Handle the error, if needed
      }
    }
  };

  useEffect(() => {
    if (fetchDisconnectionInfo) {
      toast.success("Disconnected");
      window.location.reload();
    }
  }, [fetchDisconnectionInfo]);

  // console.log(filteredData);

  // useEffect(() => {
  //   dispatch(fetchOrganizationIntegrationData());
  // }, [fetchAllOrganizationData]);

  useEffect(() => {
    dispatch(fetchOrganizationGeneralData());
    dispatch(fetchOrganizationTeamData());
    dispatch(fetchOrganizationIntegrationData());
  }, [dispatch]);

  useEffect(() => {
    // Set companyName with the value from organizationGeneralData when it's available.
    if (
      organizationGeneralData &&
      organizationGeneralData.company_name &&
      organizationGeneralData.organization_size &&
      organizationGeneralData.company_logo
    ) {
      setCompanyName(organizationGeneralData.company_name);
      setOrganizationSize(organizationGeneralData.organization_size);
      setLogo(organizationGeneralData.company_logo);
    }
  }, [organizationGeneralData]);

  // let company_name = organizationGeneralData?.company_name;
  // console.log(company_name)

  const [activeContent, setActiveContent] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [organizationSize, setOrganizationSize] = useState("");
  const [logoBase64, setLogo] = useState(null);

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function getCurrentDateTime() {
    const now = new Date();
    const date = now.getDate();
    const month = now.getMonth() + 1; // Month is zero-based
    const year = now.getFullYear();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    const formattedDate = `${date}-${month}-${year}`;
    const formattedTime = `${hours}-${minutes}-${seconds}`;

    return `${formattedDate}_${formattedTime}`;
  }

  const handleProfilePicChange = (e) => {
    e.preventDefault();

    AWS.config.update({
      accessKeyId: "AKIAUMDTMLYSMADRTYHU",
      secretAccessKey: "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",
      region: "ap-south-1",
    });

    const s3 = new AWS.S3();
    const file = e.target.files[0];
    const currentTime = getCurrentDateTime()
    if (file) {
      // Define S3 upload parameters
      const params = {
        Bucket: "viasuccess",
        Key: `Photos/profile_${currentTime}.png`,
        Body: file,
        ACL: "public-read", // Set appropriate access permissions
      };

      // Upload the image to S3
      s3.upload(params, (err, data) => {
        if (err) {
          console.error("Error uploading image:", err);
        } else {
          setLogo(data.Location);
          dispatch(
            updateOrganizationGeneralData(
              organizationGeneralData.company_name,
              organizationGeneralData.organization_size,
              logoBase64
            )
          );
          console.log("Image uploaded successfully:", data.Location);
        }
      });
    }
  };

  // const getBase64 = (file) => {
  //   return new Promise((resolve) => {
  //     let baseURL = "";
  //     // Make new FileReader
  //     let reader = new FileReader();

  //     // Convert the file to base64 text
  //     reader.readAsDataURL(file);

  //     // on reader load somthing...
  //     reader.onload = () => {
  //       // Make a fileInfo Object
  //       baseURL = reader.result;
  //       resolve(baseURL);
  //     };
  //   });
  // };

  // const handleChange = (e) => {
  //   console.log(e.target.files[0]);
  //   let file = e.target.files[0];

  //   getBase64(file)
  //     .then((result) => {
  //       setLogo(result);
  //       console.log(result);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const handleChange = (e) => {
  //   e.preventDefault();

  // }

  // ------------------Team Data change------------------

  // useEffect(() => {
  //   if (fetchTeamData) {
  //     setTeamData(fetchTeamData);
  //   }
  // }, [fetchTeamData]);

  // const [memberName, setmemberName] = useState("");
  // const [memberRole, setmemberRole] = useState("");
  const [newMembers, setNewMembers] = useState([{ name: "", email: "" }]);
  // const [newMemberCount, setNewMemberCount] = useState(1);

  const handleMemberChange = (index, key, value) => {
    const updatedMembers = [...newMembers];
    updatedMembers[index][key] = value;
    setNewMembers(updatedMembers);
  };

  const addNewMember = () => {
    setNewMembers([...newMembers, { name: "", email: "" }]);
  };

  const handleDeleteEntry = (index) => {
    setNewMembers((prevMembers) => {
      const updatedMembers = [...prevMembers];
      updatedMembers.splice(index, 1); // Remove the entry at the specified index
      return updatedMembers;
    });
  };

  const handleNewTeamMember = (e) => {
    e.preventDefault();
    const isEmptyEntry = newMembers.some(
      (item) => item.name === "" || item.email === ""
    );
    if (isEmptyEntry) {
      toast.warning("Please Fill all Entries");
      return;
    }
    dispatch(addNewTeamMember(newMembers));
    setNewMembers([{ name: "", email: "" }]);
  };

  const handleDeleteMember = (e, d) => {
    e.preventDefault();
    if (window.confirm(`Are you sure to delete ${d.name}`)) {
      dispatch(deleteTeamMember(d.user_id));
    }
  };

  // const handleInputChange = (field, value) => {
  //   // Find the index of the user in the array
  // };

  //handle adding new member to array

  // const handleNewTeamMember = (e) => {
  //   e.preventDefault();
  //   const tempObj = {
  //     name: memberName,
  //     email: memberRole,
  //   };

  //   setNewMembers([...newMembers, tempObj]);
  //   setmemberName("");
  //   setmemberRole("");
  // };
  // const handleTeamDataChange = (e) => {
  //   e.preventDefault();

  //   if (memberName || memberRole) {
  //     handleNewTeamMember();
  //   }

  //   dispatch(addNewTeamMember(newMembers));
  //   window.location.reload();
  //   // console.log(newMembers);
  // };
  // --------------------
  const toggleeContent = (index) => {
    setActiveContent(activeContent === index ? index : index);
  };

  const handleorganizationDataValue = (e) => {
    e.preventDefault();
    // console.log(companyName);
    // console.log(organizationSize);
    // console.log(logoBase64);
    dispatch(
      updateOrganizationGeneralData(companyName, organizationSize, logoBase64)
    );
  };

  useEffect(()=>{
    if(localStorage.getItem('role') !== "admin"){
      window.location.href = "/";
    }
  })

  return (
    <div className="custom-width">
      <Container>
        <Row>
          <Col className="claincoaster-col mt-5">
            <p>
              <img
                src={image1}
                width={16}
                height={16}
                className="claincoaster-img-fluid-1 me-1"
                alt="dog"
              />

              <span className="claincoaster-span-1 me-3">Setting</span>
              <BsChevronRight className="claincoaster-icon" />
              <img
                src={image2}
                width={16}
                height={16}
                className="claincoaster-img-fluid-2 ms-3 me-1"
                alt="dog"
              />
              <span className="claincoaster-span-2 ">
                Organizational Settings
              </span>
            </p>
          </Col>
        </Row>

        <Row>
          <Col
            xxl={3}
            xl={3}
            lg={3}
            md={12}
            sm={12}
            className="claincoaster-col-1 text-center mt-4"
          >
            <div className="claincoaster-div my-2 mx-auto d-flex justify-content-center align-items-center">
              <img
                src={organizationGeneralData?.company_logo || ""}
                width={100}
                height={100}
                className="rounded-circle claincoaster-img-fluid-3"
                alt="profile logo"
              />
              <img
                src={image4}
                style={{ cursor: "pointer" }}
                width={20}
                height={20}
                className="claincoaster-img-fluid-4"
                alt="pen logo"
                onClick={handleClick}
                value={logoBase64}
              />
              {/* <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: "none" }}
              /> */}
            </div>
            <p className="claincoaster-text-col-3 ms-1">
              {organizationGeneralData?.company_name || ""}
            </p>
            <p className="claincoaster-text-col-3-1">
              {organizationGeneralData?.company_type || ""}
            </p>
          </Col>
          <Col
            xxl={9}
            xl={9}
            lg={9}
            md={12}
            sm={12}
            className="claincoaster-col-2 mt-4 px-4"
          >
            <div className="claincoaster-div-2 pt-5 bg-white rounded">
              <div className="mb-3">
                <a
                  className={`claincoaster-btn-1 mx-4 fs-5 ${
                    activeContent === 0 ? "active-field" : "text-dark"
                  }`}
                  onClick={() => toggleeContent(0)}
                >
                  General
                </a>
                <a
                  className={`claincoaster-btn-2 mx-4 fs-5 ${
                    activeContent === 1 ? "active-field" : "text-dark"
                  }`}
                  onClick={() => toggleeContent(1)}
                >
                  Team
                </a>
                <a
                  className={`claincoaster-btn-2 text-2 mx-5 fs-5 ${
                    activeContent === 2 ? "active-field" : "text-dark"
                  }`}
                  onClick={() => toggleeContent(2)}
                >
                  Integration
                </a>
              </div>

              {/* organization-general-data API  */}
              <hr className="claincoaster-line mx-3"></hr>
              {activeContent === 0 && (
                <div className="claincoaster-general-div m-4 p-4">
                  <p className="claincoaster-general-heading ms-2">
                    Organization Profile
                  </p>
                  <hr className="claincoaster-line-1 mx-2"></hr>

                  {/* general section starts ------------------------------------------------------------------------------------------*/}
                  <Form onSubmit={handleorganizationDataValue}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      {/* <img
                        src={image4}
                        style={{ cursor: "pointer" }}
                        width={20}
                        height={20}
                        className="claincoaster-img-fluid-4"
                        alt="dog"
                        onClick={handleClick}
                        value={logoBase64}
                       
                      /> */}
                      <input
                        type="file"
                        onChange={handleProfilePicChange}
                        ref={hiddenFileInput}
                        style={{ display: "none" }}
                      />

                      <Form.Label className="claincoaster-general-label">
                        Company Name *
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="claincoaster-general-input py-3"
                        placeholder="Company name"
                        value={companyName}
                        onChange={(e) => {
                          setCompanyName(e.target.value);
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3 "
                      controlId="exampleForm.ControlInput1"
                    >
                      <Col xxl={4}>
                        <Form.Label className="claincoaster-general-label">
                          Organization size *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          className="claincoaster-general-input py-3"
                          placeholder="Company name"
                          value={organizationSize}
                          onChange={(e) => {
                            setOrganizationSize(e.target.value);
                          }}
                        />
                      </Col>
                    </Form.Group>
                    <Col
                      xxl={12}
                      className="claincoaster-general-btn-group text-center"
                    >
                      <Button className="claincoaster-general-btn mx-2">
                        Cancel
                      </Button>
                      <Button
                        className="claincoaster-general-btn-1 mx-2"
                        type="submit"
                      >
                        Save Changes
                      </Button>
                    </Col>
                  </Form>
                </div>
              )}

              {/*Team profile section starts ------------------------------------------------------------------------------------------  */}
              {activeContent === 1 && (
                <div className="claincoaster-general-div m-4 p-4">
                  {/* form section input field starts ---------------------------------------------------------------- */}
                  <Form onSubmit={handleNewTeamMember}>
                    <Row>
                      <p className="claincoaster-general-heading ms-2">
                        Team Profile
                      </p>
                      <hr className="claincoaster-line-1 mx-2"></hr>

                      {/* section to be duplicated --------------------------------------------------------------------- */}
                      {fetchTeamData?.map((data) => (
                        <React.Fragment key={data.user_id}>
                          <Col xxl={6}>
                            <Form.Group
                              className="mb-3"
                              controlId={`name-${data.user_id}`}
                            >
                              <Form.Label className="claincoaster-general-label">
                                Name of Team Member *{" "}
                                <RxCrossCircled
                                  onClick={(e) => handleDeleteMember(e, data)}
                                  className="text-danger fs-4"
                                />
                              </Form.Label>

                              <Form.Control
                                type="text"
                                className="claincoaster-general-input py-3"
                                placeholder="Name of Team Member"
                                value={data.name || ""}
                              />
                            </Form.Group>
                            <Form.Group
                              className="mb-3 "
                              controlId={`role-${data.user_id}`}
                            ></Form.Group>
                          </Col>
                          <Col xxl={6}>
                            <Form.Group
                              className="mb-3 "
                              controlId={`role-${data.user_id}`}
                            >
                              <Form.Label className="claincoaster-general-label">
                                Email *
                              </Form.Label>
                              <Form.Control
                                type="email"
                                className="claincoaster-general-input py-3"
                                placeholder="Email"
                                value={data.email || ""}
                              />
                            </Form.Group>

                            <Form.Group
                              className="mb-3 "
                              controlId={`role-${data.user_id}`}
                            ></Form.Group>
                          </Col>
                        </React.Fragment>
                      ))}

                      {/* Fragment to add Team member */}
                      <h3 className="text-warning">
                        Add New Member{" "}
                        <span className="text-secondary" onClick={addNewMember}>
                          <FaPlusCircle />
                        </span>
                      </h3>

                      {/* {[...Array(newMemberCount)].map((_,index) => {
                        return ( */}
                      {newMembers.map((member, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Col xxl={6}>
                              <Form.Group
                                className="mb-3"
                                controlId={`name-${1}`}
                              >
                                <Form.Label className="claincoaster-general-label">
                                  Name of Team Member *{" "}
                                  <RxCrossCircled
                                    onClick={() => handleDeleteEntry(index)}
                                    className="text-danger fs-4"
                                  />
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  className="claincoaster-general-input py-3"
                                  placeholder="Name of Team Member"
                                  value={member.name}
                                  onChange={(e) =>
                                    handleMemberChange(
                                      index,
                                      "name",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>
                              <Form.Group
                                className="mb-3 "
                                controlId={`role-${1}`}
                              ></Form.Group>
                            </Col>
                            <Col xxl={6}>
                              <Form.Group
                                className="mb-3 "
                                controlId={`role-${1}`}
                              >
                                <Form.Label className="claincoaster-general-label">
                                  Email *
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  className="claincoaster-general-input py-3"
                                  placeholder="Email"
                                  value={member.email}
                                  onChange={(e) =>
                                    handleMemberChange(
                                      index,
                                      "email",
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Group>

                              <Form.Group
                                className="mb-3 "
                                controlId={`role-${1}`}
                              ></Form.Group>
                            </Col>
                          </React.Fragment>
                        );
                      })}
                      {/* ); */}
                      {/* })} */}

                      {/* section to be duplicated --------------------------------------------------------------------- */}
                    </Row>
                    {/* <a className="claincoaster-general-link-1">
                      + Add Team Member
                    </a> */}
                    <Col
                      xxl={12}
                      className="claincoaster-general-btn-group text-center mt-2"
                    >
                      {/* <Button className="claincoaster-general-btn mx-2 p-3 px-5">
                        Cancel
                      </Button> */}

                      <Button
                        type="submit"
                        className="claincoaster-general-btn-1 mx-2 "
                      >
                        Save Changes
                      </Button>
                    </Col>
                  </Form>
                  {/* form section input field starts ---------------------------------------------------------------- */}
                </div>
              )}

              {activeContent === 2 && (
                <div className="claincoaster-general-div m-4  ">
                  <Row className="claincoaster-integration-row-2 ms-1 me-3">
                    <Col xxl={8}>
                      <Form.Group
                        className="claincoaster-search-box my-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Col xxl={6} xl={12} md={12} sm={12}>
                          <Form.Control
                            type="search"
                            className="claincoaster-general-input py-3"
                            placeholder="Search"
                            onChange={handleSearchData}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xxl={4} xl={8} lg={8} md={8} sm={8}>
                      <div className="claincoaster-integration-div-5">
                        <Form.Group
                          className="claincoaster-search-dropdown-1 my-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Dropdown className="claincoaster-dropdown">
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="claincoaster-dropdown-01 text-dark  p-3 "
                            >
                              All Integration
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                        {/* </Col>
      <Col xxl={2}> */}

                        <Form.Group
                          className=" claincoaster-search-dropdown-2 my-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Dropdown className="claincoaster-dropdown">
                            <Dropdown.Toggle
                              variant="light"
                              id="dropdown-basic"
                              className="claincoaster-dropdown-01 text-dark p-3 "
                            >
                              Short
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1">
                                Action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-2">
                                Another action
                              </Dropdown.Item>
                              <Dropdown.Item href="#/action-3">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                  <Row className="claincoaster-integration-row m-2">
                    {/* fetch integration dfata API -----------------------------------------------------------------------starts-------- */}

                    {filteredData?.map((data) => (
                      <Col lg={4}>
                        <div className="claincoaster-integration-div px-3 py-2 m-1">
                          <img
                            src={data.connector_logo}
                            width={20}
                            height={20}
                            className="claincoaster-integration-img-fluid-4"
                            alt="dog"
                          />
                          {/* {data.status==="connected" ? (<span style={{fontSize:"10px", color:"green", marginLeft:"140px"}}>connected</span>) : (<span style={{fontSize:"10px", color:"red", marginLeft:"140px"}}>Notconnected</span>)} */}
                          <br></br>
                          <p className="claincoaster-integration-heading">
                            {data.connector_name}
                          </p>
                          <p className="claincoaster-integration-text">
                            {data.description}
                          </p>
                          <Button
                            className="claincoaster-integration-btn"
                            onClick={() => {
                              handleConnect(data.status, data.connector_id);
                              setOrganizationDatas(data.connector_name);
                              setOrganizatioDataLogo(data.connector_logo);
                              setConnectorId(data.connector_id);
                              setRequiredKeys(data.required_keys);
                            }}
                          >
                            {data.status === "connected"
                              ? "Disconnect"
                              : "Connect"}
                          </Button>
                        </div>
                      </Col>
                    ))}

                    {/* fetch integration dfata API -----------------------------------------------------------------------ends-------- */}

                    <MyVerticalModal
                      organizatioDataLogo={organizatioDataLogo}
                      organizationDatas={organizationDatas}
                      requiredKeys={requiredKeys}
                      show={modalShow}
                      connectorId={connectorId}
                      onHide={() => setModalShow(false)}
                    />
                  </Row>

                  {/* <Row className="claincoaster-integration-row m-2">
                    <Col xxl={4}>
                      <div className="claincoaster-integration-div px-3 py-2 m-1">
                        <img
                          src={image7}
                          width={20}
                          height={20}
                          className="claincoaster-integration-img-fluid-4"
                          alt="dog"
                        />
                        <br></br>
                        <p className="claincoaster-integration-heading">
                          Google Calendar
                        </p>
                        <p className="claincoaster-integration-text">
                          A powerful, free service you can use to organize your
                          schedule.
                        </p>
                        <Button className="claincoaster-integration-btn-1">
                          Connect
                          <BiSolidChevronRight />
                        </Button>
                      </div>
                    </Col>
                    <Col xxl={4}>
                      <div className="claincoaster-integration-div px-3 py-2 m-1">
                        <img
                          src={image5}
                          width={20}
                          height={20}
                          className="claincoaster-integration-img-fluid-4"
                          alt="dog"
                        />
                        <br></br>
                        <p className="claincoaster-integration-heading">
                          Zapier
                        </p>
                        <p className="claincoaster-integration-text">
                          An online automation tool that connects your apps and
                          services.
                        </p>
                        <Button className="claincoaster-integration-btn-1">
                          Connect
                          <BiSolidChevronRight />
                        </Button>
                      </div>
                    </Col>
                    <Col xxl={4}>
                      <div className="claincoaster-integration-div px-3 py-2 m-1">
                        <img
                          src={image6}
                          width={20}
                          height={20}
                          className="claincoaster-integration-img-fluid-4"
                          alt="dog"
                        />
                        <br></br>
                        <p className="claincoaster-integration-heading">
                          Freshdesk
                        </p>
                        <p className="claincoaster-integration-text">
                          A cloud-based software designed to facilitate customer
                          support.
                        </p>
                        <Button className="claincoaster-integration-btn-1">
                          Connect
                          <BiSolidChevronRight />
                        </Button>
                      </div>
                    </Col>
                  </Row> */}
                  {/* <Row className="claincoaster-integration-row m-2">
                    <Col xxl={4}>
                      <div className="claincoaster-integration-div px-3 py-2 m-1">
                        <img
                          src={image7}
                          width={20}
                          height={20}
                          className="claincoaster-integration-img-fluid-4"
                          alt="dog"
                        />
                        <br></br>
                        <p className="claincoaster-integration-heading">
                          Google Calendar
                        </p>
                        <p className="claincoaster-integration-text">
                          A powerful, free service you can use to organize your
                          schedule.
                        </p>
                        <Button className="claincoaster-integration-btn-1">
                          Connect
                          <BiSolidChevronRight />
                        </Button>
                      </div>
                    </Col>
                    <Col xxl={4}>
                      <div className="claincoaster-integration-div px-3 py-2 m-1">
                        <img
                          src={image6}
                          width={20}
                          height={20}
                          className="claincoaster-integration-img-fluid-4"
                          alt="dog"
                        />
                        <br></br>
                        <p className="claincoaster-integration-heading">
                          Freshdesk
                        </p>
                        <p className="claincoaster-integration-text">
                          A cloud-based software designed to facilitate customer
                          support.
                        </p>
                        <Button className="claincoaster-integration-btn-1">
                          Connect
                          <BiSolidChevronRight />
                        </Button>
                      </div>
                    </Col>
                    <Col xxl={4}>
                      <div className="claincoaster-integration-div px-3 py-2 m-1">
                        <img
                          src={image8}
                          width={20}
                          height={20}
                          className="claincoaster-integration-img-fluid-4"
                          alt="dog"
                        />
                        <br></br>
                        <p className="claincoaster-integration-heading">
                          Outlook
                        </p>
                        <p className="claincoaster-integration-text">
                          Outlook is a personal information manager software
                          system from Microsoft .
                        </p>
                        <Button className="claincoaster-integration-btn-1">
                          Connect
                          <BiSolidChevronRight />
                        </Button>
                      </div>
                    </Col>
                  </Row> */}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
