import { React, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import image2 from "../images/building.svg";
import image4 from "../images/claincoaster.2.svg";
import image3 from "../images/avtar.png";
import Form from "react-bootstrap/Form";
import { useState } from "react";
import "../css/UserProfilePage.css";
import { BsChevronRight } from "react-icons/bs";
import image1 from "../images/Vector.svg";
import image9 from "../images/mail.svg";
import image10 from "../images/phone.svg";
import image11 from "../images/global.svg";
import { useParams } from "react-router-dom";
// import InputGroup from "react-bootstrap/InputGroup";
import { toast } from "react-toastify";
import { verifyConnectorsAction } from "../ReduxSetup/UserActions";
import {
  disconnectConnector,
  fetchOrganizationIntegrationData,
  fetchUserProfilePersonalBasicData,
  updatePersonalProfileData,
} from "../ReduxSetup/UserActions";
import { useSelector, useDispatch } from "react-redux";
import { updatePassword } from "../ReduxSetup/UserActions";
import { Button } from "react-bootstrap";
import MyVerticalModal from "./MyVerticalModal";

export default function UserProfilePage() {
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const scope = urlParams.get("scope");

  useEffect(() => {
    if (scope === "https://www.googleapis.com/auth/calendar.readonly") {
      if (code) {
        let arrayOfObjects = [
          {
            access_token: code,
          },
        ];
        dispatch(verifyConnectorsAction(8, arrayOfObjects));
      }
      // "4/0AfJohXmtqflVas_wYl7rmpI8iAcA25n238uMyv01bYSpvbPU5UvKbrV-lG_0okpUwV2Hkg"
    }
  }, []);
  const [activeContent, setActiveContent] = useState(0);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // connection states
  const [modalShow, setModalShow] = useState(false);
  const [organizationDatas, setOrganizationDatas] = useState(null);
  const [organizatioDataLogo, setOrganizatioDataLogo] = useState(null);
  const [connectorId, setConnectorId] = useState("");
  const [requiredKeys, setRequiredKeys] = useState(null);

  const fetchDisconnectionInfo = useSelector(
    (state) => state.fetchConnectorDisconnectedReducer?.userData
  );

  const handleConnect = async (connectionStatus, id) => {
    if (connectionStatus === "not_connected") {
      setModalShow(true);
    } else {
      try {
        await dispatch(disconnectConnector(id));
      } catch (error) {
        console.error("Error disconnecting:", error);
        // Handle the error, if needed
      }
    }
  };

  useEffect(() => {
    if (fetchDisconnectionInfo) {
      toast.success("Disconnected");
      window.location.reload();
    }
  }, [fetchDisconnectionInfo]);

  const fetchUserProfileBasicData = useSelector((state) => {
    if (
      state.fetchUserProfilePersonalBasicDataReducer &&
      state.fetchUserProfilePersonalBasicDataReducer.userData &&
      state.fetchUserProfilePersonalBasicDataReducer.userData.data
    ) {
      return state.fetchUserProfilePersonalBasicDataReducer.userData.data[0];
    } else {
      return null;
    }
  });
  // console.log("latestUserProfileData",fetchUserProfileBasicData);

  const fetchAllOrganizationData = useSelector((state) => {
    if (
      state.fetchOrganizationIntgrationDataReducer &&
      state.fetchOrganizationIntgrationDataReducer.userData &&
      state.fetchOrganizationIntgrationDataReducer.userData.data
    ) {
      return state.fetchOrganizationIntgrationDataReducer.userData.data;
    } else {
      return null;
    }
  });

  const filteredData = fetchAllOrganizationData?.filter((organization) => {
    const connectorType = organization.type;
    if (typeof connectorType === "string") {
      return connectorType.toLowerCase().includes("personal");
    }

    return false; // Ignore non-string properties
  });

  useEffect(() => {
    dispatch(fetchOrganizationIntegrationData());
    dispatch(fetchUserProfilePersonalBasicData());
    if (fetchUserProfileBasicData?.name) {
      setFullName(fetchUserProfileBasicData?.name);
      setphoneNumber(fetchUserProfileBasicData?.phone_number);
      setjobTitle(fetchUserProfileBasicData?.job_title);
      settimeZone(fetchUserProfileBasicData?.timezone);
    }
  }, [dispatch]);

  useEffect(() => {
    if (fetchUserProfileBasicData?.name) {
      setFullName(fetchUserProfileBasicData?.name);
      setphoneNumber(fetchUserProfileBasicData?.phone_number);
      setjobTitle(fetchUserProfileBasicData?.job_title);
      settimeZone(fetchUserProfileBasicData?.timezone);
    }
  }, [fetchUserProfileBasicData]);

  const toggleeContent = (index) => {
    setActiveContent(activeContent === index ? index : index);
  };

  // const [activeButton, setActiveButton] = useState(0);

  // const handleButtonClick = (buttonIndex) => {
  //   setActiveButton(buttonIndex);
  // };

  // updating personel info
  const [fullName, setFullName] = useState(
    fetchUserProfileBasicData?.name || ""
  );
  const [phoneNumber, setphoneNumber] = useState(
    fetchUserProfileBasicData?.phone_number || ""
  );
  const [jobTitle, setjobTitle] = useState(
    fetchUserProfileBasicData?.job_title || ""
  );
  const [timeZone, settimeZone] = useState(
    fetchUserProfileBasicData?.timezone || ""
  );

  const handleUpdatePersonalData = (e) => {
    e.preventDefault();
    if (
      fullName === "" ||
      phoneNumber === "" ||
      jobTitle === "" ||
      timeZone === ""
    ) {
      toast.warn("Please fill all details");
      return;
    }
    const dataobj = {
      name: fullName,
      phone_number: phoneNumber,
      job_title: jobTitle,
      timezone: timeZone,
    };
    dispatch(updatePersonalProfileData(dataobj));
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    console.log(oldPassword);
    console.log(newPassword);
    console.log(confirmPassword);
    dispatch(updatePassword(oldPassword, newPassword, confirmPassword));
  };

  return (
    <Container className="custom-width me-5">
      <Row>
        <div>
          <Col className="profile-col mt-5">
            <p>
              <img
                src={image1}
                width={16}
                height={16}
                className="profile-img-fluid-1 me-1"
                alt="dog"
              />

              <span className="profile-span-1 me-3">Setting</span>
              <BsChevronRight className="profile-icon" />
              <img
                src={image2}
                width={16}
                height={16}
                className="profile-img-fluid-2 ms-3 me-1"
                alt="dog"
              />
              <span className="profile-span-2 ">Profile Settings</span>
            </p>
          </Col>
        </div>
      </Row>

      <Row>
        <Col
          xxl={3}
          xl={3}
          lg={3}
          md={12}
          sm={12}
          className="profile-col-1 text-center mt-4"
        >
          <div className="profile-div">
            <img
              src={image3}
              // src={fetchUserProfileBasicData?.profile_picture || ''}           TODO
              width={100}
              height={100}
              className="profile-img-fluid-3  "
              alt="dog"
            />
            <img
              src={image4}
              width={20}
              height={20}
              className="profile-img-fluid-4"
              alt="dog"
            />
          </div>
          <div className="profile-email-name">
            <p className="profile-text-col-3 ms-1">
              {fetchUserProfileBasicData?.name || ""}{" "}
            </p>
            <p className="profile-text-col-3-1">
              {fetchUserProfileBasicData?.role || ""}{" "}
            </p>
          </div>
          <div className="profile-email-name-no">
            <p className="profile-text-col-3-2 ms-3">
              {" "}
              <img
                src={image9}
                width={25}
                height={25}
                className="profile-img-fluid-4 mt-0"
                alt="dog"
              />
              <span className="profile-span-email-no ms-2 ">
                {fetchUserProfileBasicData?.email || ""}
              </span>
            </p>
            <p className="profile-text-col-3-3  ms-3">
              {" "}
              <img
                src={image10}
                width={25}
                height={25}
                className="profile-img-fluid-4 mt-0"
                alt="dog"
              />
              <span className="profile-span-email-no ms-2">
                {fetchUserProfileBasicData?.phone_number || ""}
              </span>
            </p>
            <p className="profile-text-col-3-4 ms-3">
              {" "}
              <img
                src={image11}
                width={25}
                height={25}
                className="profile-img-fluid-4 mt-0"
                alt="dog"
              />
              <span className="profile-span-email-no ms-2">
                {fetchUserProfileBasicData?.timezone || ""}{" "}
              </span>
            </p>
            <hr className="profile-line"></hr>
            <p className="profile-div-company">Company Name</p>
            <p className="profile-div-company-name">
              {fetchUserProfileBasicData?.company_name || ""}
            </p>
          </div>
        </Col>
        <Col
          xxl={9}
          xl={9}
          lg={9}
          md={12}
          sm={12}
          className="profile-col-2 mt-4 px-4"
        >
          <div className="profile-div-1 pt-2">
            <div className="profile-div-11 pt-5 mb-3 ms-3">
              <a
                className={`profile-btn-1  me-4  ${
                  activeContent === 0 ? "active-field" : "text-dark"
                }`}
                onClick={() => toggleeContent(0)}
              >
                Personal
              </a>
              <a
                className={`profile-btn-1 me-4 ${
                  activeContent === 1 ? "active-field" : "text-dark"
                }`}
                onClick={() => toggleeContent(1)}
              >
                Password
              </a>
              {/* <a className={`profile-btn-2 ${
                  activeContent === 2 ? "active-field" : "text-dark"
                }`} onClick={() => toggleeContent(2)}>
                Notifications & Email
              </a> */}
              <a
                className={`profile-btn-2 ${
                  activeContent === 3 ? "active-field" : "text-dark"
                }`}
                onClick={() => toggleeContent(3)}
              >
                Integration
              </a>
            </div>
            <hr className="profile-line mx-3"></hr>
            {activeContent === 0 && (
              <div className="profile-general-div m-4 p-4">
                <p className="profile-general-heading ms-2">Personal Info</p>
                <hr className="profile-line-1 mx-2"></hr>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="profile-general-label">
                      Full Name <span className="profile-span-star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      className="profile-general-input py-3"
                      placeholder="Full name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="profile-general-label">
                      Phone Number <span className="profile-span-star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      className="profile-general-input py-3"
                      placeholder="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => setphoneNumber(e.target.value)}
                    />
                  </Form.Group>
                  <Row>
                    <Col xxl={6}>
                      <Form.Group
                        className="mb-3 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Col xxl={12}>
                          <Form.Label className="profile-general-label">
                            Job Title <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="profile-general-input py-3"
                            placeholder="Job Title"
                            value={jobTitle}
                            onChange={(e) => setjobTitle(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col xxl={6}>
                      <Form.Group
                        className="mb-3 "
                        controlId="exampleForm.ControlInput1"
                      >
                        <Col xxl={12}>
                          <Form.Label className="profile-general-label">
                            Time Zone <span className="text-danger">*</span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="profile-general-input py-3"
                            placeholder="Time Zone"
                            value={timeZone}
                            onChange={(e) => settimeZone(e.target.value)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <button
                      className=" m-auto bg-success p-1 rounded-2 w-25 text-white"
                      onClick={(e) => handleUpdatePersonalData(e)}
                    >
                      Update
                    </button>
                  </Row>
                </Form>
              </div>
            )}
            {/* password section starts ---------------------------------------------------------------------------------------------------- */}
            {activeContent === 1 && (
              <div className="profile-general-div m-4 p-4">
                <Form onSubmit={handlePasswordChange}>
                  <Row>
                    <p className="profile-general-heading ms-2">Team Profile</p>
                    <hr className="profile-line-1 mx-2"></hr>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="profile-general-label">
                        Current Password{" "}
                        <span className="profile-span-star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        className="profile-general-input py-3"
                        placeholder="Enter your current password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput2"
                    >
                      <Form.Label className="profile-general-label">
                        New Password{" "}
                        <span className="profile-span-star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        className="profile-general-input py-3"
                        placeholder="Enter the new password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label className="profile-general-label">
                        New Password Confirmation{" "}
                        <span className="profile-span-star">*</span>
                      </Form.Label>
                      <Form.Control
                        type="password"
                        className="profile-general-input py-3"
                        placeholder="Enter the new password again"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Form.Group>

                    <Col
                      type="submit"
                      xxl={12}
                      className="profile-general-btn-group text-center mt-2"
                    >
                      <button
                        className="profile-general-btn-1 mx-2"
                        type="submit"
                      >
                        Change Password
                      </button>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
            {activeContent === 2 && (
              <div>
                <div className="profile-general-div m-4 p-2">
                  <h5 className="profile-notification-heading ms-2">
                    Email Settings
                  </h5>
                  <hr className="profile-line-1 mx-2"></hr>
                  <Row className="profile-integration-row-2 ms-1 me-3">
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box"
                      />{" "}
                      <span className="profile-checkbox-text ms-2">
                        New communication from your deals{" "}
                      </span>
                    </p>
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box"
                      />
                      <span className="profile-checkbox-text ms-2">
                        {" "}
                        When tagged on an internal note
                      </span>
                    </p>
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box"
                      />
                      <span className="profile-checkbox-text ms-2">
                        {" "}
                        When assigned a new deal
                      </span>{" "}
                    </p>

                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box "
                      />
                      <span className="profile-checkbox-text ms-2">
                        {" "}
                        When your customer moves has bad health
                      </span>
                    </p>
                  </Row>
                </div>

                <div className="profile-general-div m-4 p-2">
                  <h5 className="profile-notification-heading ms-2">
                    Notification Settings
                  </h5>
                  <hr className="profile-line-1 mx-2"></hr>
                  <Row className="profile-integration-row-2 ms-1 me-3">
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box "
                      />{" "}
                      <span className="profile-checkbox-text ms-2">
                        Product Updates.{" "}
                      </span>
                    </p>
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box "
                      />
                      <span className="profile-checkbox-text ms-2">
                        When tagged on an internal note
                      </span>
                    </p>
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box "
                      />
                      <span className="profile-checkbox-text ms-2">
                        When assigned a new deal{" "}
                      </span>
                    </p>
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box "
                      />
                      <span className="profile-checkbox-text ms-2">
                        {" "}
                        When your customer moves has bad health
                      </span>
                    </p>
                    <p className="profile-emai-checkbox">
                      <Form.Check
                        aria-label="option 1"
                        className="checkbox-box "
                      />
                      <span className="profile-checkbox-text ms-2">
                        {" "}
                        Product updated and company announcements{" "}
                      </span>
                    </p>
                    <p className="profile-emai-checkbox">
                      <Form.Check aria-label="option 1" />
                      <span className="profile-checkbox-text ms-2">
                        From the Blog
                      </span>
                    </p>
                  </Row>
                </div>
              </div>
            )}
            {activeContent === 3 && (
              <div className="d-flex">
                {filteredData?.map((data) => (
                  <Col lg={4}>
                    <div className="claincoaster-integration-div px-3 py-2 m-1">
                      <img
                        src={data.connector_logo}
                        width={20}
                        height={20}
                        className="claincoaster-integration-img-fluid-4"
                        alt="dog"
                      />
                      {/* {data.status==="connected" ? (<span style={{fontSize:"10px", color:"green", marginLeft:"140px"}}>connected</span>) : (<span style={{fontSize:"10px", color:"red", marginLeft:"140px"}}>Notconnected</span>)} */}
                      <br></br>
                      <p className="claincoaster-integration-heading">
                        {data.connector_name}
                      </p>
                      <p className="claincoaster-integration-text">
                        {data.description}
                      </p>
                      <Button
                        className="claincoaster-integration-btn"
                        onClick={() => {
                          handleConnect(data.status, data.connector_id);
                          setOrganizationDatas(data.connector_name);
                          setOrganizatioDataLogo(data.connector_logo);
                          setConnectorId(data.connector_id);
                          setRequiredKeys(data.required_keys);
                        }}
                      >
                        {data.status === "connected" ? "Disconnect" : "Connect"}
                      </Button>
                    </div>
                  </Col>
                ))}
                <MyVerticalModal
                  organizatioDataLogo={organizatioDataLogo}
                  organizationDatas={organizationDatas}
                  requiredKeys={requiredKeys}
                  show={modalShow}
                  connectorId={connectorId}
                  onHide={() => setModalShow(false)}
                />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
